import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  container: {
    display: 'block',
    textAlign: 'center',
  },
  logo: {
    maxWidth: '100%',
    imageRendering: 'crisp-edges',
  },
  // https://caniuse.com/mdn-css_properties_image-rendering_crisp-edges
  alternativeBrowserLogo: {
    imageRendering: '-webkit-optimize-contrast',
  },
}));

const Logo = ({ logo, maxHeight = 150, children }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <img
        className={cx(classes.logo, classes.alternativeBrowserLogo)}
        style={{ maxHeight }}
        src={logo}
        alt="logo"
      />
      {children}
    </div>
  );
};

Logo.propTypes = {
  logo: PropTypes.string,
  maxHeight: PropTypes.number,
  children: PropTypes.object,
};

export default Logo;
