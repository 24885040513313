import React, { useRef, useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer';
import VirtualizedList from 'react-virtualized/dist/commonjs/List';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import Item from './Item';
import { useTheme } from '@material-ui/core';
import { useStyles, height as rowHeight } from '../ScopeList/styles';
import {
  getDepth,
  getHierarchicalId,
  isItemSelected as _isItemSelected,
  getDeprecatedIds,
  getDuplicates,
  getImposedItems,
} from './utils';
import { getIsRtl } from '../utils';

const singleScopeGetter = () => item => [item];

export const getDisplayedIds = (items, grouped, expanded) =>
  R.pipe(
    R.map(item => {
      const hierarchicalId = getHierarchicalId(item);
      if (R.has(hierarchicalId, grouped) && R.has(hierarchicalId, expanded)) {
        const children = getDisplayedIds(R.prop(hierarchicalId, grouped), grouped, expanded);
        return R.prepend(hierarchicalId, children);
      }
      return [hierarchicalId];
    }),
    R.unnest,
  )(items);

export const getHasChildrenOnLevel = groupedItems => item => {
  if (R.has(getHierarchicalId(item), groupedItems)) {
    return true;
  }
  return R.pipe(
    R.prop(R.propOr('#ROOT', 'parentId', item)),
    R.find(it => R.has(getHierarchicalId(it), groupedItems)),
    R.complement(R.isNil),
  )(groupedItems);
};

const VirtualizedTree = props => {
  const {
    accessibility,
    changeSelection,
    HTMLRenderer,
    items,
    labelRenderer,
    labels,
    disableAccessor = R.always(false),
    isGreyed = R.always(false),
    expandedIds,
    expand,
    collapse,
    maxTreeHeight = 250,
    scopeGetter = singleScopeGetter,
    treeHeight = 0,
    simpleSelectionMode = false,
  } = props;
  const theme = useTheme();
  const [scrollTop, setScrollTop] = useState(undefined);
  const [shiftIndexes, setShiftIndexes] = useState([]);
  const [isMouseDown, setIsMouseDown] = useState(false);
  const [lastItemId, setLastItemId] = useState(null);
  const [selectIds, setSelectIds] = useState({});

  const { indexedItemsById, groupedItemsByParentId } = useMemo(() => {
    const refinedItems = R.map(
      item => ({
        ...item,
        isSelected: _isItemSelected(selectIds)(item),
        isGreyed: R.is(Function, isGreyed) ? isGreyed(item) : false,
        isDisabled: R.is(Function, disableAccessor) ? disableAccessor(item, selectIds) : false,
      }),
      items,
    );
    const indexedItemsById = R.indexBy(getHierarchicalId, refinedItems);
    const groupedItemsByParentId = R.groupBy(R.propOr('#ROOT', 'parentId'), refinedItems);
    return { indexedItemsById, groupedItemsByParentId };
  }, [items, selectIds]);
  const list = useMemo(
    () =>
      getDisplayedIds(
        R.propOr([], '#ROOT', groupedItemsByParentId),
        groupedItemsByParentId,
        expandedIds,
      ),
    [items, expandedIds],
  );

  const getItemScope = scopeGetter({ indexedItemsById, groupedItemsByParentId });

  const isItemSelected = _isItemSelected(selectIds);

  const multiSelect = id => {
    const item = R.prop(id, indexedItemsById);
    let ids = R.pipe(
      getItemScope,
      getImposedItems(selectIds, indexedItemsById),
      R.filter(v => v.isSelected === item.isSelected),
      items => getDuplicates(items, indexedItemsById),
      R.map(getHierarchicalId),
    )(item);

    if (isItemSelected(item)) {
      const deprecatedIds = getDeprecatedIds(
        ids,
        { indexedItemsById, groupedItemsByParentId },
        selectIds,
      );
      ids = R.concat(ids, deprecatedIds);
    }

    if (R.has(id, selectIds)) {
      setSelectIds(R.omit(ids, selectIds));
    } else {
      setSelectIds({ ...selectIds, ...R.indexBy(R.identity, ids) });
      setLastItemId(id);
    }
  };
  const shiftSelect = index => {
    const selectRange = (indexes, isSelected) => {
      const _selectIds = R.pipe(
        R.slice(Math.min(...indexes), Math.max(...indexes) + 1),
        ids => R.props(ids, indexedItemsById),
        R.map(getItemScope),
        R.unnest,
        R.filter(v => v.isSelected === isSelected),
        R.map(getHierarchicalId),
        R.indexBy(R.identity),
      )(list);
      setShiftIndexes(indexes);
      setSelectIds(_selectIds);
    };
    if (R.isEmpty(shiftIndexes)) {
      const item = R.pipe(R.nth(index), id => R.prop(id, indexedItemsById))(list);
      if (!R.isNil(lastItemId)) {
        const lastItem = R.prop(lastItemId, indexedItemsById);
        const lastItemIndex = R.findIndex(v => v === lastItem.id, list);
        if (lastItem.isSelected !== item.isSelected && lastItemIndex !== -1) {
          selectRange([lastItemIndex, index], item.isSelected);
          return;
        }
      }
      const ids = R.pipe(
        getItemScope,
        R.filter(v => v.isSelected === item.isSelected),
        R.map(getHierarchicalId),
        R.indexBy(R.identity),
      )(item);
      setShiftIndexes(R.append(index, shiftIndexes));
      setSelectIds(ids);
    } else if (R.length(shiftIndexes) === 1 && index === R.head(shiftIndexes)) {
      setShiftIndexes([]);
      setSelectIds({});
    } else {
      const firstItem = R.pipe(R.nth(R.head(shiftIndexes)), id => R.prop(id, indexedItemsById))(
        list,
      );
      selectRange([R.head(shiftIndexes), index], firstItem.isSelected);
    }
  };
  const apply = () => {
    if (!R.isEmpty(selectIds)) {
      const ids = R.values(selectIds);
      const selection = R.pipe(R.props(ids), R.pluck('id'), R.uniq)(indexedItemsById);
      changeSelection(selection);
      setShiftIndexes([]);
      setSelectIds({});
      setIsMouseDown(false);
    }
  };

  const onMouseUp = e => {
    if (!isMouseDown) {
      return;
    }
    e.preventDefault();
    apply();
    setIsMouseDown(false);
  };

  const onMouseLeave = () => {
    if (isMouseDown) {
      apply();
    }
  };

  const onKeyUp = e => {
    e.preventDefault();
    if (e.key === 'Shift' && !R.isEmpty(shiftIndexes)) {
      apply();
    } else if (e.key === 'Control' && !R.isEmpty(selectIds)) {
      apply();
    } else if (e.key === 'Enter' && !R.isNil(lastItemId)) {
      apply();
    }
  };

  const itemEventsListeners = (id, index) => ({
    onMouseDown: e => {
      if (!simpleSelectionMode && (e.ctrlKey || e.shiftKey)) {
        return;
      }
      setIsMouseDown(true);
      multiSelect(id);
    },
    onMouseEnter: e => {
      e.preventDefault();
      if (!isMouseDown || simpleSelectionMode || e.ctrlKey || e.shiftKey) {
        return;
      }
      multiSelect(id);
      setLastItemId(id);
    },
    onKeyDown: e => {
      if (e.key === 'Enter') {
        multiSelect(id);
        setLastItemId(id);
      }
    },
    onClick: e => {
      e.preventDefault();
      if (e.ctrlKey && !simpleSelectionMode) {
        multiSelect(id);
      } else if (e.shiftKey && !simpleSelectionMode) {
        shiftSelect(index);
      } else {
        setIsMouseDown(false);
        setLastItemId(id);
      }
    },
  });
  const ref = useRef();
  const classes = useStyles();
  useEffect(() => {
    const current = ref.current;
    const handler = event => {
      if (R.or(event.ctrlKey, event.shiftKey)) {
        // ugly hack, to get div element created by react-virtuliase (get scroll height)
        // ReactVirtualized__Grid ReactVirtualized__List made the scrollbar
        const scrollEl = R.path(['children', 0, 'children', 0], current);
        const isBottom = R.gte(
          R.add(R.prop('scrollTop')(scrollEl), R.prop('clientHeight')(scrollEl)),
          R.prop('scrollHeight')(scrollEl),
        );
        const nextpos = (scrollTop || scrollEl.scrollTop) + event.deltaY / 2;
        if (R.or(event.deltaY < 0, event.deltaY > 0 && R.not(isBottom))) {
          setScrollTop(nextpos < 0 ? 0 : nextpos);
        }
        event.preventDefault();
      }
      if (R.not(event.ctrlKey) && R.not(event.shiftKey)) {
        setScrollTop(undefined); // should be undefined if the user want use the normal scroll
      }
    };
    if (scrollTop === 0) {
      setScrollTop(undefined);
    }
    if (R.isNil(current)) return;
    current.addEventListener('wheel', handler, { passive: false });
    return () => {
      current.removeEventListener('wheel', handler, { passive: false });
    };
  }, [scrollTop]);
  const maxHeight =
    treeHeight === 0
      ? R.pipe(
          R.length,
          R.multiply(rowHeight),
          R.ifElse(R.gt(maxTreeHeight), R.add(10), R.always(maxTreeHeight)),
        )(list)
      : treeHeight;

  if (R.isEmpty(list)) {
    return null;
  }
  const isRtl = getIsRtl(theme);
  return (
    <div
      style={{ height: maxHeight }}
      onKeyUp={onKeyUp}
      onMouseUp={onMouseUp}
      onMouseLeave={onMouseLeave}
      ref={ref}
      role="presentation"
    >
      <AutoSizer disableWidth>
        {({ height }) => (
          <VirtualizedList
            containerRole="list"
            role="presentation"
            overscan={10}
            tabIndex={-1}
            containerStyle={{ width: '100%', maxWidth: '100%' }}
            height={height + 2}
            width={1}
            style={{ width: '100%' }}
            scrollTop={scrollTop} // control scroll position with ctrl or shift key
            rowCount={R.length(list)}
            rowHeight={R.add(1)(rowHeight)} // 1 is space between elements
            rowRenderer={({ index, style }) => {
              const itemId = R.nth(index, list);
              const item = R.prop(itemId, indexedItemsById);
              return (
                <Item
                  {...item}
                  id={getHierarchicalId(item)}
                  label={labelRenderer(item)}
                  key={getHierarchicalId(item)}
                  depth={getDepth(indexedItemsById)(item)}
                  hasChild={R.has(itemId, groupedItemsByParentId)}
                  hasChildrenOnLevel={getHasChildrenOnLevel(groupedItemsByParentId)(item)}
                  changeList={
                    R.has(itemId, expandedIds)
                      ? () => collapse(getHierarchicalId(item))
                      : () => expand(getHierarchicalId(item))
                  }
                  NavigateIcon={R.has(itemId, expandedIds) ? ExpandLessIcon : ExpandMoreIcon}
                  expanded={R.has(itemId, expandedIds)}
                  isRtl={isRtl}
                  eventsListeners={itemEventsListeners(getHierarchicalId(item), index)}
                  HTMLRenderer={HTMLRenderer}
                  classes={classes}
                  labels={labels}
                  style={style}
                  accessibility={accessibility}
                  ariaLabel={R.prop('navigateNext')(labels)}
                  theme={theme}
                />
              );
            }}
          />
        )}
      </AutoSizer>
    </div>
  );
};

VirtualizedTree.propTypes = {
  accessibility: PropTypes.bool,
  changeSelection: PropTypes.func,
  disableAccessor: PropTypes.func,
  getItemScope: PropTypes.func,
  HTMLRenderer: PropTypes.func,
  isRtl: PropTypes.bool,
  items: PropTypes.array,
  labels: PropTypes.shape({
    disableItemLabel: PropTypes.string,
  }),
  labelRenderer: PropTypes.func,
  withExpandControl: PropTypes.bool,
  maxTreeHeight: PropTypes.number,
  expandedIds: PropTypes.object,
  expand: PropTypes.func,
  collapse: PropTypes.func,
  treeHeight: PropTypes.number,
  isGreyed: PropTypes.func,
  scopeGetter: PropTypes.func,
  simpleSelectionMode: PropTypes.bool,
};

export default VirtualizedTree;
