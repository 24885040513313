import { useMemo } from 'react';
import * as R from 'ramda';

const hasUniqUnlabelledOption = R.allPass([
  R.pipe(R.length, R.equals(1)),
  R.pipe(R.head, R.prop('label'), R.anyPass([R.isNil, R.isEmpty])),
]);

const useFocusOptions = (focus = {}, labels = {}) => {
  const options = useMemo(
    () => {
      return R.when(
        hasUniqUnlabelledOption,
        R.set(R.lensPath([0, 'label']), R.prop('uniqFocusOption', labels)),
      )(focus.options);
    },
    [labels, focus.options],
  );

  const indexedOptions = useMemo(
    () => {
      if (!options) return {};
      return R.indexBy(R.prop('value'), options);
    },
    [options],
  );

  return [options, indexedOptions];
};

export default useFocusOptions;
