import * as R from 'ramda';
import dateFns from 'date-fns';
import numeral from 'numeral';
import {
  YEAR,
  SEMESTER,
  QUARTER,
  MONTH,
  WEEK,
  DAY,
  HOUR,
  FULL_HOUR,
  MINUTE,
  YEARS,
  SEMESTERS,
  QUARTERS,
  MONTHS,
  WEEKS,
  DAYS,
  FULL_HOURS,
  HOURS,
  MINUTES,
  layoutFrequencies,
  frequencies,
  defaultOutputValues,
  formatOrder,
  defaultfreq,
} from './constants';

const isWeekInPreviousYear = (date) => {
  const startIsoWeek = dateFns.startOfISOWeek(date);
  return R.pipe(
    R.times((n) => dateFns.getYear(dateFns.addDays(startIsoWeek, n))),
    R.uniq,
    R.length,
    R.equals(1),
  )(4);
};
const supportedFrequenciesKey = R.keys(layoutFrequencies);
const dec = R.ifElse(R.gte(0), R.identity, R.dec);
const addSemesters =
  (semester = 0) =>
  (date) =>
    dateFns.addMonths(date, R.multiply(6)(dec(Number(semester))));
const getSemesterValue = R.ifElse(R.pipe(Number, R.inc, R.gte(6)), R.always(1), R.always(2));
const getFormat = (value) => numeral(value).format('[0]0');
const getHour = R.pipe(R.pick([HOUR, FULL_HOUR]), R.values, R.head);
const getRanges = (value = [0]) => R.range(R.head(value), R.inc(R.last(value)));
const setRange = (range) => (prop) =>
  R.pipe(getRanges, R.prepend(R.prop(prop)(defaultOutputValues)))(R.prop(prop)(range));

export const getDestructuringDate = (date) =>
  R.pipe(
    (date) => dateFns.format(date, 'YYYY M D H m H Q W [S]'),
    R.replace(/S/, R.pipe((date) => dateFns.getMonth(date), getSemesterValue)(date)),
    R.split(' '),
    R.ifElse((v) => R.equals(R.length(formatOrder), R.length(v)), R.map(Number), R.empty),
  )(date);

const updateLimit = (value, isSamePeriod, defaultValue) => {
  if (isSamePeriod) return value;
  return defaultValue;
};

export const getRange = R.curry((boundaries, date) => {
  const destructuredDate = getDestructuringDate(date);
  const destructuredBoundaries = R.map(getDestructuringDate, boundaries);
  const [years, months, days, hours, minutes, , quarters, weeks, semesters] =
    R.transpose(destructuredBoundaries);
  if (R.isNil(date)) {
    return {
      [YEARS]: years,
      [HOURS]: [NaN],
      [FULL_HOURS]: [NaN],
      [MONTHS]: [NaN],
      [WEEKS]: [NaN],
      [SEMESTERS]: [NaN],
      [QUARTERS]: [NaN],
      [DAYS]: [NaN],
      [MINUTES]: [NaN],
    };
  }
  const listEqualsValueFromDate = R.pipe(
    R.transpose,
    R.reduce((acc, [type, date, start, end]) => {
      return { ...acc, [type]: [R.equals(date, start), R.equals(date, end)] };
    }, {}),
  )([frequencies, destructuredDate, ...destructuredBoundaries]);

  const equalsYears = R.prop('years')(listEqualsValueFromDate);
  const equalsMonths = R.prop('months')(listEqualsValueFromDate);
  const equalsDays = R.prop('days')(listEqualsValueFromDate);

  const hoursRange = R.addIndex(R.map)((value, index) => {
    const isSamePeriod = R.all(R.identity)([
      R.nth(index, equalsDays),
      R.nth(index, equalsMonths),
      R.nth(index, equalsYears),
    ]);
    return updateLimit(R.nth(index)(hours), isSamePeriod, value);
  })([0, 23]);

  return {
    [YEARS]: years,
    [HOURS]: hoursRange,
    [FULL_HOURS]: hoursRange,
    [MONTHS]: R.addIndex(R.map)((value, index) =>
      updateLimit(R.nth(index)(months), R.nth(index, equalsYears), value),
    )([1, 12]),
    [WEEKS]: R.addIndex(R.map)((value, index) =>
      updateLimit(R.nth(index)(weeks), R.nth(index, equalsYears), value),
    )([1, dateFns.getISOWeeksInYear(date)]),
    [SEMESTERS]: R.addIndex(R.map)((value, index) =>
      updateLimit(R.nth(index)(semesters), R.nth(index, equalsYears), value),
    )([1, 2]),
    [QUARTERS]: R.addIndex(R.map)((value, index) =>
      updateLimit(R.nth(index)(quarters), R.nth(index, equalsYears), value),
    )([1, 4]),
    [DAYS]: R.addIndex(R.map)((value, index) => {
      const isSamePeriod = R.and(R.nth(index, equalsMonths), R.nth(index, equalsYears));
      return updateLimit(R.nth(index)(days), isSamePeriod, value);
    })([1, dateFns.getDaysInMonth(date)]),
    [MINUTES]: R.addIndex(R.map)((value, index) => {
      const isSamePeriod = R.all(R.identity)([
        R.nth(index, R.prop('hours')(listEqualsValueFromDate)),
        R.nth(index, equalsDays),
        R.nth(index, equalsMonths),
        R.nth(index, equalsYears),
      ]);
      return updateLimit(R.nth(index)(minutes), isSamePeriod, value);
    })([0, 59]),
  };
});

export const getAjustedDate = (frequency) =>
  R.when(
    R.always(R.or(R.equals('W', frequency), R.equals('B', frequency))),
    R.ifElse(isWeekInPreviousYear, dateFns.startOfISOWeek, dateFns.endOfISOWeek),
  );

export const isValidNumber = R.both(R.is(Number), R.complement(R.equals(NaN)));
export const getDefaultFrequency = ({ defaultFrequency, frequencies }) => {
  const isNotIncludes = R.complement(R.and)(
    R.includes(defaultFrequency)(frequencies),
    R.includes(defaultFrequency)(supportedFrequenciesKey),
  );
  return R.or(R.either(R.isEmpty, R.isNil)(defaultFrequency), isNotIncludes)
    ? R.head(frequencies)
    : defaultFrequency;
};

export const getFrequencyOptions = (frequencyoptions, frequencyLabels) => {
  const availableFrequencies = R.or(R.isEmpty(frequencyoptions), R.isNil(frequencyoptions))
    ? [defaultfreq]
    : R.filter((freq) => R.includes(freq, supportedFrequenciesKey))(frequencyoptions);
  const options = R.map((option) => ({ value: option, label: R.prop(option, frequencyLabels) }))(
    availableFrequencies,
  );
  if (R.isEmpty(options)) return [{ value: 'A', label: R.propOr('Annual', 'A', frequencyLabels) }];
  return options;
};

export const getLayout = (type, range) => {
  const layoutPeriods = R.propOr([], type)(layoutFrequencies);
  const hasYear = R.complement(R.isEmpty)(getRanges(range.years));
  return R.map(
    R.reduce((acc, period) => {
      if (hasYear) return R.append(R.evolve({ values: setRange(range) }, period), acc);
      return R.append(
        R.evolve(
          { values: R.pipe(R.flip(R.prop)(defaultOutputValues), R.flip(R.append)([])) },
          period,
        ),
        acc,
      );
    }, []),
  )(layoutPeriods);
};

export const getPeriodValue = R.pipe(R.zip(formatOrder), R.fromPairs);

export const getDate = (type, dateValue, value, isEnd) => {
  if (R.isNil(value)) return undefined;
  const dateValueUpdated = R.pipe(
    R.ifElse(R.pipe(getHour, R.isNil), R.identity, R.set(R.lensProp('hour'), getHour(value))),
    R.mergeRight(dateValue),
    R.over(R.lensProp('month'), R.defaultTo(isEnd ? 12 : 1)),
    R.over(R.lensProp('day'), R.defaultTo(isEnd ? 31 : 1)),
    R.over(R.lensProp('hour'), R.defaultTo(isEnd ? 23 : 0)),
    R.over(R.lensProp('minute'), R.defaultTo(isEnd ? 59 : 0)),
  )(value);
  const { year, month, day, hour, minute } = dateValueUpdated;
  const date = new Date(year, R.dec(month), day, hour, minute);
  if (R.equals(type, 'Q')) {
    if (R.isEmpty(dateValue) && isEnd) {
      return R.pipe(dateFns.startOfYear, R.flip(dateFns.addQuarters)(3))(date);
    }
    return R.pipe(dateFns.startOfYear, (date) =>
      dateFns.addQuarters(date, dec(R.propOr(0, QUARTER)(dateValueUpdated))),
    )(date);
  }
  if (R.equals(type, 'S')) {
    if (R.isEmpty(dateValue) && isEnd) return R.pipe(dateFns.startOfYear, addSemesters(2))(date);
    return R.pipe(dateFns.startOfYear, addSemesters(R.prop(SEMESTER)(dateValueUpdated)))(date);
  }
  if (R.or(R.equals(type, 'W'), R.equals(type, 'B'))) {
    const weekDate = new Date(year, 6, 1, 0, 0, 0, 0);
    if (R.isEmpty(dateValue)) {
      return R.pipe(
        R.ifElse(R.always(isEnd), dateFns.endOfISOYear, dateFns.startOfISOYear),
        getAjustedDate(type),
      )(weekDate);
    }
    const weeksInYear = R.dec(dateFns.getISOWeeksInYear(weekDate));
    const nbWeeks = R.pipe(
      R.propOr(0, WEEK),
      R.dec,
      R.ifElse(R.lt(weeksInYear), R.always(weeksInYear), R.identity),
    )(dateValueUpdated);
    return R.pipe(
      dateFns.startOfISOYear,
      (date) => dateFns.addWeeks(date, nbWeeks),
      R.of,
      R.map(getAjustedDate(type)),
      R.head,
    )(weekDate);
  }
  return date;
};

export const getDateInTheRange = (boundaries) => (date) => {
  if (R.isNil(date)) return date;
  return dateFns.isWithinRange(date, ...boundaries) ? date : dateFns.closestTo(date, boundaries);
};

export const numeralFormat = {
  [YEAR]: R.identity,
  [SEMESTER]: R.identity,
  [QUARTER]: R.identity,
  [MONTH]: getFormat,
  [WEEK]: getFormat,
  [DAY]: getFormat,
  [FULL_HOUR]: R.converge(R.concat, [getFormat, R.always(':00')]),
  [HOUR]: getFormat,
  [MINUTE]: getFormat,
};
