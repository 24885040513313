import * as R from 'ramda';
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  container: {
    minHeight: '40px !important',
    maxHeight: 40,
    display: 'flex',
    flexDirection: 'row-reverse',
    alignItems: 'flex-start',
    padding: theme.spacing(0),
  },
  moreFiltersContainer: {
    alignItems: 'flex-end',
  },
  ellipsis: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    flexFlow: 'nowrap',
  },
  content: {
    display: 'flex',
    alignContent: 'space-between',
    margin: `${theme.spacing(0.5, 0)} !important`,
  },
  moreFiltersContent: {
    margin: `${theme.spacing(0, 0)} !important`,
  },
  details: {
    display: 'flex',
    padding: theme.spacing(0),
    flexDirection: 'column',
    width: '100%',
    overflow: 'auto',
  },
  height: {
    maxHeight: 250,
  },
  overflow: {
    overflow: 'unset',
    maxHeight: 'unset',
  },
  panel: {
    borderTop: `solid 2px ${theme.palette.grey[700]}`,
    borderRadius: 'unset !important',
    '&$expanded': {
      marginTop: 0,
      marginBottom: theme.spacing(2),
    },
    '&:before': {
      backgroundColor: 'unset',
    },
  },
  panelBorder: {
    borderBottom: `solid 2px ${theme.palette.grey[700]}`,
    borderTop: 'none',
  },
  iconSummaryPanel: {
    color: theme.palette.primary.main,
    padding: theme.spacing(0.5, 0.5),
    margin: theme.spacing(0, 1, 0, 0),
  },
  moreFiltersExpandIcon: {
    padding: theme.spacing(0, 0.5),
  },
  fullWidth: {
    width: '100%',
  },
  pinnedIcon: {
    display: 'flex',
    margin: theme.spacing(-1, 0.5),
    color: theme.palette.grey[700],
    fontSize: 18,
  },
  label: {
    color: theme.palette.grey[700],
    ...R.pathOr({}, ['mixins', 'expansionPanel', 'title'], theme),
  },
  title: {
    padding: 0,
    fontFamily: 'Roboto Slab, serif',
    fontSize: 17,
    color: theme.palette.grey['A700'],
  },
}));
