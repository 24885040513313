import React from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import Link from '@material-ui/core/Link';
import Flags from './flags';
import { getIsRtl } from '../utils';

const useStyles = makeStyles(theme => ({
  cell: {
    borderBottom: `thin solid ${theme.palette.grey[500]}`,
    borderRight: `thin solid ${theme.palette.grey[500]}`,
    padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
    verticalAlign: 'baseline',
    color: theme.palette.grey['A700'],
    '&:hover': {
      boxShadow: `inset 0 0 0 2px ${theme.palette.primary.main}`,
    },
    whiteSpace: 'pre-wrap',
  },
  active: {
    boxShadow: `inset 0 0 0 2px ${theme.palette.primary.main}`,
  },
  highlight: theme.mixins.table.cellHighlight,
  oneCell: {
    border: `thin solid ${theme.palette.grey[500]}`,
    display: 'block', // MUI TableCell is inline-block
  },
  link: {
    position: 'inherit',
    userSelect: 'inherit',
  },
  value: {
    overflow: 'inherit',
  },
  rightAlign: {
    // only for rtl layout !
    // changed by right in rtl mode
    textAlign: 'right',
  },
}));

const isValidNumber = R.both(R.is(Number), R.complement(R.equals(NaN)));

const Cell = ({
  flags,
  value,
  intValue,
  headers,
  isOne,
  indexedDimValIds = {},
  cellHandler,
  activeCellHandler,
  isActive,
  isHighlight,
  sideProps,
  SideIcon,
  HTMLRenderer,
  labelAccessor,
  textAlign,
}) => {
  const classes = useStyles();
  const isRtl = getIsRtl(useTheme());
  const hasFlag = R.not(R.isEmpty(flags));
  const uiValue =
    R.is(Function, cellHandler) && !R.isEmpty(indexedDimValIds) ? (
      <Link
        component="button"
        variant="body1"
        color="inherit"
        underline="always"
        onClick={() => cellHandler({ indexedDimValIds })}
        className={classes.link}
      >
        {R.is(Function, HTMLRenderer) ? (
          <HTMLRenderer
            html={value}
            style={{ float: isRtl && R.isNil(textAlign) ? 'left' : textAlign }}
          />
        ) : (
          value
        )}
      </Link>
    ) : R.is(Function, HTMLRenderer) ? (
      <HTMLRenderer
        html={value}
        style={{ float: isRtl && R.isNil(textAlign) ? 'left' : textAlign }}
      />
    ) : (
      value
    );

  return (
    <TableCell
      align={isValidNumber(intValue) ? 'right' : textAlign}
      component={isOne ? 'div' : 'td'}
      className={cx(classes.cell, {
        [classes.oneCell]: isOne,
        [classes.active]: isActive,
        [classes.highlight]: isHighlight,
        [classes.rightAlign]: isRtl && R.isNil(textAlign),
      })}
      headers={headers}
      aria-describedby={headers}
      onClick={activeCellHandler}
      tabIndex={0}
      style={{ textAlign }}
    >
      {!R.isNil(SideIcon) && <SideIcon sideProps={sideProps} className={classes.value} />}
      {hasFlag && <Flags HTMLRenderer={HTMLRenderer} flags={flags} labelAccessor={labelAccessor} />}
      {uiValue}
    </TableCell>
  );
};

Cell.propTypes = {
  intValue: PropTypes.number,
  flags: PropTypes.array,
  isOne: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  headers: PropTypes.string,
  indexedDimValIds: PropTypes.object,
  cellHandler: PropTypes.func,
  isActive: PropTypes.bool,
  isHighlight: PropTypes.bool,
  activeCellHandler: PropTypes.func,
  sideProps: PropTypes.object,
  SideIcon: PropTypes.func,
  HTMLRenderer: PropTypes.func,
  labelAccessor: PropTypes.func,
  textAlign: PropTypes.string,
};

export default Cell;
