import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import cx from 'classnames';
import { Droppable } from '@hello-pangea/dnd';
import DnDItem from './DnDItem';
import { getLight, getDark } from '../utils';
import { BOX } from './constants';

export const useStyles = makeStyles(theme => ({
  blockDnd: {
    width: '100%',
    paddingBottom: theme.spacing(1),
    marginTop: theme.spacing(0.5),
    marginRight: theme.spacing(1),
    minHeight: 30,
    marginBottom: theme.spacing(1),
    borderRadius: theme.spacing(0.5),
    borderWidth: 2,
    borderStyle: 'solid',
  },
  canDrop: {
    borderStyle: 'dashed',
  },
  [`${BOX.sections}Container`]: {
    borderColor: getDark(theme) || theme.palette.secondary.dark,
  },
  [`${BOX.rows}Container`]: {
    borderColor: getLight(theme) || theme.palette.secondary.light,
  },
  [`${BOX.header}Container`]: {
    borderColor: theme.palette.primary.main,
  },
  iconTitle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexFlow: 'nowrap',
  },
  title: theme.typography.subtitle1,
}));

const Box = ({
  items,
  itemRenderer,
  dropZoneLabel,
  title,
  Icon,
  onChangeLayout,
  itemButtonProps,
  accessibility,
  md,
}) => {
  const classes = useStyles();
  const canDrag = R.equals(dropZoneLabel, BOX.rows) ? R.pipe(R.length, R.lt(1))(items) : R.T();

  return (
    <Grid item xs={accessibility ? 4 : 12} md={md}>
      <div className={classes.iconTitle}>
        <Icon />
        <span className={classes.title}>{title}</span>
        <Typography variant="srOnly">{title}</Typography>
      </div>
      <Droppable droppableId={dropZoneLabel}>
        {(provided, snapshot) => (
          <div
            data-testid={`droppable-${dropZoneLabel}`}
            ref={provided.innerRef}
            className={cx(classes.blockDnd, R.prop(`${dropZoneLabel}Container`)(classes), {
              [classes.canDrop]: snapshot.isDraggingOver,
            })}
          >
            {R.addIndex(R.map)((item, index) => (
              <DnDItem
                key={item.id}
                index={index}
                item={item}
                itemRenderer={itemRenderer}
                dropZoneLabel={dropZoneLabel}
                onChangeLayout={onChangeLayout}
                itemButtonProps={itemButtonProps}
                canDrag={canDrag}
              />
            ))(R.values(items))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </Grid>
  );
};

Box.propTypes = {
  items: PropTypes.array,
  itemRenderer: PropTypes.func,
  dropZoneLabel: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  Icon: PropTypes.object,
  onChangeLayout: PropTypes.func,
  putItem: PropTypes.func,
  itemButtonProps: PropTypes.object,
  accessibility: PropTypes.bool,
  md: PropTypes.number,
};

export default Box;
