/* eslint react/prop-types: 0 */
import React from 'react';
import * as R from 'ramda';

export const withInput = Component =>
  class extends React.Component {
    state = {
      value: this.props.value || '',
    };

    onChange = value => {
      this.setState({ value: value });
    };

    onSubmit = () => {
      this.bubbleUp(this.state.value);
    };

    bubbleUp = item => {
      if (R.is(Function, this.props.onSubmit)) this.props.onSubmit(item);
    };

    render = () => {
      const { onChange, onSubmit, isControlled, isBlank } = this.props;
      if (isBlank) return null;
      const { value } = this.state;

      return (
        <Component
          {...this.props}
          onChange={isControlled ? onChange : this.onChange}
          onSubmit={isControlled ? onSubmit : this.onSubmit}
          value={isControlled ? this.props.value : value}
        />
      );
    };
  };
