import React from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

const View = ({ isRtl, componentId, lang = 'en' }) => (
  <Helmet
    htmlAttributes={{ lang, dir: isRtl ? 'rtl' : 'ltr' }}
    title={`${componentId ? componentId + ' - ' : ''}Visions`}
  />
);

View.propTypes = {
  isRtl: PropTypes.bool,
  lang: PropTypes.string,
  componentId: PropTypes.string,
};

export default View;
