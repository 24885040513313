/**
 * AuthDialog is an UI component that displays an login popup.
 *
 * @memberOf VISIONS
 * @name AuthDialog
 * @tag component
 * @api public
 * @props
 * AuthDialog.propTypes = {
 *   isOpen: PropTypes.bool,
 *   labels: PropTypes.shape({
 *     anonymous: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
 *     error: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
 *     header: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
 *     password: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
 *     user: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
 *     submit: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
 *   }),
 *   onClose: PropTypes.func,
 *   onSubmit: PropTypes.func.isRequired
 * };
 * @demoReady
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'ramda';
import Checkbox from '@material-ui/core/Checkbox';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import PersonIcon from '@material-ui/icons/Person';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Button from '../Button';

const useStyles = makeStyles(() => ({
  header: {
    color: 'grey',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  headerLabel: {
    display: 'flex',
    alignItems: 'center',
  },
  closeButton: {
    color: 'grey !important',
    padding: 0,
  },
  content: {
    alignItems: 'stretch',
    display: 'flex',
    flexDirection: 'column',
    padding: 10,
    backgroundColor: 'rgb(240, 240, 240)',
  },
  error: {
    color: 'red',
  },
  input: {
    backgroundColor: 'white',
    '&[type=number]::-webkit-inner-spin-button, &[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '&[type=number]': {
      '-moz-appearance': 'textfield',
    },
  },
}));

const AuthDialog = ({ isOpen, labels, onClose, onSubmit }) => {
  const classes = useStyles();
  const [user, setUser] = useState('');
  const [password, setPassword] = useState('');
  const [isAnonymous, setIsAnonymous] = useState(false);

  const onChangeAnonymous = () => {
    setIsAnonymous(!isAnonymous);
    if (!isAnonymous) {
      setUser('');
      setPassword('');
    }
  };

  const canSubmit = isAnonymous || (!isEmpty(user) && !isEmpty(password));

  const onClickSubmit = () => onSubmit({ user, password, isAnonymous });

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <div className={classes.header}>
        <div className={classes.headerLabel}>
          <PersonIcon />
          <Typography>{labels.header}</Typography>
        </div>
        {onClose && (
          <IconButton className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        )}
      </div>
      <form className={classes.content}>
        <label htmlFor="login">{labels.user}</label>
        <TextField
          inputProps={{
            className: classes.input,
            id: 'login',
            autoComplete: 'username',
            name: 'username',
          }}
          value={user}
          onChange={e => setUser(e.target.value)}
          disabled={isAnonymous}
        />
        <label htmlFor="password">{labels.password}</label>
        <TextField
          inputProps={{
            className: classes.input,
            id: 'password',
            autoComplete: 'current-password',
            name: 'password',
          }}
          value={password}
          onChange={e => setPassword(e.target.value)}
          disabled={isAnonymous}
          type="password"
        />
        <div>
          <Checkbox checked={isAnonymous} onChange={onChangeAnonymous} />
          {labels.anonymous}
        </div>
        {labels.error && <div className={classes.error}>{labels.error}</div>}
        <Button color="primary" disabled={!canSubmit} onClick={onClickSubmit} variant="contained">
          <div style={{ width: '100%' }}>{labels.submit}</div>
        </Button>
      </form>
    </Dialog>
  );
};

AuthDialog.propTypes = {
  isOpen: PropTypes.bool,
  labels: PropTypes.shape({
    anonymous: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    header: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    password: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    user: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    submit: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  }),
  onClose: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
};

export default AuthDialog;
