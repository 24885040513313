import React from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { getLight, getIsRtl } from '../utils';
import Flags from './flags';

const useStyles = makeStyles(theme => ({
  columnsTitle: {
    backgroundColor: getLight(theme) || theme.palette.secondary.light,
    border: 'inherit',
    borderBottom: `thin solid ${theme.palette.grey[500]}`,
    borderRight: `thin solid ${theme.palette.grey[500]}`,
    padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
    color: theme.palette.grey['A700'],
    verticalAlign: 'middle',
    position: 'sticky',
    zIndex: 1,
  },
  fillerCell: {
    backgroundColor: theme.palette.grey[200],
    border: 'inherit',
    borderBottom: `thin solid ${theme.palette.grey[500]}`,
    borderRight: `thin solid ${theme.palette.grey[500]}`,
    padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
    verticalAlign: 'middle',
    textAlign: 'center',
    color: theme.palette.grey['A700'],
  },
  cellContent: {
    justifyContent: 'flex-start',
    display: 'flex',
  },
  label: {
    fontStyle: 'italic',
    fontWeight: 'bold',
  },
  highlight: theme.mixins.table.cellHighlight,
}));

const mapIndexed = R.addIndex(R.map);

const SubHeader = React.forwardRef(
  (
    {
      headerData,
      xLayoutData,
      activeCellIds,
      subHeadCellsWidth,
      SideIcon,
      HTMLRenderer,
      labelAccessor,
    },
    refs,
  ) => {
    const classes = useStyles();
    const theme = useTheme();
    const isRtl = getIsRtl(theme);
    return (
      <TableRow>
        {mapIndexed(
          (rowItem, index) => (
            <TableCell
              component="th"
              className={classes.columnsTitle}
              key={index}
              id={`subHeader_${index}`}
              style={
                isRtl ? { right: subHeadCellsWidth[index] } : { left: subHeadCellsWidth[index] }
              }
              ref={element => (refs.current[index] = element)}
              scope="col"
            >
              <div
                className={classes.cellContent}
                aria-label={R.path(['dimension', 'id'], rowItem)}
                data-testid={`row-dim`}
              >
                <Typography variant="body1" tabIndex={0} className={classes.label}>
                  {R.pipe(R.prop('dimension'), labelAccessor)(rowItem)}
                  <Flags
                    labelAccessor={labelAccessor}
                    HTMLRenderer={HTMLRenderer}
                    flags={R.path(['dimension', 'flags'], rowItem)}
                  />
                </Typography>
              </div>
            </TableCell>
          ),
          xLayoutData,
        )}
        <TableCell
          className={classes.fillerCell}
          style={
            isRtl
              ? { position: 'sticky', right: R.last(subHeadCellsWidth) }
              : { position: 'sticky', left: R.last(subHeadCellsWidth) }
          }
        />
        {mapIndexed(
          (item, index) => (
            <TableCell
              key={index}
              className={cx(classes.fillerCell, {
                [classes.highlight]: R.prop(index)(activeCellIds),
              })}
            >
              {!R.isNil(SideIcon) && <SideIcon sideProps={R.prop('sideProps', item)} />}
              <Flags
                flags={R.prop('flags', item)}
                labelAccessor={labelAccessor}
                HTMLRenderer={HTMLRenderer}
              />
            </TableCell>
          ),
          headerData,
        )}
      </TableRow>
    );
  },
);

SubHeader.propTypes = {
  activeCellIds: PropTypes.object,
  headerData: PropTypes.array,
  xLayoutData: PropTypes.array,
  SideIcon: PropTypes.func,
  subHeadCellsWidth: PropTypes.arrayOf(PropTypes.number),
  HTMLRenderer: PropTypes.func,
  labelAccessor: PropTypes.func,
};

export default SubHeader;
