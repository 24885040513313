import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';

const useStyles = makeStyles(theme => ({
  drawer: {
    width: 260,
    flexShrink: 0,
  },
  drawerPaper: {
    width: 260,
  },
  toolbar: theme.mixins.toolbar,
}));


const Side = ({ isDrawerOpen, variant = 'persistent', anchor = 'left', children }) => {
  const classes = useStyles();
  return (
    <Drawer
      className={classes.drawer}
      variant={variant}
      anchor={anchor}
      open={isDrawerOpen}
      classes={{ paper: classes.drawerPaper }}
    >
      <div className={classes.toolbar} />
      {children}
    </Drawer>
  );
};

Side.propTypes = {
  isDrawerOpen: PropTypes.bool,
  variant: PropTypes.string,
  anchor: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

export default Side;
