import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import Grid from '@material-ui/core/Grid';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import Title from './Title';
import { Button, InputAdornment, Typography } from '@material-ui/core';
import useFocusOptions from './useFocusOptions';
import Select from '../Select';
import { extractMultipleValue, getValue } from './utils';
import useDimensionOptions from './useDimensionOptions';
import InputProxy from './InputProxy';
import Proxy from './Proxy';
import useAxisOptions from './useAxisOptions';
import { makeStyles } from '@material-ui/core/styles';
import DataEdit from '../DataEdit';

export const useStyles = makeStyles(theme => ({
  greyVariant: {
    backgroundColor: theme.palette.secondary.dark,
    color: theme.palette.grey[600],
  },
}));

const ChartConfig = ({ labels = {}, properties = {} }) => {
  const classes = useStyles();

  const [dimensions] = useDimensionOptions(properties);

  const xKeys = useAxisOptions(properties, 'X');
  const yKeys = useAxisOptions(properties, 'Y');

  const { highlight, baseline } = properties;
  const [highlightOptions, xhighlightOptions] = useFocusOptions(highlight, labels);
  const [baselineOptions, xbaselineOptions] = useFocusOptions(baseline, labels);

  const { stackedMode = {} } = properties;
  const { options: stackedModeOptions = [] } = stackedMode;

  const { displayMode = {} } = properties;
  const { options: displayModeOptions = [] } = displayMode;

  const dKeys = ['dimensions', 'stackedDimension', 'scatterDimension', 'symbolDimension'];
  const eKeys = ['title', 'subtitle', 'source', 'logo', 'copyright'];

  if (R.isEmpty(properties)) return null;

  return (
    <Grid container spacing={3} data-testid="charts-config-test-id">
      <Proxy keys={dKeys} properties={properties}>
        <Grid item xs={12} container spacing={2}>
          <Grid item xs={12} container>
            <Grid item xs={12}>
              <Title label={labels.series} />
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Proxy keys={['stackedMode']} properties={properties}>
                  <Grid item container spacing={1} alignItems="center" style={{ minWidth: 320 }}>
                    <Grid item xs>
                      <Typography variant="body2">{labels.stackedMode}</Typography>
                    </Grid>
                    {R.map(
                      option => (
                        <Grid item key={option.value}>
                          <Button
                            variant={option.value === stackedMode.value ? 'contained' : 'outlined'}
                            onClick={() => stackedMode.onChange(option.value)}
                            color="primary"
                          >
                            <Typography variant="body2">
                              {R.path(['stackedModeOptions', option.value], labels)}
                            </Typography>
                          </Button>
                        </Grid>
                      ),
                      stackedModeOptions,
                    )}
                  </Grid>
                </Proxy>
                {R.map(
                  ([key, dimension]) => (
                    <Grid item xs={12} style={{ minWidth: 320 }} key={key}>
                      <Select
                        {...dimension}
                        onChange={dimension.onChange}
                        label={R.prop(key, labels)}
                        value={getValue(dimension.value)}
                        items={dimension.options}
                      />
                    </Grid>
                  ),
                  dimensions,
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Proxy>
      <Grid item xs={12} container spacing={2}>
        <Proxy keys={['highlight', 'baseline']} properties={properties}>
          <Grid item xs={12} lg={8} container alignContent="flex-start">
            <Grid item xs={12}>
              <Title label={labels.focus} />
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={12} style={{ minWidth: 320 }}>
                  <Select
                    {...highlight}
                    onChange={ids => highlight.onChange(R.props(ids, xhighlightOptions))}
                    value={extractMultipleValue(highlight)}
                    items={highlightOptions}
                    isMultiple
                    isClearable={true}
                    label={R.prop('highlight', labels)}
                    placeholder={R.prop('select', labels)}
                    optionsKey="highlight"
                  />
                </Grid>
                <Grid item xs={12} style={{ minWidth: 320 }}>
                  <Select
                    {...baseline}
                    onChange={id =>
                      baseline.onChange(
                        R.pipe(R.props([id]), R.without(baseline.value))(xbaselineOptions),
                      )
                    }
                    value={R.pipe(R.propOr([], 'value'), R.head, R.prop('value'))(baseline)}
                    items={baselineOptions}
                    isClearable={true}
                    label={R.prop('baseline', labels)}
                    placeholder={R.prop('select', labels)}
                    optionsKey="baseline"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Proxy>
        <Grid item xs={12} lg={4} container alignContent="flex-start">
          <Grid item xs={12}>
            <Title label={labels.size} />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <InputProxy
                pkey="width"
                labels={labels}
                properties={properties}
                InputProps={{
                  endAdornment: <InputAdornment position="end">px</InputAdornment>,
                }}
              />
              <InputProxy
                pkey="height"
                labels={labels}
                properties={properties}
                InputProps={{
                  endAdornment: <InputAdornment position="end">px</InputAdornment>,
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} container spacing={2}>
        <Proxy keys={xKeys} properties={properties}>
          <Grid item xs={12} container className={classes.greyVariant}>
            <Grid item xs={12}>
              <Title label={labels.axisX} icon={<ArrowForwardIcon />} />
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                {R.map(
                  key => (
                    <InputProxy
                      pkey={key}
                      mkey={R.init(key)}
                      key={key}
                      lg={12 / R.length(xKeys)}
                      labels={labels}
                      properties={properties}
                    />
                  ),
                  xKeys,
                )}
              </Grid>
            </Grid>
          </Grid>
        </Proxy>
        <Proxy keys={['freqStep']} properties={properties}>
          <Grid item xs={12} container className={classes.greyVariant}>
            <Grid item xs={12}>
              <Title label={labels.axisX} icon={<ArrowForwardIcon />} />
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <InputProxy pkey={'freqStep'} labels={labels} properties={properties} />
              </Grid>
            </Grid>
          </Grid>
        </Proxy>
        <Proxy keys={yKeys} properties={properties}>
          <Grid item xs={12} container className={classes.greyVariant}>
            <Grid item xs={12}>
              <Title label={labels.axisY} icon={<ArrowUpwardIcon />} />
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                {R.map(
                  key => (
                    <InputProxy
                      pkey={key}
                      mkey={R.init(key)}
                      key={key}
                      lg={12 / R.length(yKeys)}
                      labels={labels}
                      properties={properties}
                    />
                  ),
                  yKeys,
                )}
              </Grid>
            </Grid>
          </Grid>
        </Proxy>
      </Grid>
      <Proxy keys={eKeys} properties={properties}>
        <Grid item xs={12} container spacing={2}>
          <Grid item xs={12} container>
            <DataEdit labels={labels} properties={properties} />
          </Grid>
        </Grid>
      </Proxy>
      <Proxy keys={['displayMode']} properties={properties}>
        <Grid item xs={12} container spacing={2}>
          <Grid item xs={12} container>
            <Grid item container spacing={1} alignItems="center" style={{ minWidth: 320 }}>
              <Grid item xs>
                <Typography variant="body2">{labels.display}</Typography>
              </Grid>
              {R.map(
                option => (
                  <Grid item key={option.value}>
                    <Button
                      variant={option.value === displayMode.value ? 'contained' : 'outlined'}
                      onClick={() => displayMode.onChange(option.value)}
                      color="primary"
                    >
                      <Typography variant="body2">
                        {R.path(['displayOptions', option.value], labels)}
                      </Typography>
                    </Button>
                  </Grid>
                ),
                displayModeOptions,
              )}
            </Grid>
          </Grid>
        </Grid>
      </Proxy>
    </Grid>
  );
};

const submitableInputProptypes = PropTypes.shape({
  id: PropTypes.string,
  isActive: PropTypes.bool,
  onSubmit: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
});

const selectableInputProptypes = PropTypes.shape({
  id: PropTypes.string,
  isActive: PropTypes.bool,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
  value: PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  }),
});

const multiSelectableInputProptypes = PropTypes.shape({
  id: PropTypes.string,
  isActive: PropTypes.bool,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
  value: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
});

ChartConfig.propTypes = {
  labels: PropTypes.object,
  properties: PropTypes.shape({
    highlight: multiSelectableInputProptypes,
    baseline: multiSelectableInputProptypes,
    width: submitableInputProptypes,
    height: submitableInputProptypes,
    scatterDimension: selectableInputProptypes,
    scatterX: selectableInputProptypes,
    scatterY: selectableInputProptypes,
    symbolDimension: selectableInputProptypes,
    stackedDimension: selectableInputProptypes,
    stackedMode: PropTypes.shape({
      id: PropTypes.string,
      isActive: PropTypes.bool,
      onChange: PropTypes.func,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.string,
        }),
      ),
      value: PropTypes.string,
    }),
    freqStep: submitableInputProptypes,
    maxX: submitableInputProptypes,
    minX: submitableInputProptypes,
    pivotX: submitableInputProptypes,
    stepX: submitableInputProptypes,
    maxY: submitableInputProptypes,
    minY: submitableInputProptypes,
    pivotY: submitableInputProptypes,
    stepY: submitableInputProptypes,
    title: submitableInputProptypes,
    subtitle: submitableInputProptypes,
    source: submitableInputProptypes,
  }),
};

export default ChartConfig;
