import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import { validate as getIsValidEmail } from 'isemail';
import Input from '../Input';
import Alert from '../Alert';
import { Button } from '../';

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(0, 1),
    display: 'flex',
    alignItems: 'flex-start',
  },
  containerSubmitButton: {
    margin: theme.spacing(1, 0),
    display: 'flex',
    justifyContent: 'flex-end',
  },
  alertMessage: {
    display: 'flex',
    flexDirection: 'column',
  },
  input: {
    margin: 0,
  },
  close: {
    alignItems: 'flex-start',
  },
  alert: {
    margin: theme.spacing(0, 1),
  },
}));

const Form = ({
  hasError,
  isMessageOpen,
  isSharing,
  mail = '',
  share,
  changeMail,
  changeIsMessageOpen,
  labels,
}) => {
  const [emailHasError, setEmailError] = React.useState(false);
  const classes = useStyles();

  const onChange = value => {
    if (getIsValidEmail(mail)) {
      setEmailError(false);
    }
    if (R.is(Function)(changeMail)) return changeMail(value);
  };

  const onSubmit = () => {
    if (!getIsValidEmail(mail)) {
      setEmailError(true);
      return;
    }
    if (R.is(Function)(share)) return share();
  };

  if (isMessageOpen) {
    return (
      <Alert
        className={classes.alert}
        classes={{ action: classes.close }}
        variant="filled"
        severity={hasError ? 'error' : 'success'}
        onClose={() => changeIsMessageOpen(false)}
      >
        <div className={classes.alertMessage}>
          <Typography variant="body2">
            {hasError ? R.prop('errorTitle')(labels) : R.prop('successTitle')(labels)}
          </Typography>
          <Typography variant="body2">
            {hasError ? R.prop('errorMessage')(labels) : R.prop('successMessage')(labels)}
          </Typography>
        </div>
      </Alert>
    );
  }

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12}>
        <Input
          inputProps={{
            'aria-label': R.prop('email')(labels),
            autoComplete: 'email',
          }}
          className={classes.input}
          label={R.prop('email')(labels)}
          type="email"
          value={mail}
          onChange={onChange}
          variant="outlined"
          fullWidth
          isControlled
          textFieldProps={{ error: emailHasError }}
          onSubmit={onSubmit}
        />
      </Grid>
      <Grid item xs={12} className={classes.containerSubmitButton}>
        <Button
          aria-label={R.propOr('', 'submit')(labels)}
          type="submit"
          disabled={isSharing || R.isNil(share)}
          variant="contained"
          color="primary"
          alternative="siscc"
          onClick={onSubmit}
          className={classes.submitButton}
        >
          {isSharing ? <CircularProgress size={24} /> : R.propOr('', 'submit')(labels)}
        </Button>
      </Grid>
    </Grid>
  );
};

Form.propTypes = {
  hasError: PropTypes.bool,
  isMessageOpen: PropTypes.bool,
  isSharing: PropTypes.bool,
  mail: PropTypes.string,
  share: PropTypes.func,
  changeMail: PropTypes.func,
  changeIsMessageOpen: PropTypes.func,
  labels: PropTypes.shape({
    submit: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    email: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    successTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    successMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    errorTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    errorMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  }),
};

export default Form;
