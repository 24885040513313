import PropTypes from 'prop-types';
import * as R from 'ramda';

const Proxy = ({ keys, properties, children }) => {
  if (!R.any(R.flip(R.has)(properties), keys)) return null;

  return children;
};

Proxy.propTypes = {
  children: PropTypes.node,
  keys: PropTypes.array,
  properties: PropTypes.object,
};

export default Proxy;
