import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { pathOr } from 'ramda';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Collapse from '@material-ui/core/Collapse';
import Button from '@material-ui/core/Button';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

const VARIANT_ICON = 'icon';
export const VARIANT_BOLD = 'bold';

const useStyles = makeStyles(theme => ({
  buttonExpand: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  [`button${VARIANT_ICON}`]: {
    color: theme.palette.primary.main,
    backgroundColor: 'rgba(24, 32, 38, 0.04)',
    margin: theme.spacing(1, 0),
  },
  [`button${VARIANT_BOLD}`]: {
    marginBottom: theme.spacing(1),
    padding: theme.spacing(0.5, 0, 1, 0),
    '&:hover': {
      backgroundColor: 'unset',
    },
    ...pathOr({}, ['mixins', 'collapsibleTree', 'buttonSublevel'], theme),
  },
  [`collapsibleContainer${VARIANT_BOLD}`]: {
    borderTop: '2px solid black',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

const CollapseComponent = ({ label, isOpen, children, variant = VARIANT_ICON, level }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(isOpen);

  useEffect(() => setOpen(isOpen), [isOpen]);

  return (
    <div
      className={cx(classes[`collapsibleContainer${variant}`])}
      style={{
        marginRight: theme.spacing(level * 2),
        marginLeft: theme.spacing(level * 2),
      }}
    >
      <Button
        className={cx(classes.buttonExpand, classes[`button${variant}`])}
        onClick={() => setOpen(!open)}
        fullWidth
      >
        {label}
        {open ? <KeyboardArrowUpIcon color="primary" /> : <KeyboardArrowDownIcon color="primary" />}
      </Button>
      <Collapse in={open} timeout="auto">
        {children}
      </Collapse>
    </div>
  );
};

CollapseComponent.propTypes = {
  label: PropTypes.string,
  isOpen: PropTypes.bool,
  children: PropTypes.node,
  variant: PropTypes.oneOf([VARIANT_ICON, VARIANT_BOLD]),
  level: PropTypes.number,
};

export default CollapseComponent;
