import React from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Tooltip from '../Tooltip/Tooltip';

const useStyles = makeStyles(theme => ({
  font: {
    color: theme.palette.grey[600],
  },
  spaceContainer: {
    paddingLeft: theme.spacing(1.5),
  },
  gridContainer: {
    padding: theme.spacing(0.5, 1.5),
  },
}));

const TableFooter = ({ tooltip, label, logo, link, linkLabel, height = 25 }) => {
  const classes = useStyles();
  return (
    <Grid container direction="row" alignItems="center" spacing={2}>
      <Grid item xs={1} className={classes.gridContainer}>
        <Tooltip
          placement="top"
          interactive
          title={
            <Typography variant="body2">
              <Typography variant="inherit">{R.prop('label')(tooltip)}</Typography>
              <a className={classes.spaceContainer} href={R.prop('link')(tooltip)}>
                {R.prop('linkLabel')(tooltip)}
              </a>
            </Typography>
          }
        >
          <Typography variant="body2" className={classes.font}>
            {R.ifElse(R.isNil, R.always('©'), R.identity)(label)}
          </Typography>
        </Tooltip>
      </Grid>
      <Grid item xs={11} className={classes.gridContainer}>
        <Grid container justifyContent="flex-end">
          <Typography variant="body2" className={classes.font}>
            <a href={link}>{linkLabel}</a>
          </Typography>
          <img className={classes.spaceContainer} height={height} src={logo} alt="logo" />
        </Grid>
      </Grid>
    </Grid>
  );
};

TableFooter.propTypes = {
  tooltip: PropTypes.shape({
    link: PropTypes.string,
    linkLabel: PropTypes.string,
    label: PropTypes.string,
  }),
  label: PropTypes.string,
  logo: PropTypes.string,
  link: PropTypes.string,
  linkLabel: PropTypes.string,
  height: PropTypes.number,
  classes: PropTypes.object,
};

export default TableFooter;
