import React, { useEffect } from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { Typography } from '@material-ui/core';
import cx from 'classnames';

const useStyles = makeStyles(theme => ({
  root: {
    ...theme.typography.body2,
  },
  paper: {
    maxHeight: 320,
  },
  typo: {
    whiteSpace: 'break-spaces',
  },
  disabledLabel: {
    color: theme.palette.grey[700],
  },
}));

const Select = ({
  id,
  optionsKey,
  value = '',
  label,
  items = [],
  isMultiple,
  onChange,
  textFieldProps = {},
  valueAccessor,
  labelAccessor,
  disabled,
  dataTestId = 'select-testid',
}) => {
  const classes = useStyles();
  const ref = React.useRef(null);
  const [open, setOpen] = React.useState(false);
  const [width, setWidth] = React.useState(0);

  const handleChange = event => {
    if (R.is(Function)(onChange)) {
      onChange(event.target.value);
    }
  };

  useEffect(() => {
    if (ref.current && open) setWidth(ref.current.clientWidth);
  }, [open]);

  const getter = key => accessor =>
    R.ifElse(
      R.is(Object),
      item => (R.is(Function, accessor) ? accessor(item) : R.prop(key)(item)),
      R.identity,
    );

  const joinByValue = R.innerJoin((obj, value) => obj.value === value);
  const labels = R.pluck('label', joinByValue(items, R.is(Array, value) ? value : [value]));
  return (
    <TextField
      select
      id={id}
      data-testid={dataTestId}
      disabled={R.isEmpty(items)}
      label={label}
      value={value}
      title={R.join(', ', labels)}
      onChange={handleChange}
      variant="outlined"
      margin="dense"
      size="small"
      fullWidth
      SelectProps={{
        ref,
        open,
        autoWidth: true,
        multiple: isMultiple,
        disabled,
        onOpen: () => setOpen(true),
        onClose: () => setOpen(false),
        classes: { root: classes.root },
        renderValue: () => (
          <Typography variant="inherit" className={classes.typo}>
            {R.join(', ', labels)}
          </Typography>
        ),
        MenuProps: {
          getContentAnchorEl: null,
          anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
          transformOrigin: { vertical: 'top', horizontal: 'center' },
          classes: { paper: classes.paper },
          PaperProps: {
            style: { width },
          },
        },
      }}
      className={classes.root}
      {...textFieldProps}
    >
      {R.map(item => {
        const value = getter('value')(valueAccessor)(item);
        const label = getter('label')(labelAccessor)(item);
        return (
          <MenuItem
            key={`${optionsKey}-${value}`}
            id={label}
            value={value}
            dense
            aria-label={label}
            title={label}
            disabled={R.propOr(false, 'disabled')(item)}
          >
            <Typography
              variant="inherit"
              className={cx(classes.typo, {
                [classes.disabledLabel]: R.propOr(false, 'disabled')(item),
              })}
            >
              {label}
            </Typography>
          </MenuItem>
        );
      })(items)}
    </TextField>
  );
};

Select.propTypes = {
  id: PropTypes.string,
  optionsKey: PropTypes.string,
  dataTestId: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.element]),
  items: PropTypes.array,
  onChange: PropTypes.func,
  textFieldProps: PropTypes.object,
  valueAccessor: PropTypes.func,
  labelAccessor: PropTypes.func,
  isMultiple: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default Select;
