import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  title: {
    fontFamily: 'inherit',
  },
}));

const Title = ({ icon, label }) => {
  const classes = useStyles();

  return (
    <Grid container>
      {!R.isNil(icon) && icon}
      <Typography variant="h6" className={classes.title} inline="true">
        {label}
      </Typography>
    </Grid>
  );
};

Title.propTypes = {
  label: PropTypes.string,
  icon: PropTypes.node,
};

export default Title;
