import React from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Flags from './flags';
import { getIsRtl } from '../utils';
import { getValue } from './utils';

const useStyles = makeStyles(theme => ({
  tableHeadCell: {
    color: theme.palette.getContrastText(theme.palette.primary.main),
    backgroundColor: theme.palette.primary.main,
    border: 'inherit',
    borderBottom: `thin solid ${theme.palette.grey[500]}`,
    borderRight: `thin solid ${theme.palette.grey[500]}`,
    padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
    verticalAlign: 'top',
    '&:focus ': {
      opacity: '.85',
    },
    textAlign: 'center',
  },
  cellContentCenter: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'sticky',
  },
  cellContentRight: {
    display: 'flex',
    justifyContent: 'flex-end',
    fontStyle: 'italic',
  },
  cellLabelHeader: {
    fontStyle: 'inherit',
    fontWeight: 'bold',
  },
  stickyLabel: {
    position: 'sticky',
  },
}));

const timesIndexed = R.addIndex(R.times);
const mapIndexed = R.addIndex(R.map);

const Header = ({
  headerData,
  valuesHeaderData,
  xLayoutDataLength,
  subHeadCellsWidth,
  HTMLRenderer,
  labelAccessor,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isRtl = getIsRtl(theme);

  const stickyLabelPosition = R.last(subHeadCellsWidth) + 30 + theme.spacing(1);

  if (R.compose(R.anyPass([R.isNil, R.isEmpty]), R.path([0, 'data']))(headerData)) return null;
  return timesIndexed(index => {
    const flags = R.path([0, 'data', index, 'dimension', 'flags'], headerData);
    const id = `header_${index}`;
    return (
      <TableRow key={id}>
        <TableCell
          component="th"
          padding="none"
          align="center"
          className={classes.tableHeadCell}
          colSpan={R.inc(xLayoutDataLength)}
          id={id}
          tabIndex={0}
          scope="row"
          title={R.pipe(R.path([-1, 'data', index, 'dimension']), labelAccessor)(headerData)}
          style={
            isRtl
              ? { position: 'sticky', zIndex: 1, right: 0 }
              : { position: 'sticky', zIndex: 1, left: 0 }
          }
        >
          <div
            className={classes.cellContentRight}
            aria-label={R.path([0, 'data', index, 'dimension', 'id'], headerData)}
            data-testid={`header-dim`}
            role="row"
          >
            <Typography variant="body1" className={classes.cellLabelHeader}>
              {R.pipe(R.path([-1, 'data', index, 'dimension']), labelAccessor)(headerData)}
              <Flags
                HTMLRenderer={HTMLRenderer}
                flags={flags}
                isHeader
                labelAccessor={labelAccessor}
              />
            </Typography>
          </div>
        </TableCell>
        {mapIndexed((headerItem, idx) => {
          const value = headerItem.isEmpty ? { label: '' } : getValue(labelAccessor)(headerItem);
          const flags = R.propOr([], 'flags', value);
          const hierarchySpace = R.pipe(
            R.propOr([], 'parents'),
            R.length,
            R.times(() => '·'),
          )(value);
          return (
            <TableCell
              key={`${R.prop('id', value)}-${idx}`}
              component="th"
              className={cx(classes.tableHeadCell, {
                [classes.topCell]: R.not(R.isEmpty(flags)),
              })}
              id={`${idx}_${index}`}
              headers={`header_${index}`}
              colSpan={R.prop('spanCount', headerItem)}
              tabIndex={0}
              title={R.prop('label', value)}
              aria-label={R.prop('label', value)}
              aria-describedby={id}
              scope="col"
            >
              <div className={classes.cellContentCenter}>
                {!R.isEmpty(hierarchySpace) &&
                  mapIndexed((space, idx) => <div key={idx}>{space}</div>)(hierarchySpace)}
                <Typography
                  className={classes.stickyLabel}
                  style={{
                    left: isRtl ? theme.spacing(1) : stickyLabelPosition,
                    right: isRtl ? stickyLabelPosition : theme.spacing(1),
                  }}
                  variant="body1"
                >
                  {R.prop('label', value)}
                  <Flags
                    HTMLRenderer={HTMLRenderer}
                    flags={flags}
                    isHeader
                    labelAccessor={labelAccessor}
                  />
                </Typography>
              </div>
            </TableCell>
          );
        }, R.nth(index, valuesHeaderData))}
      </TableRow>
    );
  }, R.compose(R.length, R.pathOr([], [-1, 'data']))(headerData));
};

Header.propTypes = {
  headerData: PropTypes.array,
  valuesHeaderData: PropTypes.array,
  xLayoutDataLength: PropTypes.number,
  subHeadCellsWidth: PropTypes.array,
  HTMLRenderer: PropTypes.func,
  labelAccessor: PropTypes.func,
};

export default Header;
