import React, { useState } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import cx from 'classnames';
import {
  getEvolvedDisableAccessor,
  getHierarchicalId,
  getScopeGetters,
} from '../VirtualizedTree/utils';
import IconButton from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Draggable from 'react-draggable';
import { Button, Spotlight, Tooltip, VirtualizedTree } from '..';
import { withExpansionTree } from '../VirtualizedTree/withExpansionTree';
import { withSpotlight } from '../VirtualizedTree/withSpotlight';
import { makeStyles } from '@material-ui/core/styles';
import HintIcon from '@material-ui/icons/EmojiObjects';
import singleImg from './images/single-selection.png';
import childrenImg from './images/children-selection.png';
import branchImg from './images/branch-selection.png';
import levelImg from './images/level-selection.png';
import { Divider } from '@material-ui/core';

const PaperComponent = props => (
  <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
    <Paper style={{ minWidth: '75%', height: '75%' }} {...props} />
  </Draggable>
);

const useStyles = makeStyles(theme => ({
  header: {
    color: 'black',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  headerLabel: {
    fontSize: '16px',
    fontFamily: 'PT Sans Narrow',
    fontWeight: '700',
    color: theme.palette.grey[700],
  },
  selectionTitle: {
    fontSize: '12px',
    fontFamily: "'Helvetica Neue', Helvetica, Arial, sans-serif",
    fontWeight: '500',
    color: theme.palette.grey['A700'],
  },
  closeButton: {
    color: 'grey !important',
    padding: 0,
  },
  selectMode: {
    marginLeft: 10,
    '& .MuiInputBase-root': {
      height: 32,
    },
  },
  narrowTop: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  spotlight: { width: '45%' },
  selection: {
    display: 'flex',
    alignItems: 'center',
  },
  narrowSpotlight: { width: '100%' },
  selectionButton: {
    marginLeft: 24,
    marginRight: 15,
    height: 32,
  },
  narrowSelectionButton: {
    height: 32,
    marginTop: 15,
    marginRight: 10,
  },
  selectionMenu: {
    width: '50%',
    maxHeight: '100%',
    position: 'absolute',
    top: 110,
    right: 48,
    zIndex: 1,
  },
  narrowSelectionMenu: {
    width: '100%',
  },
  selectModeContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  selectButton: {
    color: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.action.hover,
    },
  },
  selectModeItem: {
    boxShadow: 'none',
    display: 'flex',
    backgroundColor: 'inherit',
    flexDirection: 'row-reverse',
    alignItems: 'center',
    '& p': {
      paddingLeft: '5px',
      fontSize: '12px',
      fontFamily: "'Helvetica Neue', Helvetica, Arial, sans-serif",
      color: theme.palette.primary.main,
      fontWeight: '500',
      lineHeight: 1.43,
      textAlign: 'left',
    },
    '& img': {
      width: 21,
      height: 24,
    },
  },
  hint: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: 7,
    color: theme.palette.primary.main,
  },
  divider: {
    backgroundColor: theme.palette.primary.light,
  },
  actions: {
    justifyContent: 'flex-end',
  },
  ellipsis: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  tooltip: {
    backgroundColor: theme.palette.action.hover,
  },
  subheader: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },
  narrowHeader: {
    alignItems: 'flex-start',
  },
}));

const AdvancedFilterDialog = props => {
  const {
    id,
    title,
    items,
    labelRenderer,
    onClose,
    changeSelection,
    isOpen,
    isNarrow,
    labels,
    disableAccessor = R.always(false),
    expandAll,
    collapseAll,
    spotlight,
    setSpotlight,
    expandedIds,
    allItems,
    ...rest
  } = props;
  const [selectionMode, setSelectionMode] = useState('single');
  const [selection, setSelection] = useState({});
  const classes = useStyles();
  const [height, setHeight] = useState(0);
  const getTotal = (items, spotlightAcc) => {
    const itemsIds = R.pluck('id', items);
    const total = R.pipe(R.uniq, R.length)(itemsIds);
    if (R.is(Function, spotlightAcc)) {
      return spotlightAcc(total);
    }
    return total;
  };
  const measureRef = React.useCallback(node => {
    if (node !== null) {
      setHeight((node.getBoundingClientRect().height * 75) / 100);
    }
  });

  const listHeight = height - 220;
  const selectionItems = R.map(
    item => ({
      ...item,
      isSelected: R.has(item.id, selection) ? !item.isSelected : !!item.isSelected,
    }),
    items,
  );
  const indexedFilteredItems = R.indexBy(getHierarchicalId, selectionItems);

  const selectionOptions = [
    [
      {
        value: 'single',
        label: R.prop('singleSelection', labels),
        img: singleImg,
      },
      {
        value: 'children',
        label: R.prop('childrenSelection', labels),
        img: childrenImg,
      },
    ],
    [
      {
        value: 'branch',
        label: R.prop('branchSelection', labels),
        img: branchImg,
      },
      {
        value: 'level',
        label: R.prop('levelSelection', labels),
        img: levelImg,
      },
    ],
  ];

  const _allGroupedItems = R.pipe(
    R.map(item => ({
      ...item,
      isSelected: R.has(item.id, selection) ? !item.isSelected : !!item.isSelected,
    })),
    R.groupBy(R.propOr('#ROOT', 'parentId')),
  )(allItems);

  const isChildInSelectionScope = (child, ancestor) => {
    if (!R.has(getHierarchicalId(child), indexedFilteredItems)) {
      return false;
    }
    if (selectionMode === 'single' || selectionMode === 'level') {
      return false;
    }
    if (selectionMode === 'children') {
      return child.parentId === getHierarchicalId(ancestor);
    }
    return true;
  };

  const _disableAccessor = getEvolvedDisableAccessor(
    _allGroupedItems,
    disableAccessor,
    isChildInSelectionScope,
  );

  const isDisabled = item => (R.is(Function, disableAccessor) ? disableAccessor(item) : false);
  const scopeGetter = R.prop(selectionMode, getScopeGetters);

  const onChangeSelection = ids => {
    const nextSelection = R.reduce(
      (acc, id) => (R.has(id, acc) ? R.dissoc(id, acc) : R.assoc(id, id, acc)),
      selection,
      ids,
    );
    setSelection(nextSelection);
  };
  const handleSubmit = () => {
    changeSelection(id, R.values(selection), { expandedIds, spotlight });
    setSelection({});
    setSpotlight({ term: '' });
  };
  const rejectonlyDisabledItems = item => _disableAccessor(item);
  const getSelectedItems = item => R.propOr(false, 'isSelected')(item);

  const selectAll = () => {
    const imposedIds = R.reduce(
      (acc, item) => R.uniq(R.concat(R.propOr([], 'imposedIds', item), acc)),
      [],
    )(items);
    const ids = R.pluck('id', R.reject(getSelectedItems, R.reject(rejectonlyDisabledItems, items)));
    const allIds = R.concat(imposedIds, ids);
    const nextSelection = R.reduce(
      (acc, id) => (R.has(id, acc) ? acc : R.assoc(id, id, acc)),
      {},
      allIds,
    );
    setSelection(nextSelection);
  };

  const deselectAll = () => {
    const itemIds = R.pipe(R.filter(getSelectedItems), R.pluck('id'))(items);
    const ids = R.difference(itemIds, selection);
    const nextSelection = R.reduce(
      (acc, id) => (R.has(id, acc) ? acc : R.assoc(id, id, acc)),
      {},
      ids,
    );
    setSelection(nextSelection);
  };
  const mapIndexed = R.addIndex(R.map);
  return (
    <Dialog
      open={isOpen}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      onClose={onClose}
      ref={measureRef}
      aria-modal={true}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
        <div className={cx(classes.header, { [classes.narrowHeader]: isNarrow })}>
          <div className={isNarrow ? classes.subheader : classes.header}>
            <div className={classes.headerLabel} aria-label={title}>
              &nbsp;{title}
            </div>
            <div className={cx(classes.selectModeContainer, { [classes.narrowHeader]: isNarrow })}>
              <div className={classes.hint}>
                <Tooltip
                  variant="light"
                  title={<Typography variant="body2">{R.prop('hint', labels)}</Typography>}
                  aria-label={R.prop('hint', labels)}
                  tabIndex={0}
                >
                  <HintIcon />
                </Tooltip>
                <Typography
                  className={classes.selectionTitle}
                  aria-label={R.prop('selectionMode', labels)}
                  tabIndex={0}
                >
                  {R.prop('selectionMode', labels)}
                </Typography>
              </div>
              <div
                className={cx(classes.selectModeContainer, { [classes.narrowHeader]: isNarrow })}
              >
                {mapIndexed(
                  (el, index) => (
                    <div key={index}>
                      {R.map(
                        ({ value, label, img }) => (
                          <Tooltip
                            key={value}
                            classes={{ tooltip: classes.tooltip }}
                            title={
                              <img
                                style={{ width: '72px', height: '90px' }}
                                src={img}
                                alt={value}
                              />
                            }
                            tabIndex={-1}
                          >
                            <Button
                              className={classes.selectButton}
                              selected={value === selectionMode}
                              onClick={() => {
                                setSelectionMode(value);
                              }}
                              aria-pressed={value === selectionMode}
                              aria-label={label}
                              tabIndex={0}
                            >
                              <Paper className={classes.selectModeItem} key={value}>
                                <Typography>{label}</Typography>
                                {!R.isNil(img) && R.is(String, img) && (
                                  <img src={img} alt={value} />
                                )}
                              </Paper>
                            </Button>
                          </Tooltip>
                        ),
                        el,
                      )}
                    </div>
                  ),
                  selectionOptions,
                )}
              </div>
            </div>
          </div>
          <div>
            {onClose && (
              <IconButton
                className={classes.closeButton}
                onClick={onClose}
                aria-label={R.prop('cancel', labels)}
              >
                <CloseIcon />
              </IconButton>
            )}
          </div>
        </div>
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={12} className={cx(classes.topElements, { [classes.narrowTop]: isNarrow })}>
            <div className={isNarrow ? classes.narrowSpotlight : classes.spotlight}>
              <Spotlight
                hasClearAll={true}
                placeholder={getTotal(items, R.prop('placeholder', labels))}
                term={spotlight.term}
                hasCommit={false}
                spotlight={{ fields: {} }}
                action={setSpotlight}
              />
            </div>
            <div style={{ display: 'flex', flexDirection: isNarrow ? 'column' : 'row' }}>
              <div>
                <Button onClick={collapseAll} color="primary">
                  {R.prop('colapseAll', labels)}
                </Button>
                <Button style={{ marginLeft: 10 }} onClick={expandAll} color="primary">
                  {R.prop('expandAll', labels)}
                </Button>
              </div>
              <div>
                <Button onClick={selectAll} color="primary">
                  {R.prop('selectAll', labels)}
                </Button>
                <Button style={{ marginLeft: 10 }} onClick={deselectAll} color="primary">
                  {R.prop('deselectAll', labels)}
                </Button>
              </div>
            </div>
            <Divider className={classes.divider} />
          </Grid>
          <Grid item xs={12}>
            <VirtualizedTree
              {...rest}
              disableAccessor={_disableAccessor}
              isGreyed={isDisabled}
              labels={labels}
              labelRenderer={labelRenderer}
              items={selectionItems}
              changeSelection={onChangeSelection}
              withExpandControl={true}
              treeHeight={listHeight}
              scopeGetter={scopeGetter}
              expandedIds={expandedIds}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <div>
          {R.is(Function, onClose) && (
            <Button
              onClick={() => {
                onClose();
                setSelection({});
                setSpotlight({ term: '' });
              }}
              color="primary"
            >
              {R.prop('cancel', labels)}
            </Button>
          )}
          {R.is(Function, changeSelection) && (
            <Button
              disabled={R.isEmpty(selection)}
              onClick={handleSubmit}
              color="primary"
              variant="contained"
              alternative="siscc"
              style={{ marginLeft: 10 }}
            >
              {R.prop('apply', labels)}
            </Button>
          )}
        </div>
      </DialogActions>
    </Dialog>
  );
};

AdvancedFilterDialog.propTypes = {
  id: PropTypes.string,
  accessibility: PropTypes.bool,
  changeSelection: PropTypes.func,
  disableAccessor: PropTypes.func,
  HTMLRenderer: PropTypes.func,
  isRtl: PropTypes.bool,
  isNarrow: PropTypes.bool,
  isOpen: PropTypes.bool,
  items: PropTypes.array,
  labels: PropTypes.shape({
    placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    disableItemLabel: PropTypes.string,
    singleSelection: PropTypes.string,
    childrenSelection: PropTypes.string,
    branchSelection: PropTypes.string,
    levelSelection: PropTypes.string,
    selectAll: PropTypes.string,
    deselectAll: PropTypes.string,
    apply: PropTypes.string,
    cancel: PropTypes.string,
  }),
  onClose: PropTypes.func,
  labelRenderer: PropTypes.func,
  title: PropTypes.string,
  expandAll: PropTypes.func,
  collapseAll: PropTypes.func,
  spotlight: PropTypes.object,
  setSpotlight: PropTypes.func,
  expandedIds: PropTypes.object,
  allItems: PropTypes.array,
};

export default withSpotlight(withExpansionTree(AdvancedFilterDialog));
