import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import SvgIcon from '@material-ui/core/SvgIcon';

const useStyles = makeStyles(theme => ({
  paperItem: {
    alignItems: 'baseline',
  },
  itemTitle: {
    ...theme.typography.body2,
    color: theme.palette.primary.main,
  },
  button: {
    '&:hover': {
      backgroundColor:
        R.path(['palette', 'tertiary', 'light'])(theme) || theme.palette.secondary.light,
    },
    padding: theme.spacing(1.25),
    textAlign: 'left',
    textTransform: 'none',
    justifyContent: 'flex-start',
    display: 'flex',
  },
  itemSubtitle: {
    ...theme.typography.body1,
    display: 'flex',
    flexDirection: 'column',
    color: theme.palette.grey[800],
  },
  itemSubtitleLink: {
    ...theme.typography.body1,
    color: theme.palette.grey[800],
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  svg: {
    color: theme.palette.grey[600],
    fontSize: '1rem',
    marginRight: theme.spacing(0.5),
    marginTop: theme.spacing(0.25),
    marginBottom: 'auto',
  },
}));

const Item = ({ parentId, item, action, testId, isSecondLevelClikable, labelAccessor }) => {
  const classes = useStyles();

  return (
    <Grid className={classes.paperItem}>
      <Button
        aria-label={R.prop('label')(item)}
        className={classes.button}
        fullWidth
        data-testid={testId}
        role="link"
        onClick={() => {
          if (R.not(R.is(Function)(action))) return;
          action(parentId, R.prop('id')(item));
        }}
        tabIndex={0}
      >
        <SvgIcon className={classes.svg}>
          <path d={R.prop('svgPath')(item)} />
        </SvgIcon>
        <Grid container item xs={12} direction="column">
          <span className={classes.itemTitle}>{labelAccessor(item, parentId)}</span>
          <span className={classes.itemSubtitle}>
            {R.map(subtopic => {
              if (R.not(R.is(Object)(subtopic))) return subtopic;
              if (R.not(isSecondLevelClikable)) return labelAccessor(subtopic, parentId);
              const id = R.prop('id')(subtopic);
              return (
                <Link
                  key={id}
                  className={classes.itemSubtitleLink}
                  role="link"
                  onClick={e => {
                    if (R.not(R.is(Function)(action))) return;
                    action(parentId, id);
                    e.stopPropagation();
                  }}
                  tabIndex={0}
                  style={{ display: 'list-item' }}
                >
                  {labelAccessor(subtopic, parentId)}
                </Link>
              );
            })(
              R.pipe(
                R.propOr([], 'subtopics'),
                R.ifElse(R.always(isSecondLevelClikable), R.identity, R.intersperse(' • ')),
              )(item),
            )}
          </span>
        </Grid>
      </Button>
    </Grid>
  );
};

Item.propTypes = {
  parentId: PropTypes.string,
  testId: PropTypes.string,
  item: PropTypes.object,
  action: PropTypes.func,
  isSecondLevelClikable: PropTypes.bool,
  labelAccessor: PropTypes.func,
};

export default Item;
