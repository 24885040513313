import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Grid from '@material-ui/core/Grid';
import Collapse from '@material-ui/core/Collapse';
import Typography from '@material-ui/core/Typography';
import { ToggleButton } from '../';
import Item from './Item';
import Paper from './Paper';

export const MAX_COLUMNS = 12;
export const COLUMNS = [12, 12, 6, 4, 3, 3, 2, 2, 2, 2, 2, 2, 1];

const useStyles = makeStyles(() => ({
  collapseContainer: {
    width: '100%',
  },
}));

const CollapseButtons = props => {
  const {
    items = [],
    action = () => {},
    nbColumns = 3,
    testId = 'collapseButton',
    isSecondLevelClikable,
    gridButtonsProps = {},
    breakpoint = theme => theme.breakpoints.down('sm'),
    justify,
    textTransform = 'none',
    labelAccessor = R.prop('label'),
  } = props;

  const classes = useStyles();
  const isMobileView = useMediaQuery(breakpoint);

  const [toggleId, setIsOpen] = useState();
  useEffect(() => {
    const id = props.selectedItemId;
    if (props.selectedItemId) setIsOpen({ [id]: true });
  }, [props.selectedItemId]);

  const getValues = parentId =>
    R.map(item => (
      <Item
        key={R.prop('id')(item)}
        testId={`${testId}_${parentId}_value_${R.prop('id')(item)}`}
        parentId={parentId}
        item={item}
        action={action}
        isSecondLevelClikable={isSecondLevelClikable}
        labelAccessor={labelAccessor}
      />
    ));

  return (
    <Grid container spacing={2} data-testid={testId} justifyContent={justify}>
      {R.map(({ id, label, values = [] }) => (
        <Grid item xs={12} sm={12} md={R.nth(nbColumns)(COLUMNS)} {...gridButtonsProps} key={id}>
          <Typography variant="h3">
            <ToggleButton
              textTransform={textTransform}
              label={label}
              tabindex={
                R.isNil(toggleId) ||
                R.prop(id)(toggleId) ||
                R.compose(R.not, R.head, R.values)(toggleId)
                  ? 0
                  : -1
              }
              isOpen={R.prop(id)(toggleId)}
              toggle={() => setIsOpen({ [id]: !R.prop(id)(toggleId) })}
              testId={`${testId}_${id}`}
              id={id}
            />
            {isMobileView && (
              <Collapse
                in={R.prop(id)(toggleId)}
                timeout="auto"
                unmountOnExit
                className={classes.collapseContainer}
              >
                <Paper elevation={10} isMobileView={isMobileView}>
                  {getValues(id)(values)}
                </Paper>
              </Collapse>
            )}
          </Typography>
        </Grid>
      ))(items)}
      {!isMobileView &&
        R.map(({ id, values = [] }) => (
          <Collapse
            in={R.prop(id)(toggleId)}
            timeout="auto"
            unmountOnExit
            key={id}
            className={classes.collapseContainer}
          >
            <Paper elevation={10}>{getValues(id)(values)}</Paper>
          </Collapse>
        ))(items)}
    </Grid>
  );
};

CollapseButtons.propTypes = {
  testId: PropTypes.string,
  selectedItemId: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
      svgPath: PropTypes.string,
      values: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
          subtopics: PropTypes.arrayOf(
            PropTypes.oneOfType([
              PropTypes.shape({
                id: PropTypes.string.isRequired,
                label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
              }),
              PropTypes.string,
            ]),
          ),
        }),
      ),
    }),
  ),
  action: PropTypes.func,
  nbColumns: PropTypes.number,
  isSecondLevelClikable: PropTypes.bool,
  breakpoint: PropTypes.func,
  gridButtonsProps: PropTypes.object,
  justify: PropTypes.string,
  textTransform: PropTypes.string,
  labelAccessor: PropTypes.func,
};

export default CollapseButtons;
