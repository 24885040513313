import React from 'react';
import * as R from 'ramda';
import dateFns from 'date-fns';

const spotlightHandler = ({ term, fields }) => item =>
  R.any(field => {
    const itemValue = R.is(Function, field.accessor)
      ? field.accessor(item)
      : R.prop(field.accessor, item);
    if (R.is(Number, itemValue))
      return R.contains(R.toLower(term), R.toLower(R.toString(itemValue)));
    return R.contains(R.toLower(term), R.toLower(itemValue));
  })(fields);

export const reduceChildren = (node, itemsIndexById, func = R.flip(R.prepend), acc = []) => {
  if (R.isNil(node.parentId)) return acc;
  const nextNode = R.prop(node.parentId)(itemsIndexById);
  if (R.isNil(nextNode)) return acc;
  return reduceChildren(nextNode, itemsIndexById, func, func(acc, nextNode));
};

export const spotlightScopeListEngine = ({
  term = '',
  fields,
  items,
  itemsById,
  noPath,
  hasPath,
}) => {
  const isSelected = R.pipe(R.prop('isSelected'), R.equals(true));
  const getFields = R.pipe(R.defaultTo([]), R.filter(isSelected), R.values);

  return R.reduce((acc, item) => {
    if (spotlightHandler({ term, fields: getFields(fields) })(item)) {
      if (noPath) return [...acc, item];
      return [
        ...acc,
        {
          ...item,
          nodePath: hasPath ? R.propOr([], 'path')(item) : reduceChildren(item, itemsById),
        },
      ];
    }
    return acc;
  }, [])(items);
};

export const withBlank = func => Component => props => {
  if (R.pipe(func, R.prop('isBlank'))(props)) return null;
  return <Component {...props} />;
};

export const getLight = R.path(['palette', 'tertiary', 'light']);
export const getDark = R.path(['palette', 'tertiary', 'dark']);

export const getIsRtl = theme => R.equals('rtl', R.prop('direction', theme));
export const newDate = stringDate => {
  const date = new Date(stringDate);
  return dateFns.addMinutes(date, date.getTimezoneOffset());
};
