import React, { useState, Fragment, useEffect } from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Collapse, { VARIANT_BOLD } from './Collapse';
import cx from 'classnames';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  title: {
    fontWeight: 'bold',
    ...R.pathOr({}, ['mixins', 'collapsibleTree', 'title'], theme),
  },
  divider: {
    marginBottom: theme.spacing(2),
  },
  spacingValue: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  overflow: {
    whiteSpace: 'pre-wrap',
    overflowWrap: 'break-word',
  },
}));

const CollapsibleTree = ({
  data = [],
  defaultDepthLevel = 1,
  isLoading,
  children,
  labels,
  testId = 'collapsible-tree',
  valueHandler,
  icon,
  onClick,
}) => {
  const classes = useStyles();
  const [depthLevel, setDepthLevel] = useState(defaultDepthLevel);

  useEffect(() => setDepthLevel(defaultDepthLevel), [defaultDepthLevel]);

  if (isLoading) {
    return (
      <Grid container item justifyContent="center">
        <CircularProgress />
      </Grid>
    );
  }

  const handleCollapse = isAllOpen => {
    if (isAllOpen) return setDepthLevel(undefined);
    return setDepthLevel(defaultDepthLevel);
  };

  const getValue = ({ value, children = [], ...handlerProps }) => {
    if ((R.isNil(value) || R.isEmpty(value)) && R.isEmpty(children)) {
      return R.prop('noValue', labels);
    }
    if (R.is(Function, valueHandler)) {
      return valueHandler({ value, ...handlerProps });
    }
    return value;
  };

  const isAllOpen = depthLevel >= defaultDepthLevel;
  const buttonAllOpenLabel = isAllOpen
    ? R.prop('collapseAll', labels)
    : R.prop('expandAll', labels);

  return (
    <Fragment>
      <Container disableGutters data-testid={testId} className={classes.container}>
        <Button
          aria-label={buttonAllOpenLabel}
          color="primary"
          endIcon={
            isAllOpen ? (
              <KeyboardArrowUpIcon color="primary" />
            ) : (
              <KeyboardArrowDownIcon color="primary" />
            )
          }
          onClick={() => handleCollapse(isAllOpen)}
        >
          {buttonAllOpenLabel}
        </Button>
        <div>
          {children}
          <IconButton color="primary" onClick={onClick}>
            {icon}
          </IconButton>
        </div>
      </Container>
      {R.map(({ id, label, children = [] }) => {
        return (
          <Fragment key={id}>
            <Divider className={classes.divider} />
            <Typography variant="h5" className={classes.title}>
              {label}
            </Typography>
            {R.map(({ id, label, children, value, handlerProps = {} }) => {
              return (
                <Collapse key={id} level={0} label={label} isOpen={depthLevel >= 0}>
                  <Typography
                    className={cx(classes.spacingValue, classes.overflow)}
                    variant="body2"
                  >
                    {getValue({ value, children, ...handlerProps })}
                  </Typography>
                  {children &&
                    R.map(({ id, label, value, handlerProps = {} }) => {
                      return (
                        <Collapse
                          key={id}
                          level={1}
                          variant={VARIANT_BOLD}
                          label={label}
                          isOpen={depthLevel >= 1}
                        >
                          <Typography variant="body2" className={classes.overflow}>
                            {getValue({ value, ...handlerProps })}
                          </Typography>
                        </Collapse>
                      );
                    }, children)}
                </Collapse>
              );
            }, children)}
          </Fragment>
        );
      }, data)}
    </Fragment>
  );
};

CollapsibleTree.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      label: PropTypes.string,
      children: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
          label: PropTypes.string,
          children: PropTypes.arrayOf(
            PropTypes.shape({
              id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
              label: PropTypes.string,
              value: PropTypes.string,
            }),
          ),
        }),
      ),
    }),
  ),
  defaultDepthLevel: PropTypes.number,
  isLoading: PropTypes.bool,
  children: PropTypes.node,
  labels: PropTypes.shape({
    collapseAll: PropTypes.string,
    expandAll: PropTypes.string,
    noValue: PropTypes.string,
  }),
  testId: PropTypes.string,
  valueHandler: PropTypes.func,
  icon: PropTypes.element,
  onClick: PropTypes.func,
};

export default CollapsibleTree;
