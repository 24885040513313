import * as R from 'ramda';

export default theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    height: 'unset',
    borderRadius: 3,
    minHeight: 25,
    fontSize: 12,
    margin: theme.spacing(0.25),
  },
  mainChip: {
    background: 'none',
    color: theme.palette.text.primary,
  },
  secondaryChip: {
    backgroundColor: R.path(['palette', 'tertiary', 'light'])(theme) || theme.palette.grey[100],
    '&:hover, &:focus': {
      backgroundColor: R.path(['palette', 'tertiary', 'dark'])(theme) || theme.palette.grey[300],
    },
    color: theme.palette.primary.main,
  },
  icon: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    margin: 0,
    '&:hover': {
      color: 'inherit',
    },
    '& svg': {
      fontSize: 15,
    },
  },
  divider: {
    margin: theme.spacing(0.5),
    backgroundColor: theme.palette.grey[700],
  },
  dividerHz: {
    width: '100%',
    margin: theme.spacing(0.5),
    backgroundColor: theme.palette.grey[700],
  },
  spaceLabel: {
    paddingLeft: 0,
  },
  formatLabel: {
    whiteSpace: 'pre-wrap',
    overflowWrap: 'anywhere',
  },
  tooltip: theme.typography.body2,
  childrenIndicator: {
    margin: '0 2px',
    fontSize: 10,
  },
  tooltipIndicator: {
    borderBottom: `1px dotted ${theme.palette.primary.main}`,
  },
});
