import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import Container from '@material-ui/core/Container';
import ZoomOutMapIcon from '@material-ui/icons/ZoomOutMap';
import {
  ExpansionPanel,
  Tag as InternalTag,
  VerticalButton,
  Spotlight,
  VirtualizedTree,
  Tooltip,
} from '../';
import { withExpansionTree } from '../VirtualizedTree/withExpansionTree';
import { withSpotlight } from '../VirtualizedTree/withSpotlight';
import { getEvolvedDisableAccessor } from '../VirtualizedTree/utils';
import { useStyles } from '../ScopeList/styles';
import { useTheme } from '@material-ui/core';
import { getIsRtl } from '../utils';

const getCounter = (items, disableAccessor, tagAccessor) => {
  const selectedItemsIds = R.reduce(
    (acc, item) => {
      if (R.prop('isSelected', item)) {
        return R.append(item.id, acc);
      }
      return acc;
    },
    [],
    items,
  );
  const itemsIds = R.pluck('id', items);
  const count = R.pipe(R.uniq, R.length)(selectedItemsIds);
  const total = R.pipe(R.uniq, R.length)(itemsIds);
  if (R.is(Function, tagAccessor)) {
    return tagAccessor(count, total);
  }
  return `${count}/${total}`;
};
const getTotal = (items, spotlightAcc) => {
  const itemsIds = R.pluck('id', items);
  const total = R.pipe(R.uniq, R.length)(itemsIds);
  if (R.is(Function, spotlightAcc)) {
    return spotlightAcc(total);
  }
  return total;
};
const HierarchicalFilter = props => {
  const {
    id,
    items,
    changeSelection,
    onChangeActivePanel,
    activePanelId,
    Tag,
    testId,
    expansionPanelProps = {},
    labelRenderer = R.prop('label'),
    toggleBulk,
    label,
    labels,
    tagAccessor,
    tagAriaLabel,
    expandedIds,
    spotlight,
    setSpotlight,
    hasSpotlight,
    disableAccessor = R.always(false),
    allItems,
    simpleSelectionMode = false,
  } = props;
  const classes = useStyles();
  const theme = useTheme();
  const isRtl = getIsRtl(theme);
  const Chip = R.isNil(Tag) ? InternalTag : Tag;
  const _allGroupedItems = R.groupBy(R.propOr('#ROOT', 'parentId'), allItems);
  const _disableAccessor = simpleSelectionMode
    ? disableAccessor
    : getEvolvedDisableAccessor(_allGroupedItems, disableAccessor, R.always(false));

  const tagValue = getCounter(items, _disableAccessor, tagAccessor);
  const tagValueLabel = getCounter(items, _disableAccessor, tagAriaLabel);
  const isDisabled = item => (R.is(Function, disableAccessor) ? disableAccessor(item) : false);

  const onSelect = values => {
    changeSelection(id, values);
  };

  const onAdvancedToggle = () => {
    toggleBulk({ expandedIds, spotlight });
  };
  return (
    <ExpansionPanel
      isOpen={R.equals(id, activePanelId)}
      id={id}
      testId={`${testId}`}
      label={labelRenderer({ id, label })}
      onChangeActivePanel={onChangeActivePanel}
      overflow={true}
      tagValueLabel={tagValueLabel}
      topElementComponent={
        <Fragment>
          <Container disableGutters className={classes.spotlightContainer} role="presentation">
            {hasSpotlight && (
              <Spotlight
                className={{ container: classes.middle }}
                fullWidth={true}
                hasClearAll={true}
                placeholder={getTotal(items, R.prop('placeholder', labels))}
                term={spotlight.term}
                hasCommit={false}
                spotlight={{ fields: {} }}
                action={setSpotlight}
              />
            )}
            {R.is(Function, toggleBulk) && hasSpotlight && (
              <Tooltip
                title={R.prop('iconLabel')(labels)}
                key={id}
                placement="bottom"
                variant="light"
              >
                <VerticalButton
                  aria-label={R.prop('advancedSelection')(labels)}
                  classes={{ root: classes.bulkSelectionIcon }}
                  onClick={onAdvancedToggle}
                >
                  <ZoomOutMapIcon color="primary" />
                </VerticalButton>
              </Tooltip>
            )}
          </Container>
        </Fragment>
      }
      tag={
        <Chip items={items} tagValueLabel={tagValueLabel}>
          {tagValue}
        </Chip>
      }
      {...expansionPanelProps}
    >
      <VirtualizedTree
        {...props}
        items={items}
        changeSelection={onSelect}
        isRtl={isRtl}
        disableAccessor={_disableAccessor}
        isGreyed={isDisabled}
      />
    </ExpansionPanel>
  );
};

HierarchicalFilter.propTypes = {
  id: PropTypes.string,
  activePanelId: PropTypes.string,
  changeSelection: PropTypes.func,
  onChangeActivePanel: PropTypes.func,
  disableAccessor: PropTypes.func,
  HTMLRenderer: PropTypes.func,
  isRtl: PropTypes.bool,
  items: PropTypes.array,
  label: PropTypes.string,
  labels: PropTypes.shape({
    disableItemLabel: PropTypes.string,
    placeholder: PropTypes.func,
  }),
  labelRenderer: PropTypes.func,
  Tag: PropTypes.func,
  testId: PropTypes.string,
  expansionPanelProps: PropTypes.object,
  toggleBulk: PropTypes.func,
  tagAccessor: PropTypes.func,
  expandedIds: PropTypes.object,
  spotlight: PropTypes.object,
  setSpotlight: PropTypes.func,
  hasSpotlight: PropTypes.bool,
  allItems: PropTypes.array,
  simpleSelectionMode: PropTypes.bool,
  tagAriaLabel: PropTypes.func,
};

export default withSpotlight(withExpansionTree(HierarchicalFilter));
