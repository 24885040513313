import React from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import SvgIcon from '@material-ui/core/SvgIcon';
import Icon from '@material-ui/core/SvgIcon';
import CheckBoxIcon from '@material-ui/icons/CheckBoxOutlineBlankOutlined';
import CheckedBoxIcon from '@material-ui/icons/CheckBoxOutlined';
import cx from 'classnames';
import { Tooltip } from '../';
import useKeyboardEscapeHandler from '../hooks/useKeybordEscapeHandler';

const Item = ({
  classes,
  id,
  label,
  description = '',
  isSelected,
  isDisabled,
  isGreyed,
  changeList,
  ariaLabel,
  NavigateIcon,
  hasChild,
  hasChildrenOnLevel,
  count,
  svgPath,
  style,
  isRtl,
  accessibility,
  HTMLRenderer,
  hierarchicalId,
  labels,
  depth = 0,
  eventsListeners,
  expanded,
}) => {
  const { open, setOpen } = useKeyboardEscapeHandler();
  const padding =
    depth * 30 +
    (depth > 0 && !hasChildrenOnLevel ? 30 : 0) +
    (!hasChild && hasChildrenOnLevel ? 30 : 0);

  return (
    <ListItem
      dense
      disableGutters
      classes={{ disabled: classes.disabled }}
      className={cx(classes.listItemContainer, classes.disableSecondaryAction)}
      tabIndex={-1}
      style={{
        ...style,
        flexDirection: isRtl ? 'row-reverse' : 'row',
        [`${isRtl ? 'paddingRight' : 'paddingLeft'}`]: `${padding}px`,
      }}
      role="listitem"
    >
      {R.and(NavigateIcon, hasChild) && (
        <IconButton
          disableRipple
          disableFocusRipple
          className={classes.arrow}
          aria-label={ariaLabel}
          tabIndex={0}
          size="small"
          color="primary"
          onClick={() => changeList(hierarchicalId || id)}
          aria-expanded={expanded}
        >
          <NavigateIcon />
        </IconButton>
      )}
      <ListItemText
        data-testid={`value_${id}`}
        tabIndex={0}
        role="checkbox"
        aria-label={isGreyed ? `${R.propOr('', 'disableItemLabel')(labels)} ${label}` : label}
        aria-checked={isSelected}
        aria-disabled={isDisabled}
        primaryTypographyProps={{ noWrap: true, color: 'inherit' }}
        secondaryTypographyProps={{ color: 'inherit' }}
        classes={{ root: classes.listItem }}
        className={cx({
          [classes.accessibilityItemHover]: accessibility,
          [classes.listItemSelected]: isSelected,
          [classes.listItemHover]: !isDisabled,
        })}
        onKeyDown={isDisabled ? null : eventsListeners.onKeyDown}
        onClick={isDisabled ? null : eventsListeners.onClick}
        onMouseDown={isDisabled || open ? null : eventsListeners.onMouseDown}
        onMouseEnter={isDisabled || open ? null : eventsListeners.onMouseEnter}
        title={isGreyed ? `${R.propOr('', 'disableItemLabel')(labels)} ${label}` : label}
        style={{
          opacity: isDisabled && isGreyed ? '0.5' : '1',
          outline: accessibility ? undefined : 'none',
        }}
        primary={
          <div
            className={cx(classes.labelContainer, {
              [classes.rtlLabelContainer]: isRtl,
            })}
          >
            <Icon
              color="primary"
              className={cx(classes.labelIcon, { [classes.disabledLabel]: isDisabled })}
              style={{ opacity: isDisabled ? '0.5' : '1' }}
            >
              {isSelected ? <CheckedBoxIcon /> : <CheckBoxIcon />}
            </Icon>
            {svgPath && (
              <SvgIcon
                color="primary"
                className={cx(classes.labelIcon, {
                  [classes.disabledLabel]: isDisabled,
                })}
              >
                <path d={svgPath} />
              </SvgIcon>
            )}
            <Tooltip
              title={
                R.or(R.isNil(HTMLRenderer), R.isEmpty(description)) ? (
                  description
                ) : (
                  <HTMLRenderer html={description} onClick={e => e.stopPropagation()} />
                )
              }
              enterDelay={400}
              enterNextDelay={400}
              tabIndex={description ? 0 : -1}
              aria-label={description}
              aria-hidden={false}
              placement="top"
              interactive={Boolean(HTMLRenderer)}
              onOpen={() => setOpen(true)}
              onClose={() => setOpen(false)}
              disableHoverListener={R.isEmpty(description)}
              open={open}
            >
              <Typography
                color="inherit"
                variant="body2"
                className={cx(classes.label, {
                  [classes.disabledLabel]: isGreyed,
                  [classes.tooltipNotice]: !R.isEmpty(description),
                })}
              >
                {label}
              </Typography>
            </Tooltip>
            {R.not(R.isNil(count)) && (
              <Typography variant="body2" className={classes.count}>
                ({count})
              </Typography>
            )}
          </div>
        }
      />
    </ListItem>
  );
};

Item.propTypes = {
  classes: PropTypes.object,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
  description: PropTypes.string,
  parentId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ariaLabel: PropTypes.string,
  NavigateIcon: PropTypes.object,
  isSelected: PropTypes.bool,
  isDisabled: PropTypes.bool,
  hasChild: PropTypes.bool,
  hasChildrenOnLevel: PropTypes.bool,
  isSection: PropTypes.bool,
  changeList: PropTypes.func,
  changeListDescription: PropTypes.string,
  count: PropTypes.number,
  svgPath: PropTypes.string,
  style: PropTypes.object,
  isRtl: PropTypes.bool,
  accessibility: PropTypes.bool,
  labelRenderer: PropTypes.func,
  HTMLRenderer: PropTypes.func,
  hierarchicalId: PropTypes.string,
  labels: PropTypes.object,
  depth: PropTypes.number,
  eventsListeners: PropTypes.object,
  isGreyed: PropTypes.bool,
  expanded: PropTypes.bool,
};

export default Item;
