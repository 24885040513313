import React from 'react';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { makeStyles } from '@material-ui/core/styles';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import CloseIcon from '@material-ui/icons/Close';
import { Alert, Button } from '../';
import { CircularProgress } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  container: {
    boxShadow: 'none',
    width: '100%',
    padding: 20,
  },
  textarea: {
    width: '100%',
    margin: theme.spacing(0.5, 0, 0, 0),
  },
  title: {
    paddingBottom: 30,
    ...R.pathOr({}, ['mixins', 'share', 'title'], theme),
  },
  textAreaWrapper: {
    display: 'flex',
    flexGrow: 1,
  },
}));

const ShortUrls = ({
  labels,
  onClick,
  isLoading,
  contentValue,
  isNotifOpen = false,
  setIsNotifOpen,
}) => {
  const isNarrow = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const classes = useStyles();

  return (
    <Grid className={classes.container}>
      <Typography variant="h6" className={classes.title}>
        {R.prop('title')(labels)}
      </Typography>
      <Grid
        container
        justifyContent={isNarrow ? 'center' : 'flex-start'}
        alignItems={isNarrow ? 'left' : 'center'}
        direction={isNarrow ? 'column-reverse' : 'row'}
      >
        <Grid item style={{ padding: isNarrow ? '20px 0 0 0' : '0 20px 0 0' }}>
          <Button
            aria-label={R.propOr('', 'generateUrl')(labels)}
            type="submit"
            variant="contained"
            color="primary"
            disabled={isLoading || R.isNil(onClick)}
            alternative="siscc"
            onClick={onClick}
            className={classes.submitButton}
          >
            {isLoading ? <CircularProgress size={24} /> : R.propOr('', 'generateUrl')(labels)}
          </Button>
        </Grid>
        <Grid item className={classes.textAreaWrapper}>
          <TextareaAutosize
            aria-label={contentValue}
            className={classes.textarea}
            minRows={2}
            maxRows={2}
            readOnly
            value={contentValue}
          />
        </Grid>
      </Grid>
      <Collapse in={isNotifOpen}>
        <Alert
          severity={'error'}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setIsNotifOpen(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          {R.prop('errorMessage', labels)}
        </Alert>
      </Collapse>
    </Grid>
  );
};
ShortUrls.propTypes = {
  onClick: PropTypes.func,
  isLoading: PropTypes.bool,
  contentValue: PropTypes.string,
  isNotifOpen: PropTypes.bool,
  setIsNotifOpen: PropTypes.func,
  labels: PropTypes.shape({
    generateUrl: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    errorMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  }),
};
export default ShortUrls;
