import React from 'react';
import * as R from 'ramda';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import colorsItems from '../colors';

const useStyles = makeStyles(theme => ({
  label: {
    display: 'flex',
    flexGrow: 1,
    borderLeft: '1px solid black',
  },
  badge: {
    margin: theme.spacing(1),
    zIndex: 0,
  },
}));

const MenuColors = () => {
  const classes = useStyles();
  const theme = useTheme();

  const renderTree = (item, level = 0) => (
    <ListItem
      component='div'
      key={`${item.id}-${level}`}
      className={classes.label}
      style={{
        backgroundColor: item.color || item.alphaColor,
        color: item.color ? theme.palette.getContrastText(item.color) : 'inherit',
        borderColor: item.color ? theme.palette.getContrastText(item.color) : 'inherit'
      }}
    >
      <ListItemText>
        <Typography variant="body2">{item.id}</Typography>
        {R.is(Array, item.children) && (
          R.map((subItem) => renderTree(subItem, level + 1))(R.sortBy(R.prop('id'), item.children))
        )}
      </ListItemText>
    </ListItem>
  );
  return (
    <List dense>
      {R.map(renderTree)(R.sortBy(R.prop('id'), colorsItems))}
    </List>
  );
};



export default MenuColors;