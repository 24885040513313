import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import * as R from 'ramda';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Lens from '@material-ui/icons/Lens';
import { withBlank } from '../utils';
import { useStyles } from './styles';
import { getMaxValuesLength, getEmptyValues, getLabel, valueLabel, maxCount } from './lib';

const patern = {
  id: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  count: PropTypes.number,
};

const Header = ({ header, colSpan, valuesSpan }) => {
  const classes = useStyles();

  return R.map(({ id, label, count }) => (
    <TableRow key={id} className={cx(classes.header, classes.border)}>
      <TableCell
        tabIndex={0}
        className={cx(classes.cell, classes.headerTitleCell)}
        colSpan={colSpan}
        scope="row"
        title={label}
      >
        {label}
      </TableCell>
      {R.times(
        index => (
          <TableCell
            key={`${id}-${index}`}
            className={cx(classes.border, classes.cell, classes.headerCell)}
            scope="col"
          >
            {index < count ? valueLabel : ''}
          </TableCell>
        ),
        valuesSpan,
      )}
    </TableRow>
  ))(header);
};

Header.propTypes = {
  header: PropTypes.array,
  colSpan: PropTypes.number,
  valuesSpan: PropTypes.number,
};

const RowHeader = ({ id = 'rowHeader', rows, emptyValues }) => {
  const classes = useStyles();

  return (
    <TableRow>
      {R.map(({ id, label }) => (
        <TableCell
          tabIndex={0}
          key={id}
          className={cx(classes.rows, classes.border, classes.cell, classes.rowheaderCell)}
          title={label}
        >
          {label}
        </TableCell>
      ))(rows)}
      <TableCell className={cx(classes.separatorValues, classes.border, classes.separatorSize)} />
      {R.addIndex(R.map)((value, index) => (
        <TableCell
          key={`${id}-${index}`}
          className={cx(classes.separatorValues, classes.border, classes.cell)}
        />
      ))(emptyValues)}
    </TableRow>
  );
};

RowHeader.propTypes = {
  id: PropTypes.string,
  rows: PropTypes.array,
  emptyValues: PropTypes.array,
};

const Sections = ({ sections, colSpan }) => {
  const classes = useStyles();

  return (
    <TableRow className={cx(classes.border)}>
      <TableCell tabIndex={0} className={cx(classes.border, classes.sections)} colSpan={colSpan}>
        {R.addIndex(R.map)(({ id, label }, index) => (
          <Fragment key={id}>
            <span className={classes.sectionsTitleCell}>{`${label}: `}</span>
            <span className={classes.sectionsCell}>
              {valueLabel}
              {index === sections.length - 1 ? null : <Lens className={classes.icon} />}
            </span>
          </Fragment>
        ))(sections)}
      </TableCell>
    </TableRow>
  );
};

Sections.propTypes = {
  sections: PropTypes.array,
  colSpan: PropTypes.number,
};

const RowValues = ({ id = 'rowValues', rows, emptyValues }) => {
  const classes = useStyles();

  const totalMaxCount = R.min(maxCount, R.reduce(R.max, 0, R.pluck('count', rows)));

  return R.times(
    rowIndex => (
      <TableRow key={`${id}-${rowIndex}`}>
        {R.map(({ id, count }) => (
          <TableCell
            key={`${id}-${rowIndex}`}
            className={cx(classes.rows, classes.border, classes.cell)}
          >
            {rowIndex < count ? valueLabel : ''}
          </TableCell>
        ))(rows)}
        <TableCell className={cx(classes.separatorValues, classes.separatorSize, classes.border)} />
        {R.times(columnIndex => (
          <TableCell
            key={`${rowIndex}-header-${columnIndex}`}
            className={cx(classes.border, classes.cell)}
          />
        ))(emptyValues.length)}
      </TableRow>
    ),
    totalMaxCount,
  );
};

RowValues.propTypes = {
  id: PropTypes.string,
  rows: PropTypes.array,
  emptyValues: PropTypes.array,
};

const MyTable = ({ header = [], sections = [], rows = [], labelRenderer = R.prop('name') }) => {
  const classes = useStyles();
  const nbShowedValuesHeader = getMaxValuesLength(header);

  const emptyValues = getEmptyValues(nbShowedValuesHeader);
  const nbColumns = R.length(rows) + R.max(nbShowedValuesHeader, 1) + 1;
  const nbColumnRows = R.length(rows) + 1;
  const enhancedHeader = R.pipe(getLabel(labelRenderer))(header);
  const enhancedRows = R.pipe(getLabel(labelRenderer))(rows);
  const enhancedSections = R.pipe(getLabel(labelRenderer))(sections);

  return (
    <Table
      tabIndex={0}
      data-testid="table-preview-test-id"
      className={cx(classes.table, classes.border)}
    >
      <tbody>
        {R.isEmpty(enhancedHeader) ? null : (
          <Header
            colSpan={nbColumnRows}
            header={enhancedHeader}
            valuesSpan={nbShowedValuesHeader}
          />
        )}
        <RowHeader rows={enhancedRows} emptyValues={emptyValues} />
        {R.isEmpty(enhancedSections) ? null : (
          <Sections colSpan={nbColumns} sections={enhancedSections} />
        )}
        <RowValues rows={enhancedRows} emptyValues={emptyValues} />
      </tbody>
    </Table>
  );
};

MyTable.propTypes = {
  header: PropTypes.arrayOf(PropTypes.shape(patern)),
  sections: PropTypes.arrayOf(PropTypes.shape(patern)),
  rows: PropTypes.arrayOf(PropTypes.shape(patern)),
  labelRenderer: PropTypes.func,
};

export default withBlank(({ rows = [], isBlank }) => ({
  isBlank: R.or(isBlank, R.isEmpty(rows)),
}))(MyTable);
