import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, Tabs, Tab, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as R from 'ramda';
import Permissions from './Permissions';

const useStyles = makeStyles(theme => ({
  tabs: {
    border: '1px solid black',
    padding: theme.spacing(1, 2),
    '& .MuiTabs-indicator': {
      backgroundColor: '#182026 !important',
    },
  },
  tab: {
    backgroundColor: theme.palette.blue,
    fontSize: '12px',
    '& .Mui-selected': {
      fontWeight: 'bold',
    },
  },
}));

const PermissionsTabs = ({ data, labels, onCheckPermission, onCheckPermissionGroup }) => {
  const classes = useStyles();
  const [valueTabs, setValueTabs] = useState(0);
  const handleValueTabsChange = (_, newValue) => setValueTabs(newValue);

  const permissionsData = R.path(['options', 'data'], data);
  const permissionGroupsData = R.path(['permissions', 'data'], data);

  const selectedGroups = R.filter(R.prop('isSelected'), permissionGroupsData);
  const selectedPermissionsInGroups = R.pipe(R.pluck('options'), R.unnest, R.uniq)(selectedGroups);
  const selectedPermissions = R.pipe(
    R.filter(R.prop('isSelected')),
    R.pluck('id'),
  )(permissionsData);
  const nonGroupedSelectedPermissions = R.difference(
    selectedPermissions,
    selectedPermissionsInGroups,
  );

  const evolvedPermissionGroupsData = R.append(
    {
      id: 'customPermissions',
      label: R.prop('customPermissions', labels),
      definition: R.prop('customPermissionsDefinition', labels),
      isSelected: !R.isEmpty(nonGroupedSelectedPermissions),
      disabled: true,
    },
    permissionGroupsData,
  );

  return (
    <Grid>
      <Typography
        variant="h6"
        className={classes.label}
        style={{ marginTop: 10, marginBottom: 10 }}
      >
        {R.prop('title')(labels)}
      </Typography>
      <Grid className={classes.tabs}>
        <Tabs
          value={valueTabs}
          onChange={handleValueTabsChange}
          aria-label={R.prop('title')(labels)}
          className={classes.tab}
        >
          <Tab label={R.path(['permissions', 'label'], data)} />
          <Tab label={R.path(['options', 'label'], data)} />
        </Tabs>
        <Box>
          <Permissions
            data={valueTabs === 0 ? evolvedPermissionGroupsData : permissionsData}
            labels={labels}
            onCheck={valueTabs === 0 ? onCheckPermissionGroup : onCheckPermission}
          />
        </Box>
      </Grid>
    </Grid>
  );
};
PermissionsTabs.propTypes = {
  data: PropTypes.object,
  labels: PropTypes.object,
  onCheckPermission: PropTypes.func,
  onCheckPermissionGroup: PropTypes.func,
};
export default PermissionsTabs;
