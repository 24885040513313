import React from 'react';
import PropTypes from 'prop-types';
import { isNil } from 'ramda';

const Title = ({ header }) => {
  if (isNil(header)) {
    return '';
  }
  return (
    <b>
      <i>{header}</i>&nbsp;
    </b>
  );
};

Title.propTypes = {
  header: PropTypes.string,
};

export default Title;
