import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as R from 'ramda';
import cx from 'classnames';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  hideContent: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  contentLabel: {
    color: theme.palette.text.primary,
    fontWeight: 'bold',
    fontSize: '14px',
  },
  label: {
    color: theme.palette.text.primary,
    fontSize: '14px',
  },
  disabled: {
    cursor: 'not-allowed',
    color: theme.palette.text.disabled,
    '&:hover': {
      color: theme.palette.text.disabled,
    },
  },
}));

const Permissions = ({ data, labels, onCheck }) => {
  const classes = useStyles();

  return (
    <Grid container>
      <Grid item container xs={12}>
        <Grid item xs={12} md={4}>
          <Typography variant="h6" className={classes.contentLabel} style={{ paddingTop: 8 }}>
            {R.prop('permission')(labels)}
          </Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <Typography
            variant="h6"
            className={cx(classes.contentLabel, classes.hideContent)}
            style={{ paddingTop: 8 }}
          >
            {R.prop('definition')(labels)}
          </Typography>
        </Grid>
      </Grid>
      <Grid item container xs={12}>
        {R.map(item => (
          <Grid item container xs={12} key={item.id}>
            <Grid item xs={12} md={4}>
              <FormControlLabel
                control={<Checkbox name="checkedB" color="primary" />}
                label={
                  <Typography
                    variant="h6"
                    className={R.propOr(false, 'disabled')(item) ? classes.disabled : classes.label}
                  >
                    {R.prop('label')(item)}
                  </Typography>
                }
                checked={!!item.isSelected}
                disabled={R.propOr(false, 'disabled')(item)}
                onChange={() => onCheck(item.id)}
              />
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography
                variant="h6"
                className={cx(
                  R.propOr(false, 'disabled')(item) ? classes.disabled : classes.label,
                  classes.hideContent,
                )}
                style={{ paddingTop: 8 }}
              >
                {R.prop('definition')(item)}
              </Typography>
            </Grid>
          </Grid>
        ))(data)}
      </Grid>
    </Grid>
  );
};
Permissions.propTypes = {
  data: PropTypes.array,
  labels: PropTypes.object,
  setSelectedOptions: PropTypes.func,
  onCheck: PropTypes.func,
};
export default Permissions;
