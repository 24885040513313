import { useState, useEffect } from 'react';
import * as R from 'ramda';

export const useSpotlight = (hasCommit, defaultTerm = '', defaultSpotlight, action = () => {}) => {
  const [term, setTerm] = useState(defaultTerm);
  const [spotlight = {}, setSpotlight] = useState(defaultSpotlight);
  const [isFocus, setFocus] = useState(false);

  useEffect(
    () => {
      setTerm(defaultTerm);
      setSpotlight(defaultSpotlight);
    },
    [defaultTerm, defaultSpotlight],
  );

  const onKeyPress = event => {
    if (event.key === 'Enter') return action({ term, spotlight });
  };

  const onClearTerm = event => {
    event.preventDefault();
    setTerm('');
    return action({ term: '' });
  };

  const onChangeTerm = event => {
    event.preventDefault();
    const newTerm = R.propOr('', 'value')(event.target);
    setTerm(newTerm);
    return hasCommit ? null : action({ term: newTerm, spotlight });
  };

  const onCommitTerm = event => {
    event.preventDefault();
    return action({ term, spotlight });
  };

  const onChangeField = field => event => {
    event.preventDefault();
    const newSpotlight = {
      ...spotlight,
      fields: {
        ...spotlight.fields,
        [field.id]: { ...field, isSelected: !field.isSelected },
      },
    };
    setSpotlight(newSpotlight);
    return action({ term, spotlight: newSpotlight });
  };

  return {
    value: term,
    spotlight,
    isFocus,
    setFocus,
    onKeyPress,
    onClearTerm,
    onChangeTerm,
    onChangeField,
    onCommitTerm,
  };
};
