import React from 'react';
import * as R from 'ramda';
import cx from 'classnames';
import PropTypes from 'prop-types';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import HelpIcon from '@material-ui/icons/EmojiObjects';
import { useStyles } from './styles';
import { withBlank } from '../utils';
import Tooltip from '../Tooltip';

const CustomExpansionPanel = ({
  id,
  topElementComponent,
  onChangeActivePanel,
  label,
  tag,
  overflow = true,
  children,
  isOpen,
  maxHeight = true,
  fullWidth,
  testId = 'expansion_panel',
  isPinned,
  pinnedLabel,
  moreFilters,
  isNarrow = false,
  isPopper = false,
  popperLabels = {},
}) => {
  const classes = useStyles();

  return (
    <Accordion
      data-testid={testId}
      className={cx({ [classes.fullWidth]: fullWidth })}
      expanded={isOpen}
      onChange={R.is(Function)(onChangeActivePanel) ? () => onChangeActivePanel(id) : undefined}
      classes={{ root: isNarrow ? classes.panelBorder : classes.panel }}
      elevation={0}
      aria-expanded={isOpen}
    >
      <AccordionSummary
        data-testid={`${testId}_panel`}
        className={cx(classes.container, { [classes.moreFiltersContainer]: moreFilters })}
        classes={{
          content: cx(classes.content, classes.ellipsis, {
            [classes.moreFiltersContent]: moreFilters,
          }),
          expandIcon: cx(classes.iconSummaryPanel, {
            [classes.moreFiltersExpandIcon]: moreFilters,
          }),
        }}
        expandIcon={<ExpandMoreIcon />}
        aria-controls={id}
        id={id}
        aria-label={isPinned ? `${label} ${pinnedLabel} ` : label}
      >
        <Grid container item className={classes.ellipsis}>
          {isPinned && <Typography variant="srOnly">{pinnedLabel}</Typography>}
          <Typography
            noWrap
            variant="body2"
            title={label}
            className={moreFilters ? classes.title : classes.label}
          >
            {label}
          </Typography>
          {isPinned && <div className={classes.pinnedIcon}>•</div>}
          {isPopper && !R.isEmpty(R.propOr('', 'title', popperLabels)) && (
            <Tooltip
              variant="light"
              tabIndex={0}
              aria-label={R.propOr('', 'ariaLabel', popperLabels)}
              aria-hidden={false}
              placement="bottom-start"
              title={R.propOr('', 'title', popperLabels)}
            >
              <HelpIcon fontSize="small" />
            </Tooltip>
          )}
        </Grid>
        {R.isNil(tag) ? null : tag}
      </AccordionSummary>
      {R.isNil(topElementComponent) ? null : topElementComponent}
      <AccordionDetails
        id={id}
        aria-label={label}
        className={cx(classes.details, {
          [classes.overflow]: overflow,
          [classes.height]: maxHeight,
        })}
      >
        {children}
      </AccordionDetails>
    </Accordion>
  );
};

CustomExpansionPanel.propTypes = {
  testId: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  topElementComponent: PropTypes.element,
  onChangeActivePanel: PropTypes.func,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  pinnedLabel: PropTypes.string,
  tag: PropTypes.element,
  overflow: PropTypes.bool,
  isOpen: PropTypes.bool,
  isBlank: PropTypes.bool,
  maxHeight: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  fullWidth: PropTypes.bool,
  isPinned: PropTypes.bool,
  moreFilters: PropTypes.bool,
  isNarrow: PropTypes.bool,
  isPopper: PropTypes.bool,
  popperLabels: PropTypes.shape({
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    ariaLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  }),
};

export default withBlank(({ isBlank }) => ({ isBlank }))(CustomExpansionPanel);
