import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import cx from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { Draggable } from '@hello-pangea/dnd';
import { getLight, getDark } from '../utils';
import { BOX, wcagId } from './constants';

export const useStyles = makeStyles(theme => ({
  containerButton: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(1),
    borderRadius: theme.spacing(0.5),
    userSelect: 'none',
  },
  styledItemContainer: {
    cursor: 'grab',
    minHeight: 32,
    borderRadius: theme.spacing(0.5),
    alignItems: 'center',
    paddingTop: theme.spacing(0.25),
    paddingBottom: theme.spacing(0.25),
    margin: theme.spacing(0.25),
    opacity: 1,
    flexFlow: 'wrap',
  },
  [`${BOX.sections}Item`]: {
    color: theme.palette.getContrastText(getDark(theme) || theme.palette.secondary.dark),
    backgroundColor: getDark(theme) || theme.palette.secondary.dark,
  },
  [`${BOX.rows}Item`]: {
    color: theme.palette.getContrastText(getLight(theme) || theme.palette.secondary.light),
    backgroundColor: getLight(theme) || theme.palette.secondary.light,
  },
  [`${BOX.header}Item`]: {
    color: theme.palette.getContrastText(theme.palette.primary.main),
    backgroundColor: theme.palette.primary.main,
  },
  cantMove: {
    cursor: 'no-drop',
  },
  text: {
    fontStyle: 'italic',
    fontSize: theme.typography.fontSize - 2,
    fontWeight: theme.typography.fontWeightBold,
  },
  itemButton: {
    paddingTop: theme.spacing(0.25),
    paddingBottom: theme.spacing(0.25),
    paddingRight: 0,
  },
  select: {
    padding: theme.spacing(0.5, 4, 0.5, 2),
    backgroundColor: theme.palette.common.white,
    '&:focus': {
      backgroundColor: theme.palette.common.white,
    },
  },
  spaceButton: {
    margin: theme.spacing(0, 1, 0, 0),
  },
}));

const DnDItem = ({ item, index, itemRenderer, dropZoneLabel, itemButtonProps, canDrag }) => {
  const classes = useStyles();
  const selectItem = R.prop(item.id)(itemButtonProps);
  return (
    <Draggable key={item.id} draggableId={item.id} index={index} isDragDisabled={R.not(canDrag)}>
      {provided => (
        <div
          data-testid={`draggable-${item.id}`}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className={cx(
            classes.styledItemContainer,
            classes.containerButton,
            [R.prop(`${dropZoneLabel}Item`)(classes)],
            {
              [classes.cantMove]: R.not(canDrag),
            },
          )}
          aria-label={itemRenderer(item)}
          aria-describedby={wcagId}
        >
          <Fragment>
            <Typography
              variant={'body2'}
              className={cx(classes.text, { [classes.spaceButton]: selectItem })}
            >
              {itemRenderer(item)}
            </Typography>
            {selectItem && (
              <Select
                id={item.id}
                value={selectItem.value}
                onChange={e => selectItem.onChange(e.target.value)}
                classes={{ root: classes.select }}
                variant="outlined"
              >
                {R.map(v => (
                  <MenuItem key={v} value={v}>
                    {v}
                  </MenuItem>
                ))(selectItem.options)}
              </Select>
            )}
          </Fragment>
        </div>
      )}
    </Draggable>
  );
};

DnDItem.propTypes = {
  item: PropTypes.object,
  index: PropTypes.number,
  itemRenderer: PropTypes.func,
  dropZoneLabel: PropTypes.string,
  onChangeLayout: PropTypes.func,
  itemButtonProps: PropTypes.object,
  handleChangeBox: PropTypes.func,
  canDrag: PropTypes.bool,
  dropZoneIndex: PropTypes.number,
};

export default DnDItem;
