import React from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import SectionHeader from './sectionHeader';
import Cell from './cell';
import { getLight, getIsRtl } from '../utils';
import { getValue } from './utils';
import Flags from './flags';
import { memo } from 'react';

const useStyles = makeStyles(theme => ({
  rowTitle: {
    backgroundColor: getLight(theme) || theme.palette.secondary.light,
    border: 'inherit',
    borderBottom: `thin solid ${theme.palette.grey[500]}`,
    borderRight: `thin solid ${theme.palette.grey[500]}`,
    padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
    color: theme.palette.grey['A700'],
    verticalAlign: 'top',
    align: 'left',
  },
  timePeriodCells: {
    whiteSpace: 'nowrap',
  },
  fillerCell: {
    minWidth: '30px',
    backgroundColor: theme.palette.grey[200],
    verticalAlign: 'top',
    border: 'inherit',
    borderBottom: `thin solid ${theme.palette.grey[500]}`,
    borderRight: `thin solid ${theme.palette.grey[500]}`,
    padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
    position: 'sticky',
    zIndex: 1,
  },
  cellContent: {
    display: 'flex',
    alignItems: 'center',
  },
  flagContainer: {
    display: 'flex',
    justifyContent: 'center',
    fontWeight: 'bold',
  },
  topCell: {
    paddingTop: 0,
  },
  hierarchySpace: {
    marginBottom: 'auto',
  },
  highlight: theme.mixins.table.cellHighlight,
  stickyHeader: {
    position: 'sticky',
    zIndex: 1,
  },
}));
const mapIndexed = R.addIndex(R.map);

const Section = ({
  headerData,
  cells,
  section,
  xLayoutDataLength,
  cellHandler,
  activeCellHandler,
  activeCellIds,
  headerHeight,
  sectionIndex,
  nbColumnsByRows,
  SideIcon,
  subHeadCellsWidth,
  HTMLRenderer,
  labelAccessor,
  cellValueAccessor,
  isNoWrap,
  textAlign,
}) => {
  const classes = useStyles();
  const currentSection = R.path([0, 'key'], section);
  const headerNumberRows = R.length(nbColumnsByRows);
  const maxColumns = R.last(nbColumnsByRows);
  const theme = useTheme();
  const isRtl = getIsRtl(theme);

  return (
    <TableBody>
      <SectionHeader
        headerData={headerData}
        section={section}
        currentSection={currentSection}
        xLayoutDataLength={xLayoutDataLength}
        headerHeight={headerHeight}
        SideIcon={SideIcon}
        HTMLRenderer={HTMLRenderer}
        labelAccessor={labelAccessor}
      />
      {mapIndexed((sectionbody, rowIndex) => {
        const rowId = `${sectionbody.key}_${sectionIndex}_${rowIndex}`;
        const rowIds = R.pipe(
          R.map(index => `${sectionbody.key}_${sectionIndex}_${index}`),
          R.join(' '),
        )(R.times(R.identity, R.length(R.prop('data', sectionbody))));
        return (
          <TableRow key={rowId}>
            {R.times(index => {
              const cell = R.path(['data', index], sectionbody);
              const value = R.isNil(cell) ? { label: '' } : getValue(labelAccessor)(cell);
              const flags = R.propOr([], 'flags', value);
              const id = `${rowIndex}_${rowId}_${index}`;

              // special space is used for hierarchy U+2007 &#8199; &numsp;
              const hierarchySpace = R.pipe(
                R.propOr([], 'parents'),
                R.when(R.isNil, R.always([])),
                R.length,
                // eslint-disable-next-line no-irregular-whitespace
                R.times(() => '·  '),
              )(value);

              return (
                <TableCell
                  key={id}
                  className={cx(classes.rowTitle, classes.stickyHeader, {
                    [classes.highlight]: R.prop(rowId)(activeCellIds),
                    [classes.timePeriodCells]:
                      R.is(Function, isNoWrap) && isNoWrap(R.propOr({}, 'dimension', cell)),
                  })}
                  id={id}
                  headers={`subHeader_${index} ${currentSection}`}
                  component="th"
                  style={
                    isRtl ? { right: subHeadCellsWidth[index] } : { left: subHeadCellsWidth[index] }
                  }
                >
                  <div className={classes.cellContent}>
                    {!R.isEmpty(hierarchySpace) && (
                      <div className={classes.hierarchySpace}>{hierarchySpace}</div>
                    )}
                    <Typography
                      variant="body1"
                      tabIndex={0}
                      aria-describedby={`subHeader_${index} ${currentSection}`}
                    >
                      {R.prop('label', value)}
                      <Flags
                        HTMLRenderer={HTMLRenderer}
                        labelAccessor={labelAccessor}
                        flags={flags}
                      />
                    </Typography>
                  </div>
                </TableCell>
              );
            }, xLayoutDataLength /*R.prop('data', sectionbody)*/)}
            <TableCell
              className={cx(classes.fillerCell, {
                [classes.highlight]: R.prop(rowId)(activeCellIds),
              })}
              style={
                isRtl ? { right: R.last(subHeadCellsWidth) } : { left: R.last(subHeadCellsWidth) }
              }
            >
              <div className={classes.flagContainer}>
                {!R.isNil(SideIcon) && <SideIcon sideProps={R.prop('sideProps', sectionbody)} />}
                <Flags
                  HTMLRenderer={HTMLRenderer}
                  labelAccessor={labelAccessor}
                  flags={R.prop('flags', sectionbody)}
                />
              </div>
            </TableCell>
            {mapIndexed((item, columnIndex) => {
              const columnIds = R.pipe(
                R.times(R.identity),
                R.map(rowIndex => {
                  const columnId = R.divide(
                    columnIndex,
                    R.divide(maxColumns, R.nth(rowIndex, nbColumnsByRows)),
                  );
                  return `${Math.floor(columnId || 0)}_${rowIndex}`;
                }),
                R.join(' '),
              )(headerNumberRows);

              const intersectionCellId = `${rowId}/${columnIndex}`;
              const isActive = R.pipe(
                R.propOr({}, 'intersectionCellId'),
                R.equals(intersectionCellId),
              )(activeCellIds);
              const missingValLabel = item.isEmpty || sectionbody.isEmpty ? '' : '..';
              return (
                <Cell
                  {...R.pipe(
                    R.pathOr({ value: null }, [item.key, currentSection, sectionbody.key, 0]),
                    R.over(R.lensProp('value'), val =>
                      R.isNil(val) ? missingValLabel : cellValueAccessor(val),
                    ),
                  )(cells)}
                  key={intersectionCellId}
                  headers={`${rowIds} ${columnIds} ${currentSection}`}
                  cellHandler={cellHandler}
                  HTMLRenderer={HTMLRenderer}
                  labelAccessor={labelAccessor}
                  SideIcon={SideIcon}
                  isActive={isActive}
                  textAlign={textAlign}
                  isHighlight={R.any(R.identity)([
                    R.prop(rowId)(activeCellIds),
                    R.prop(columnIndex)(activeCellIds),
                  ])}
                  activeCellHandler={
                    R.is(Function)(activeCellHandler)
                      ? () =>
                          activeCellHandler({
                            [rowId]: R.not(isActive),
                            [columnIndex]: R.not(isActive),
                            intersectionCellId: isActive ? null : intersectionCellId,
                          })
                      : null
                  }
                />
              );
            }, headerData)}
          </TableRow>
        );
      }, R.nth(1, section))}
    </TableBody>
  );
};

Section.propTypes = {
  cells: PropTypes.object,
  headerData: PropTypes.array,
  section: PropTypes.array,
  xLayoutDataLength: PropTypes.number,
  cellHandler: PropTypes.func,
  activeCellHandler: PropTypes.func,
  activeCellIds: PropTypes.object,
  headerHeight: PropTypes.number,
  sectionIndex: PropTypes.number,
  headerNumberRows: PropTypes.number,
  nbColumnsByRows: PropTypes.array,
  maxColumns: PropTypes.number,
  SideIcon: PropTypes.func,
  subHeadCellsWidth: PropTypes.arrayOf(PropTypes.number),
  HTMLRenderer: PropTypes.func,
  labelAccessor: PropTypes.func,
  cellValueAccessor: PropTypes.func,
  isNoWrap: PropTypes.func,
  textAlign: PropTypes.string,
};

export default memo(Section);
