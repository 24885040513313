import * as R from 'ramda';

export const getHierarchicalId = item => R.prop('hierarchicalId', item) || R.prop('id', item);

export const getDescendants = (id, groupedItems) => {
  const reccurse = _id => {
    const children = R.propOr([], _id, groupedItems);
    return R.pipe(
      R.map(item => {
        const __id = getHierarchicalId(item);
        const descendants = reccurse(__id);
        return R.prepend(item, descendants);
      }),
      R.unnest,
    )(children);
  };

  return reccurse(id);
};

export const isItemSelected = selectIds => item => {
  if (!R.isEmpty(selectIds) && R.has(getHierarchicalId(item), selectIds)) {
    return !item.isSelected;
  }
  return !!item.isSelected;
};

export const getDepth = indexedItems => item => {
  if (R.isNil(item.parentId)) {
    return 0;
  }
  const parent = R.prop(item.parentId, indexedItems);
  return getDepth(indexedItems)(parent) + 1;
};

export const getDuplicates = (items, indexedItems) => {
  const itemsIds = R.pluck('id', items);
  const grouped = R.pipe(R.values, R.groupBy(R.prop('id')))(indexedItems);
  return R.pipe(R.props(itemsIds), R.unnest)(grouped);
};

export const getDeprecatedIds = (ids, items, selectedIds) => {
  const { groupedItemsByParentId, indexedItemsById } = items;
  let _ids = ids;
  const reccurse = scopeIds => {
    const scopeItems = R.props(scopeIds, indexedItemsById);
    const grouped = R.groupBy(R.propOr('#ROOT', 'parentId'), scopeItems);
    const refined = R.omit(R.append('#ROOT', _ids), grouped);

    const deprecatedParents = R.reduce(
      (acc, id) => {
        const _item = R.prop(id, indexedItemsById);
        const _itemsIds = R.has('hierarchicalId', _item)
          ? R.pipe(
              R.values,
              R.filter(R.propEq('id', _item.id)),
              R.map(getHierarchicalId),
            )(indexedItemsById)
          : [id];

        const children = R.pipe(R.props(_itemsIds), R.unnest)(groupedItemsByParentId);
        const selectedChild = R.find(child => {
          return !R.includes(getHierarchicalId(child), _ids) && isItemSelected(selectedIds)(child);
        }, children);
        if (!isItemSelected(selectedIds)(_item) && R.isNil(selectedChild)) {
          return R.concat(reccurse([id]), acc);
        }
        if (!R.propOr(false, 'isGreyed', _item)) {
          return acc;
        }
        if (R.isNil(selectedChild)) {
          return R.concat(acc, _itemsIds);
        }
        return acc;
      },
      [],
      R.keys(refined),
    );

    if (!R.isEmpty(deprecatedParents)) {
      _ids = R.concat(_ids, deprecatedParents);
      const deprecatedAncestors = reccurse(deprecatedParents);
      return R.concat(deprecatedParents, deprecatedAncestors);
    }

    return deprecatedParents;
  };

  return reccurse(ids);
};

// evolved disableAccessor with hierarchical context and selection mode
export const getEvolvedDisableAccessor =
  (
    groupedItems,
    disableAccessor = R.always(false),
    getIsChildInSelectionScope = R.always(true), //combine selection mode + spotlight
  ) =>
  (item, selectedIds) => {
    const hierarchicalFinder = validator => parentId =>
      R.find(child => {
        if (validator(child)) return true;
        return !R.isNil(hierarchicalFinder(validator)(getHierarchicalId(child)));
      }, R.propOr([], parentId, groupedItems));
    // selected forcedItem isn't deselectable unless all selected descendants are in deselection scope
    if (isItemSelected(selectedIds)(item) && R.propOr(false, 'isForced', item)) {
      const outOfScopeSelectedDescendant = hierarchicalFinder(
        child => isItemSelected(selectedIds)(child) && !getIsChildInSelectionScope(child, item),
      )(getHierarchicalId(item));
      return !R.isNil(outOfScopeSelectedDescendant);
    }
    // non selected invalid item is disabled unless some (valid) children are selected or selectable in scope
    if (!isItemSelected(selectedIds)(item) && disableAccessor(item)) {
      const validDescendant = hierarchicalFinder(
        child =>
          isItemSelected(selectedIds)(child) ||
          (getIsChildInSelectionScope(child, item) && !disableAccessor(child)),
      )(getHierarchicalId(item));
      return R.isNil(validDescendant);
    }
    return false;
  };

export const getScopeGetters = {
  single: () => item => [item],
  children: items => item => {
    const childItems = R.propOr([], getHierarchicalId(item), items.groupedItemsByParentId);
    return R.reject(R.prop('isDisabled'), R.prepend(item, childItems));
  },
  branch: items => item => {
    const descendants = getDescendants(getHierarchicalId(item), items.groupedItemsByParentId);
    const res = R.reject(R.prop('isDisabled'), R.prepend(item, descendants));
    return res;
  },
  level: items => item => {
    const indexed = items.indexedItemsById;
    const depth = getDepth(indexed)(item);
    return R.filter(
      it => !R.prop('isDisabled', it) && getDepth(indexed)(it) === depth,
      R.values(indexed),
    );
  },
};

export const getImposedItems = (selectIds, indexedItems) => selectedItems =>
  R.reduce(
    (acc, item) => {
      const isSelected = isItemSelected(selectIds)(item);
      if (!isSelected) {
        const imposedIds = R.pipe(
          R.filter(i => i.id === item.id),
          R.map(R.propOr([], 'imposedIds')),
          R.unnest,
        )(R.values(indexedItems));
        const imposedItems = R.props(imposedIds, indexedItems);
        return R.concat(acc, R.append(item, imposedItems));
      }
      return R.append(item, acc);
    },
    [],
    selectedItems,
  );
