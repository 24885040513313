import { useMemo } from 'react';
import * as R from 'ramda';

const useDimensionOptions = properties => {
  const dimensions = useMemo(
    () => {
      return R.pipe(
        R.pick(['scatterDimension', 'scatterX', 'scatterY', 'symbolDimension', 'stackedDimension']),
        R.toPairs,
      )(properties);
    },
    [properties],
  );

  return [dimensions];
};

export default useDimensionOptions;
