import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Done from '@material-ui/icons/Done';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';

const useStyles = makeStyles(() => ({
  visibility: {
    visibility: 'hidden',
  },
  input: {
    '&[type=number]::-webkit-inner-spin-button, &[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '&[type=number]': {
      '-moz-appearance': 'textfield',
    },
  },
}));

const LefttIcon = ({ Icon }) => <Icon />;

LefttIcon.propTypes = {
  Icon: PropTypes.object,
};

const RightIcon = ({ onSubmit, Icon = Done }) => (
  <IconButton color="primary" onClick={() => onSubmit()}>
    <Icon />
  </IconButton>
);

RightIcon.propTypes = {
  Icon: PropTypes.object,
  onSubmit: PropTypes.func,
  classes: PropTypes.object,
};

export const MyInput = ({ id, value, onSubmit, onReset, resetLabel, placeholder, ...props }) => {
  const classes = useStyles();
  const [inputValue, setInputValue] = useState(value || '');
  useEffect(
    () => {
      setInputValue(value);
    },
    [value],
  );

  const resetId = `reset-${id}`;

  const handleBlur = event => {
    if (R.is(Function, onSubmit) && inputValue !== value) {
      onSubmit(event.target.value);
    }
  };

  const handleReset = () => {
    if (R.is(Function, onReset)) {
      onReset();
    } else {
      setInputValue(value);
    }
  };

  const onEnterLabel = event => {
    if (event.key === 'Enter') {
      if (R.is(Function, onSubmit)) {
        onSubmit(event.target.value);
      }
    }
  };

  const handleChange = event => {
    setInputValue(event.target.value);
  };

  const endAdornment =
    R.is(Function, onReset) || inputValue !== value ? (
      <InputAdornment position="end">
        <IconButton
          id={resetId}
          aria-label={resetLabel}
          onKeyPress={onEnterLabel}
          onClick={handleReset}
        >
          <RotateLeftIcon fontSize="small" />
        </IconButton>
      </InputAdornment>
    ) : null;

  return (
    <TextField
      inputProps={{
        className: classes.input,
      }}
      onBlur={handleBlur}
      margin="dense"
      size="small"
      variant="outlined"
      onKeyPress={onEnterLabel}
      onChange={handleChange}
      id={id}
      value={inputValue}
      InputProps={{
        endAdornment,
      }}
      placeholder={R.is(Number)(placeholder) ? R.toString(placeholder) : placeholder}
      {...R.omit(['isActive'])(props)}
    />
  );
};

MyInput.propTypes = {
  id: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onSubmit: PropTypes.func,
  onReset: PropTypes.func,
  resetLabel: PropTypes.string,
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default MyInput;
