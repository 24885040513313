import React from 'react';
import * as R from 'ramda';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  circularProgress: {
    width: '48px',
    height: ' 48px',
    borderStyle: 'solid',
    borderWidth: '5px',
    borderRadius: '50%',
    display: 'inline-block',
    boxSizing: 'border-box',
    animation: `$rotate 1s linear infinite`,
    margin: theme.spacing(2),
    borderBottomColor: 'transparent !important',
  },
  '@keyframes rotate': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(360deg)',
    },
  },
  warning: {
    borderColor: theme.palette.warning.main,
  },
  primaryColor: {
    borderColor: theme.palette.primary.main,
  },
  secondaryColor: {
    borderColor: theme.palette.secondary.main,
  },
}));

const Loading = ({ message, isWarning = false, variant = 'primary', color }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div
        style={{ color: R.isNil(color) ? undefined : color }}
        className={cx(classes.circularProgress, {
          [classes[`${variant}Color`]]: !R.isNil(variant) && R.isNil(color) && !isWarning,
          [classes.warning]: isWarning,
        })}
      />
      <Typography variant="subtitle1" noWrap align="center">
        {message}
      </Typography>
    </div>
  );
};

Loading.propTypes = {
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  variant: PropTypes.string,
  color: PropTypes.string,
  isWarning: PropTypes.bool,
};

export default Loading;
