import React, { Fragment } from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Popover from '@material-ui/core/Popover';
import Button from '@material-ui/core/Button';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Check from '@material-ui/icons/Check';

export const Field = ({ spotlight = {}, onChangeField, isRtl, classes }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'visions-spotlight-popover' : undefined;

  return (
    <Fragment>
      <Button aria-describedby={id} onClick={handleClick}>
        <div className={classes.buttonContainer}>
          {R.prop('searchLabel', spotlight)}
          {<ArrowDropDownIcon fontSize="small" />}
        </div>
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div dir={isRtl ? 'rtl' : 'ltr'} className={classes.popoverButton}>
          {R.pipe(
            R.values,
            R.map(field => (
              <Button
                key={field.id}
                className={classes.buttonContainer}
                onClick={onChangeField(field)}
                disabled={field.disabled}
              >
                <Check
                  fontSize="small"
                  className={cx({ [classes.selected]: !R.prop('isSelected')(field) })}
                />
                <spotlight.FieldLabelRenderer id={field.id} />
              </Button>
            )),
          )(R.prop('fields', spotlight))}
        </div>
      </Popover>
    </Fragment>
  );
};

Field.propTypes = {
  classes: PropTypes.object,
  spotlight: PropTypes.shape({
    fields: PropTypes.shape({
      id: PropTypes.string,
      isSelected: PropTypes.bool,
      disabled: PropTypes.bool,
    }),
    searchLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    FieldLabelRenderer: PropTypes.func,
  }),
  onChangeField: PropTypes.func.isRequired,
  isRtl: PropTypes.bool,
};
