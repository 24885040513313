import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import {
  Grid,
  Paper,
  TextareaAutosize,
  Checkbox,
  FormControlLabel,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { validate as getIsValidEmail } from 'isemail';
import Mode from './Mode';
import Input from './Input';
import { Button } from '../';

const useStyles = makeStyles(theme => ({
  paper: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2),
  },
  emailReasonMessage: {
    backgroundColor: R.path(['palette', 'tertiary', 'dark'])(theme) || theme.palette.grey[100],
    padding: theme.spacing(2),
    margin: theme.spacing(2, 0),
  },
  label: {
    color: theme.palette.primary.main,
  },
  textarea: {
    width: '100%',
    padding: theme.spacing(1),
  },
  textareaError: {
    borderColor: theme.palette.error.main,
    borderWidth: theme.spacing(0.25),
  },
}));

const Contact = ({
  modes,
  defaultMode = 'question',
  defaultFullName,
  defaultEmail,
  labels,
  send,
  isSubmitDisable,
  children,
}) => {
  const classes = useStyles();

  const [mode, setMode] = useState(defaultMode);
  const [title, setTitle] = useState('');
  const [name, setName] = useState(defaultFullName || '');
  const [organisation, setOrganisation] = useState('');
  const [email, setEmail] = useState(defaultEmail || '');
  const [subject, setSubject] = useState('');
  const [emailHasError, setEmailError] = useState(false);
  const [details, setDetails] = useState('');
  const [checked, setChecked] = useState(false);
  const [isSendDisable, setSendDisable] = useState(true);
  const [detailsHasError, setDetailsError] = useState(false);
  const checkEmail = email => {
    if (getIsValidEmail(email) && R.test(/^\S+@\S+\.\S+$/, email)) {
      return setEmailError(false);
    }
    return setEmailError(true);
  };

  const checkDetails = details => {
    if (R.isEmpty(details)) {
      return setDetailsError(true);
    }
    return setDetailsError(false);
  };

  const onSubmit = () => {
    if (R.is(Function)(send))
      return send({
        mode,
        title,
        name,
        organisation,
        email,
        details,
        checkbox: checked,
        subject,
      });
  };

  useEffect(() => {
    if (R.isEmpty(email)) return setSendDisable(true);
    if (R.isEmpty(details)) return setSendDisable(true);
    if (emailHasError) return setSendDisable(true);
    if (detailsHasError) return setSendDisable(true);
    return setSendDisable(isSubmitDisable || false);
  }, [emailHasError, detailsHasError, email, details, isSubmitDisable]);

  return (
    <Grid container className={classes.paper} data-testid="contact-test-id">
      <Grid item container xs={12} md={12} justifyContent="space-between">
        <Grid item xs={12} sm={12} md={5}>
          <Mode
            changeMode={setMode}
            modes={modes}
            mode={mode}
            title={R.prop('modeTitle')(labels)}
          />
        </Grid>
        <Grid item container xs={12} sm={12} md={6} flex-direction="column">
          <Grid item xs={12}>
            <Input
              variant="outlined"
              label={labels.personalTitle}
              name="title"
              type="text"
              fullWidth
              value={title}
              onChange={e => setTitle(e.target.value)}
              isControlled
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              variant="outlined"
              isControlled
              label={labels.name}
              name="name"
              type="text"
              onChange={e => setName(e.target.value)}
              value={name}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              variant="outlined"
              isControlled
              label={labels.organisation}
              type="text"
              name="organisation"
              onChange={e => setOrganisation(e.target.value)}
              value={organisation}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              inputProps={{
                'aria-label': R.prop('email')(labels),
                autoComplete: 'email',
                onBlur: () => checkEmail(email),
              }}
              label={R.prop('email')(labels)}
              type="email"
              value={email}
              onChange={e => setEmail(e.target.value)}
              variant="outlined"
              fullWidth
              isControlled
              defaultValue={defaultEmail}
              textFieldProps={{ error: emailHasError }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={12}>
        <Paper className={classes.emailReasonMessage}>
          {R.prop('message')(R.find(R.propEq('value', mode))(modes))}
        </Paper>
      </Grid>
      <Grid item xs={12} md={12}>
        <Input
          variant="outlined"
          isControlled
          label={labels.subject}
          name="subject"
          type="text"
          onChange={e => setSubject(e.target.value)}
          value={subject}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={12}>
        <TextareaAutosize
          name="details"
          onChange={e => setDetails(e.target.value)}
          className={cx(classes.textarea, { [classes.textareaError]: detailsHasError })}
          aria-label={R.prop('details')(labels)}
          minRows={6}
          placeholder={R.prop('details')(labels)}
          value={details}
          onBlur={() => checkDetails(details)}
        />
      </Grid>
      <Grid item container justifyContent="space-between">
        <Grid item>
          <FormControlLabel
            control={<Checkbox name="checkedB" color="primary" />}
            label={<Typography variant="h6">{R.prop('checkbox')(labels)}</Typography>}
            checked={checked}
            onChange={e => setChecked(e.target.checked)}
          />
        </Grid>
        <Grid item>
          <Typography variant="h6" className={classes.label} style={{ paddingTop: 8 }}>
            {R.prop('organisationPrivacyPolicy')(labels)}
          </Typography>
        </Grid>
      </Grid>
      <Grid item container justifyContent="space-between" alignItems="center">
        <Grid item>{children}</Grid>
        <Grid item>
          <Button
            aria-label={R.prop('submit')(labels)}
            type="submit"
            disabled={isSendDisable}
            variant="contained"
            color="primary"
            alternative="siscc"
            onClick={onSubmit}
            size="large"
            className={classes.submitButton}
          >
            {R.prop('submit')(labels)}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

Contact.propTypes = {
  defaultMode: PropTypes.string,
  defaultFullName: PropTypes.string,
  defaultEmail: PropTypes.string,
  lang: PropTypes.string,
  theme: PropTypes.string,
  modes: PropTypes.array,
  send: PropTypes.func,
  isSubmitDisable: PropTypes.bool,
  children: PropTypes.node,
  labels: PropTypes.shape({
    name: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    organisation: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    personalTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    submit: PropTypes.string,
    email: PropTypes.string,
    details: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    contactForHelp: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    subject: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  }),
};
export default Contact;
