import { makeStyles, useTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import * as R from 'ramda';
import cx from 'classnames';
import { getIsRtl } from '../utils';
import makeStylesFn from './styles';
import CustomChip from './CustomChip';
import { myTooltipRenderer } from './utils';

const useStyles = makeStyles(makeStylesFn);

const GroupedChips = ({
  itemProps = {},
  onDelete,
  labelRenderer = R.prop('label'),
  tooltipRenderer,
  ariaLabel,
  reducingNbChips = 15,
  labels,
  testId = 'chips-test-id',
  ariaLabelTooltip = ' parent of ',
}) => {
  const { id: parentId, isNotRemovable, values = [] } = itemProps;
  const theme = useTheme();
  const isRtl = getIsRtl(theme);
  const classes = useStyles();

  return (
    <Fragment>
      <CustomChip
        testId={testId}
        parentId={parentId}
        label={`${labelRenderer(itemProps)}:`}
        ariaLabel={ariaLabel}
        chipClasses={cx(classes.mainChip, classes.chip)}
        onDelete={isNotRemovable ? null : onDelete}
        iconClasses={classes.icon}
        labelClasses={cx(classes.spaceLabel, classes.formatLabel)}
      />
      {R.pipe(R.length, R.flip(R.gt)(reducingNbChips))(values) ? (
        <CustomChip
          parentId={parentId}
          label={`${R.length(values)} ${R.prop('reducingChip')(labels)}`}
          chipClasses={cx(classes.secondaryChip, classes.chip)}
          onDelete={isNotRemovable ? null : onDelete}
          iconClasses={classes.icon}
          labelClasses={cx(classes.formatLabel)}
        />
      ) : (
        R.map(items => {
          const lastItem = R.last(items);
          const { id, isNotRemovable, deprecated } = lastItem;

          return (
            <CustomChip
              deprecated={deprecated}
              key={id}
              id={id}
              items={R.pipe(R.length, R.gte(2)) ? items : null}
              renderer={
                R.is(Function)(tooltipRenderer)
                  ? tooltipRenderer
                  : myTooltipRenderer({ isRtl, labelRenderer })
              }
              label={labelRenderer(lastItem)}
              labelRenderer={labelRenderer}
              ariaLabel={ariaLabel}
              parentId={parentId}
              onDelete={isNotRemovable ? null : onDelete}
              chipClasses={cx(classes.secondaryChip, classes.chip)}
              iconClasses={classes.icon}
              labelClasses={cx(classes.formatLabel)}
              ariaLabelTooltip={ariaLabelTooltip}
            />
          );
        })(values)
      )}
    </Fragment>
  );
};
GroupedChips.propTypes = {
  testId: PropTypes.string,
  itemProps: PropTypes.shape({
    id: PropTypes.string,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    isNotRemovable: PropTypes.bool,
    values: PropTypes.arrayOf(
      PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
          isNotRemovable: PropTypes.bool,
        }),
      ),
    ),
  }),
  onDelete: PropTypes.func,
  labelRenderer: PropTypes.func,
  tooltipRenderer: PropTypes.func,
  ariaLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  reducingNbChips: PropTypes.number,
  labels: PropTypes.shape({
    reducingChip: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  }),
  ariaLabelTooltip: PropTypes.string,
};
export default GroupedChips;
