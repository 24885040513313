import React from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => {
  const color1 = R.path(['palette', 'highlight', 'hl1'], theme) || theme.palette.primary.dark;

  return {
    defaultSelected: {
      backgroundColor: theme.palette.action.hover,
      '&:hover': {
        backgroundColor: `${theme.palette.action.selected} !important`,
      },
    },
    sisccRoot: {
      '&:hover': {
        color: theme.palette.getContrastText(color1),
        backgroundColor:
          R.path(['palette', 'highlight', 'hl1'], theme) || theme.palette.primary.dark,
        ...R.path(['mixins', 'toggleButton', 'hover'])(theme),
      },
    },
    sisccSelected: {
      color: theme.palette.getContrastText(color1),
      backgroundColor: color1,
      ...R.path(['mixins', 'toggleButton', 'selected'])(theme),
    },
  };
});

const MyButton = React.forwardRef(
  ({ className, selected, alternative = 'default', ...props }, ref) => {
    const classes = useStyles();
    return (
      <Button
        className={cx(className, R.prop(`${alternative}Root`)(classes), {
          [R.prop(`${alternative}Selected`)(classes)]: selected,
        })}
        aria-pressed={selected}
        ref={ref}
        {...props}
      >
        {props.children}
      </Button>
    );
  },
);

MyButton.propTypes = {
  className: PropTypes.string,
  alternative: PropTypes.string,
  selected: PropTypes.bool,
  children: PropTypes.node,
};

export default MyButton;
