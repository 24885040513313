import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '../Button';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = () => ({
  root: {
    minWidth: 64,
  },
  startIcon: {
    margin: 0,
  },
  label: {
    display: 'flex',
    flexDirection: 'column',
  },
});

const VerticalButton = React.forwardRef(
  ({ children, classes, selected, startIcon, loading, sizeLoading = 18, ...props }, ref) => (
    <Button
      classes={{
        root: classes.root,
        startIcon: classes.startIcon,
        label: classes.label,
      }}
      selected={selected}
      startIcon={loading ? <CircularProgress size={sizeLoading} /> : startIcon}
      ref={ref}
      {...props}
    >
      {children}
    </Button>
  ),
);

VerticalButton.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  classes: PropTypes.shape({
    root: PropTypes.string,
    startIcon: PropTypes.string,
    label: PropTypes.string,
  }),
  startIcon: PropTypes.node,
  selected: PropTypes.bool,
  loading: PropTypes.bool,
  sizeLoading: PropTypes.number,
};

export default withStyles(styles)(VerticalButton);
