import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { makeStyles } from '@material-ui/core/styles';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { useTheme } from '@material-ui/core/styles';
import { withBlank } from '../utils';

const useStyles = makeStyles(theme => ({
  container: {
    alignItems: 'center',
    display: 'flex',
    userSelect: 'none',
  },
  input: {
    width: 40,
    marginBottom: '0 !important',
    marginTop: '0 !important',
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
    padding: '0 !important',
    justifyContent: 'center',
    '& fieldset': {
      padding: '0 !important',
    },
    '& input': {
      padding: '0 !important',
      height: 24,
      textAlign: 'center',
    },
  },
  innerInput: {
    fontSize: theme.typography.fontSize,
  },
}));

const PageForm = ({ labels, page, pages, onSubmit }) => {
  const classes = useStyles();
  const [value, changeValue] = useState(page);

  const changePageFor = number => {
    if (R.isEmpty(R.match(/^[0-9]*$/g, number))) return;
    return changeValue(number);
  };

  useEffect(
    () => {
      changeValue(page);
    },
    [page],
  );

  const onEnterLabel = event => {
    if (event.key === 'Enter') {
      event.preventDefault();
      if (R.is(Function, onSubmit)) {
        const intValue = parseInt(event.target.value);
        if (isNaN(intValue)) return;
        if (intValue > pages) return;
        onSubmit(intValue);
      }
    }
  };

  return (
    <div className={classes.container}>
      <Typography variant="body2" tabIndex={0}>
        {R.prop('page', labels)}
      </Typography>
      <TextField
        inputProps={{
          'aria-label': R.prop('page', labels),
          className: classes.innerInput,
        }}
        className={classes.input}
        value={value}
        disabled={pages === 1}
        onChange={e => changePageFor(e.target.value)}
        onKeyPress={onEnterLabel}
        type="text"
        variant="outlined"
      />
      <Typography variant="body2" tabIndex={0}>{`${R.prop('of', labels)} ${pages}`}</Typography>
    </div>
  );
};

PageForm.propTypes = {
  page: PropTypes.number,
  pages: PropTypes.number,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  labels: PropTypes.object,
};

const Pagination = ({ labels, page, pages, onChange, onSubmit }) => {
  const classes = useStyles();
  const theme = useTheme();
  return (
    <div data-testid="pagination-test-id" className={classes.container}>
      <IconButton
        className={classes.iconButton}
        disabled={page === 1}
        color="primary"
        onClick={() => onChange(1)}
        aria-label={R.prop('startPage', labels)}
        size="small"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        className={classes.iconButton}
        disabled={page === 1}
        color="primary"
        onClick={() => onChange(page - 1)}
        aria-label={R.prop('previousPage', labels)}
        size="small"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <PageForm labels={labels} page={page} pages={pages} onSubmit={onSubmit} />
      <IconButton
        className={classes.iconButton}
        disabled={page === pages}
        color="primary"
        onClick={() => onChange(page + 1)}
        aria-label={R.prop('nextPage', labels)}
        size="small"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        className={classes.iconButton}
        disabled={page === pages}
        color="primary"
        onClick={() => onChange(pages)}
        aria-label={R.prop('endPage', labels)}
        size="small"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
};

Pagination.propTypes = {
  page: PropTypes.number,
  pages: PropTypes.number,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  labels: PropTypes.shape({
    page: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    of: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  }),
};

export default withBlank(({ isBlank, pages = 1 }) => ({
  isBlank: R.or(isBlank, R.equals(1, pages)),
}))(Pagination);
