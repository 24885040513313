import * as R from 'ramda';

export const changeLayout = ({ dragIndex, hoverIndex, dragZone, dropZone, currentLayout }) => {
  if (R.equals(dragZone, dropZone)) {
    return R.over(R.lensProp(dropZone), R.move(dragIndex, hoverIndex), currentLayout);
  }
  const item = R.pipe(R.prop(dragZone), R.nth(dragIndex))(currentLayout);
  return R.pipe(
    R.over(R.lensProp(dragZone), R.without([item])),
    R.over(R.lensProp(dropZone), R.insert(hoverIndex, item)),
  )(currentLayout);
};

export const rejectHiddenElement = R.map(R.reject(({ isHidden }) => isHidden));

export const addHiddenElement = layout =>
  R.mapObjIndexed((values, key) => R.pipe(R.prop(key), R.concat(values))(layout));

export const getIsOptionalActivated = (layout, optionalId) => {
  const founded = R.pipe(R.values, R.unnest, R.find(R.equals(optionalId)))(layout);
  return !R.isNil(founded);
};
