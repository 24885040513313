import React, { Fragment } from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Flags from './flags';
import { getDark, getIsRtl } from '../utils';
import { getValue } from './utils';

const useStyles = makeStyles(theme => ({
  section: {
    backgroundColor: getDark(theme) || theme.palette.secondary.dark,
    border: 'inherit',
    borderBottom: `thin solid ${theme.palette.grey[500]}`,
    borderRight: `thin solid ${theme.palette.grey[500]}`,
    padding: theme.spacing(0, 1),
    color: theme.palette.grey['A700'],
    verticalAlign: 'middle',
    whiteSpace: 'nowrap',
  },
  label: {
    fontStyle: 'italic',
    fontWeight: 'bold',
    marginRight: theme.spacing(0.5),
  },
  containerSection: {
    display: 'flex',
    alignItems: 'center',
  },
  stickyHeaderSection: {
    position: 'sticky',
  },
  flag: {
    height: '100%',
    backgroundColor: getDark(theme) || theme.palette.secondary.dark,
    paddingLeft: 12,
  },
  leftLabelPosition: {
    left: theme.spacing(1),
  },
  rightLabelPosition: {
    right: theme.spacing(1),
  },
  sectionLabelsContainer: {
    padding: theme.spacing(0.5, 0),
  },
}));
const mapIndexed = R.addIndex(R.map);

const SectionHeader = ({
  headerData,
  section,
  currentSection,
  xLayoutDataLength,
  headerHeight,
  SideIcon,
  HTMLRenderer,
  labelAccessor,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isRtl = getIsRtl(theme);
  if (R.compose(R.anyPass([R.isNil, R.isEmpty]), R.path([0, 'data']))(section)) return null;

  return (
    <TableRow
      key={section.key}
      className={classes.stickyHeaderSection}
      style={{ left: 0, top: headerHeight, zIndex: 2 }}
    >
      <TableCell
        component="th"
        className={classes.section}
        colSpan={R.pipe(R.length, R.inc, R.add(xLayoutDataLength))(headerData)}
        id={currentSection}
        scope="col"
      >
        <Grid container alignItems="center">
          <Grid
            item
            style={
              isRtl ? { position: 'sticky', right: '8px' } : { position: 'sticky', left: '8px' }
            }
          >
            <Typography variant="body1" component="div" className={classes.containerSection}>
              <span
                className={cx(
                  classes.stickyHeaderSection,
                  classes.leftLabelPosition,
                  classes.sectionLabelsContainer,
                )}
              >
                {mapIndexed((sectionHeader, index) => {
                  const value = getValue(labelAccessor)(sectionHeader);
                  return (
                    <Fragment key={index}>
                      {index !== 0 && <br />}

                      <Typography
                        noWrap
                        variant="body1"
                        component="span"
                        tabIndex={0}
                        className={classes.label}
                        data-testid="section-dim"
                        aria-label={R.path(['dimension', 'id'], sectionHeader)}
                      >
                        {R.pipe(R.prop('dimension'), labelAccessor)(sectionHeader)}
                        {':'}
                      </Typography>
                      <Typography noWrap variant="body1" component="span" tabIndex={0}>
                        {R.prop('label', value)}
                      </Typography>
                      <Flags
                        HTMLRenderer={HTMLRenderer}
                        labelAccessor={labelAccessor}
                        flags={R.prop('flags', value)}
                      />
                    </Fragment>
                  );
                }, R.path([0, 'data'], section))}
                <span>
                  <Flags
                    HTMLRenderer={HTMLRenderer}
                    flags={R.path([0, 'flags'], section)}
                    labelAccessor={labelAccessor}
                  />
                </span>
              </span>
            </Typography>
          </Grid>
          {!R.isNil(SideIcon) && (
            <Grid item style={{ marginLeft: 40 }}>
              <SideIcon sideProps={R.path([0, 'sideProps'], section)} />
            </Grid>
          )}
        </Grid>
      </TableCell>
    </TableRow>
  );
};

SectionHeader.propTypes = {
  headerData: PropTypes.array,
  section: PropTypes.array,
  currentSection: PropTypes.string,
  xLayoutDataLength: PropTypes.number,
  headerHeight: PropTypes.number,
  SideIcon: PropTypes.func,
  HTMLRenderer: PropTypes.func,
  labelAccessor: PropTypes.func,
};

export default SectionHeader;
