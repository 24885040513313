import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import useSize from '@react-hook/size';
import cx from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';
import Info from '@material-ui/icons/Info';
import Typography from '@material-ui/core/Typography';
import { T4_BREAKPOINTS } from '../theme';
import Title from './Title';
import Flags from './Flags';
import TooltipHeader from './TooltipHeader';
import { getReducedContent } from './utils';

const useStyles = makeStyles(theme => ({
  header: {
    alignItems: 'center',
    display: 'flex',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    marginBottom: 10,
    marginTop: 10,
    alignItems: 'baseline',
    ...R.pathOr({}, ['mixins', 'dataHeader', 'root'], theme),
  },
  title: {
    display: 'inline-block',
    width: '100%',
    lineHeight: 1.5,
    ...R.pathOr({}, ['mixins', 'dataHeader', 'title'], theme),
  },
  wrapperSubtitle: {
    alignItems: 'center',
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    lineHeight: 1.3,
  },
  subtitle: {
    lineHeight: 1.6,
    color: theme.palette.grey[600],
    ...R.pathOr({}, ['mixins', 'dataHeader', 'subtitle'], theme),
  },
  disclaimer: {
    padding: theme.spacing(0.5),
    lineHeight: 1.5,
    ...R.pathOr({}, ['mixins', 'dataHeader', 'disclaimer'], theme),
  },
  infoIcon: {
    height: '0.8em',
    color: theme.palette.grey[600],
  },
  svgWrapper: {
    marginLeft: 4,
    ...theme.typography.body1,
  },
  svg: {
    fontSize: 'inherit',
    borderBottom: '1px dotted',
    borderBottomColor: 'inherit',
  },
  content: {
    boxSizing: 'border-box',
    color: 'white',
    listStyleType: 'none',
    maxWidth: 270,
    padding: 0,
    margin: 0,
    fontSize: 12,
    fontWeight: 400,
    lineHeight: 1.43,
    ...theme.typography.body1,
  },
  subContent: {
    listStyle: 'none',
    padding: 0,
    marginLeft: 20,
    maxWidth: 270,
    ...theme.typography.body1,
  },
  sticky: {
    position: 'sticky',
    left: 0,
  },
  sideIconContainer: {
    marginLeft: '10px',
  },
}));

const DataHeader = ({
  children,
  title,
  subtitle = [],
  combinations,
  disclaimer,
  sideProps,
  SideIcon,
  isSticky = false,
}) => {
  const target = React.useRef(null);
  const [width] = useSize(target);
  const isWidthUp = R.gte(width);
  const isWidthLess = R.lt(width);
  const classes = useStyles();

  const reducedContent = getReducedContent({
    isWidthLess,
    title,
    subtitle,
    combinations,
    disclaimer,
  });

  const theme = useTheme();
  const iconColor = R.path(['mixins', 'dataHeader', 'icon', 'color'], theme);
  const fontSizeTitle = R.cond([
    [R.always(isWidthLess(T4_BREAKPOINTS.xs3)), R.always(12)],
    [R.always(isWidthLess(T4_BREAKPOINTS.sm)), R.always(14)],
    [R.always(isWidthLess(T4_BREAKPOINTS.md2)), R.always(16)],
    [R.T, R.identity],
  ])(18);
  const fontSizeSubtitle = R.cond([
    [R.always(isWidthLess(T4_BREAKPOINTS.xs3)), R.always(12)],
    [R.always(isWidthLess(T4_BREAKPOINTS.sm)), R.always(13)],
  ])(14);
  const subtitleLength = R.length(subtitle);
  const combinationsLength = R.length(combinations);

  return (
    <div
      className={classes.header}
      style={{
        justifyContent: isWidthLess(T4_BREAKPOINTS.xs2) ? 'flex-end' : 'space-between',
      }}
      ref={target}
      data-testid="data-header"
    >
      <div className={cx(classes.container, { [classes.sticky]: isSticky })}>
        {isWidthUp(T4_BREAKPOINTS.xs2) && title && (
          <Typography
            variant="h1"
            className={classes.title}
            style={{ fontSize: fontSizeTitle }}
            data-testid="data-header-title"
            aria-label={title.label}
          >
            {title.label}
            {!R.isNil(SideIcon) && (
              <span className={classes.sideIconContainer}>
                <SideIcon sideProps={sideProps} />
              </span>
            )}
            <Flags classes={classes} content={R.propOr([], 'flags', title)} />
          </Typography>
        )}
        {isWidthUp(T4_BREAKPOINTS.xs3) && subtitle && R.not(R.isEmpty(subtitle)) && (
          <div className={classes.wrapperSubtitle}>
            {R.pipe(
              R.addIndex(R.map)((sub, index) => (
                <div
                  key={`subtitle-${index}`}
                  className={classes.subtitle}
                  style={{ fontSize: fontSizeSubtitle }}
                  data-testid="data-header-subtitle"
                  aria-label={sub.header}
                >
                  <Title header={sub.header} />
                  <Typography variant="inherit">{sub.label}</Typography>
                  {R.not(R.isEmpty(R.propOr([], 'flags', sub))) && (
                    <Flags classes={classes} content={R.prop('flags', sub)} />
                  )}
                  {R.lt(index + 1, subtitleLength) && (
                    <svg width="24" height="8">
                      <circle cx="12" cy="4" r="3" stroke={iconColor} fill={iconColor} />
                    </svg>
                  )}
                </div>
              )),
            )(subtitle)}
          </div>
        )}
        {isWidthUp(T4_BREAKPOINTS.xs3) && combinations && R.not(R.isEmpty(combinations)) && (
          <div className={classes.wrapperSubtitle}>
            {R.pipe(
              R.addIndex(R.map)((comb, index) => (
                <div
                  key={`combination-${index}`}
                  className={classes.subtitle}
                  data-testid="data-header-combination"
                  aria-label={comb.header}
                >
                  <Title header={comb.header} />
                  <Typography variant="inherit">{comb.label}</Typography>
                  {R.not(R.isEmpty(R.propOr([], 'flags', comb))) && (
                    <Flags classes={classes} content={R.prop('flags', comb)} />
                  )}
                  {R.lt(index + 1, combinationsLength) && (
                    <svg width="24" height="8">
                      <circle cx="12" cy="4" r="3" stroke={iconColor} fill={iconColor} />
                    </svg>
                  )}
                </div>
              )),
            )(combinations)}
          </div>
        )}
        {isWidthUp(T4_BREAKPOINTS.xs3) && disclaimer && (
          <Typography
            data-testid="data-header-disclaimer"
            className={classes.disclaimer}
            style={{ fontSize: fontSizeSubtitle }}
          >
            {disclaimer}
          </Typography>
        )}
      </div>
      <div className={classes.children}>
        {R.not(R.isEmpty(reducedContent)) && (
          <TooltipHeader classes={classes} content={reducedContent}>
            <Info className={classes.infoIcon} />
          </TooltipHeader>
        )}
        {children}
      </div>
    </div>
  );
};

DataHeader.propTypes = {
  title: PropTypes.shape({
    label: PropTypes.string,
    flags: PropTypes.arrayOf(
      PropTypes.shape({
        header: PropTypes.string,
        label: PropTypes.string,
        sub: PropTypes.arrayOf(
          PropTypes.shape({
            label: PropTypes.string,
          }),
        ),
      }),
    ),
  }),
  subtitle: PropTypes.arrayOf(
    PropTypes.shape({
      header: PropTypes.string,
      label: PropTypes.string,
      flags: PropTypes.arrayOf(
        PropTypes.shape({
          header: PropTypes.string,
          label: PropTypes.string,
          sub: PropTypes.arrayOf(
            PropTypes.shape({
              label: PropTypes.string,
            }),
          ),
        }),
      ),
    }),
  ),
  combinations: PropTypes.arrayOf(
    PropTypes.shape({
      header: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
  sideProps: PropTypes.object,
  SideIcon: PropTypes.func,
  disclaimer: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  children: PropTypes.node,
  isSticky: PropTypes.bool,
};

export default DataHeader;
