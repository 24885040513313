import React from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import dateFns from 'date-fns';
import Grid from '@material-ui/core/Grid';
import { numeralFormat } from './lib';
import { Select } from '../';
import useStyles from './styles';
import { defaultfreq } from './constants';
import {
  getRange,
  getFrequencyOptions,
  getDefaultFrequency,
  getLayout,
  getPeriodValue,
  getDate,
  getDateInTheRange,
  getDestructuringDate,
  isValidNumber,
} from './lib';
import { withBlank } from '../utils';

const Period = ({
  defaultFrequency = defaultfreq,
  availableFrequencies = [defaultfreq],
  boundaries: _boundaries,
  availableBoundaries: _availableBoundaries,
  changePeriod,
  changeFrequency,
  period: _period,
  labels,
  periodDisabled,
  frequencyDisabled,
  disabled,
}) => {
  const classes = useStyles();
  const frequencies = getFrequencyOptions(availableFrequencies, labels);
  if (R.isEmpty(frequencies)) return null;
  const frequency = getDefaultFrequency({
    defaultFrequency,
    frequencies: R.pluck('value')(frequencies),
  });
  const period = R.defaultTo([], _period);
  const boundaries = R.defaultTo([], _boundaries);
  const availableBoundaries = R.defaultTo([], _availableBoundaries);
  const isPeriodDisabled = R.or(R.any(R.isEmpty)([period, boundaries]), periodDisabled);
  const startDate = R.head(period);
  const endDate = R.last(period);

  const startPeriodBoundaries = [R.head(boundaries), R.defaultTo(R.last(boundaries), endDate)];
  const endPeriodBoundaries = [R.defaultTo(R.head(boundaries), startDate), R.last(boundaries)];
  const ranges = [
    getRange(startPeriodBoundaries, startDate),
    getRange(endPeriodBoundaries, endDate),
  ];

  const showFrequency = R.and(R.gt(R.length(frequencies), 1), R.not(frequencyDisabled));
  const destructuringDates = R.map(getDestructuringDate)(period);

  const onChangeFrequency = value => {
    if (R.is(Function, changeFrequency)) return changeFrequency(value);
  };
  const _getIsDisabled = getter => (value, id) => {
    const fn = R.prop(getter)({ start: R.head, end: R.last });
    if (!isValidNumber(Number(value)) || R.length(availableBoundaries) !== 2) {
      return false;
    }
    const dateValue = getPeriodValue(fn(destructuringDates));

    let date = getDate(frequency, dateValue, { [id]: Number(value) }, getter === 'end');
    const [availableStart, availableEnd] = availableBoundaries;
    if (id === 'year') {
      if (dateFns.isSameYear(date, availableStart)) {
        date = availableStart;
      } else if (dateFns.isSameYear(date, availableEnd)) {
        date = availableEnd;
      }
    }
    return (
      dateFns.compareAsc(date, availableStart) === -1 ||
      dateFns.compareDesc(date, availableEnd) === -1
    );
  };
  const onChangePeriod = (getter, id) => value => {
    const fn = R.prop(getter)({ start: R.head, end: R.last });
    const lensIndex = R.prop(getter)({ start: R.lensIndex(0), end: R.lensIndex(1) });
    const dateValue = getPeriodValue(fn(destructuringDates));
    const selectedValue = R.ifElse(
      isValidNumber,
      R.assoc(id, R.__, {}),
      R.always(undefined),
    )(Number(value));
    const newDate = getDate(frequency, dateValue, selectedValue, getter === 'end');
    if (R.is(Function, changePeriod)) {
      return changePeriod(
        R.pipe(R.set(lensIndex, newDate), ([startDate, endDate]) => [
          getDateInTheRange(startPeriodBoundaries)(startDate),
          getDateInTheRange(endPeriodBoundaries)(endDate),
        ])(period),
      );
    }
  };
  return (
    <Grid data-testid="period-picker-test-id" container direction="row">
      {showFrequency && (
        <Grid
          item
          xs={12}
          className={classes.headerContainer}
          data-testid="period-picker-frequency-test-id"
        >
          <Grid container direction="row">
            <Grid item xs={12}>
              <Select
                id={labels.frequency}
                label={labels.frequency}
                onChange={onChangeFrequency}
                value={frequency}
                items={frequencies}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
      {R.not(isPeriodDisabled) &&
        R.map(({ getter, layout, label, value, testId, getIsDisabled }) => (
          <Grid key={getter} item xs={12} data-testid={testId}>
            <Grid container direction="row">
              <Grid item xs={12} className={classes.sideLabel}>
                <span
                  tabIndex={0} // eslint-disable-line jsx-a11y/no-noninteractive-tabindex
                  aria-label={label}
                >
                  {label}
                </span>
              </Grid>
            </Grid>
            <Grid container direction="row">
              <Grid item xs={12}>
                <Grid container className={classes.headerContainer} spacing={1}>
                  {R.map(row =>
                    R.map(({ id, values }) => {
                      const selectedValue = R.isNil(R.prop(id)(value))
                        ? R.head(values)
                        : R.prop(id)(value);
                      return (
                        <Grid
                          key={id}
                          item
                          xs={12 / R.length(row)}
                          className={classes.selectContainer}
                        >
                          <Select
                            onChange={onChangePeriod(getter, id)}
                            label={R.prop(id)(labels)}
                            id={`${id}-${label}`}
                            dataTestId={`${id}-${label}-test-id`}
                            value={selectedValue}
                            disabled={disabled || false}
                            items={R.map(option => ({
                              id,
                              value: option,
                              disabled: getIsDisabled(option, id),
                              label: R.ifElse(
                                R.is(String),
                                R.identity,
                                R.prop(id)(numeralFormat),
                              )(option),
                            }))(values)}
                          />
                        </Grid>
                      );
                    })(row),
                  )(layout)}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ))([
          {
            getter: 'start',
            testId: 'period-picker-start-period-test-id',
            layout: getLayout(frequency, R.head(ranges)),
            label: R.prop('start')(labels),
            value: getPeriodValue(R.head(destructuringDates)),
            getIsDisabled: _getIsDisabled('start'),
          },
          {
            getter: 'end',
            testId: 'period-picker-end-period-test-id',
            layout: getLayout(frequency, R.last(ranges), /*R.last(availableRanges)*/ []),
            label: R.prop('end')(labels),
            value: getPeriodValue(R.last(destructuringDates)),
            getIsDisabled: _getIsDisabled('end'),
          },
        ])}
    </Grid>
  );
};

Period.propTypes = {
  defaultFrequency: PropTypes.string,
  availableFrequencies: PropTypes.array,
  availableBoundaries: PropTypes.array,
  boundaries: PropTypes.array,
  changePeriod: PropTypes.func,
  changeFrequency: PropTypes.func,
  period: PropTypes.array,
  labels: PropTypes.object,
  periodDisabled: PropTypes.bool,
  frequencyDisabled: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default withBlank(({ periodDisabled, frequencyDisabled, isBlank }) => ({
  isBlank: R.or(isBlank, R.and(periodDisabled, frequencyDisabled)),
}))(Period);
