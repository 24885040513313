import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { getIsRtl } from '../utils';

const useStyles = makeStyles(theme => ({
  appBar: {
    background: theme.palette.grey[100],
    minWidth: 300,
  },
  root: {
    alignItems: 'flex-start',
  },
  gutters: {
    padding: '8px 5%',
  },
  leftLabel: {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.grey['A700'],
    textAlign: 'left',
  },
  rightLabel: {
    color: theme.palette.grey['A700'],
    textAlign: 'left',
  },
  rtl: {
    textAlign: 'right',
  },
  fixed: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100%',
    zIndex: 0,
  },
}));

const SisccFooter = React.forwardRef(({ leftLabel, rightLabel = [], isFixed }, ref) => {
  const classes = useStyles();
  const theme = useTheme();
  const isRtl = getIsRtl(theme);

  return (
    <AppBar
      ref={ref}
      data-testid="footer-bar-test-id"
      position="static"
      className={cx(classes.appBar, { [classes.fixed]: isFixed })}
    >
      <Toolbar classes={{ root: classes.root, gutters: classes.gutters }}>
        <Grid xs={4} item container justifyContent="flex-start" alignItems="flex-start">
          <Typography variant="body2" className={cx(classes.leftLabel, { [classes.rtl]: isRtl })}>
            {leftLabel}
          </Typography>
        </Grid>
        <Grid xs={8} item container justifyContent="flex-end" alignItems="flex-start">
          <Typography variant="body2" className={cx(classes.rightLabel, { [classes.rtl]: !isRtl })}>
            {rightLabel}
          </Typography>
        </Grid>
      </Toolbar>
    </AppBar>
  );
});

SisccFooter.propTypes = {
  leftLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  rightLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  isFixed: PropTypes.bool,
};

export default SisccFooter;
