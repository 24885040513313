import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import Card from '@material-ui/core/Card';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import { Tooltip, Warning as WarningIcon } from '../';

const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: theme.palette.secondary.dark,
    boxShadow: 'none',
    padding: theme.spacing(2),
    width: '100%',
  },
  title: {
    padding: 0,
    ...R.pathOr({}, ['mixins', 'share', 'title'], theme),
  },
  content: {
    padding: theme.spacing(1, 0),
    '&:last-child': {
      padding: theme.spacing(1, 0),
    },
  },
  label: theme.typography.body2,
  warning: {
    marginLeft: theme.spacing(0.5),
    width: 'unset',
    fontSize: 'inherit',
    height: '1.3em',
    ...R.pathOr({}, ['mixins', 'share', 'warning'], theme),
  },
}));

const Warning = ({ title }) => {
  const classes = useStyles();
  const theme = useTheme();
  const warningColor = R.path(['palette', 'highlight', 'hl1'], theme) || theme.palette.primary.dark;
  const warningTextColor = theme.palette.getContrastText(warningColor);
  return (
    <Tooltip
      className={classes.warning}
      title={title}
      tabIndex={0}
      variant="warning"
      aria-hidden={false}
      placement="bottom"
      PopperProps={{
        modifiers: {
          flip: {
            behavior: ['bottom', 'top', 'left', 'right'],
          },
        },
      }}
    >
      <WarningIcon pathBackgroundColor={warningColor} pathColor={warningTextColor} />
    </Tooltip>
  );
};

Warning.propTypes = {
  title: PropTypes.string,
};

const Mode = ({ changeMode, mode, modes = [], title }) => {
  const classes = useStyles();
  return (
    <Card className={classes.container}>
      <CardContent className={classes.content}>
        <Typography variant="h6" className={classes.title}>
          {title}
        </Typography>
        <RadioGroup onChange={e => changeMode(e.target.value)} value={mode}>
          {R.map(({ label, value, disabled = false, warningMessage }) => (
            <FormControlLabel
              classes={{ label: classes.label }}
              key={value}
              disabled={disabled}
              control={<Radio variant="outlined" color="primary" />}
              label={
                <span>
                  {label}
                  {warningMessage && <Warning title={warningMessage} />}
                </span>
              }
              value={value}
            />
          ))(modes)}
        </RadioGroup>
      </CardContent>
    </Card>
  );
};

Mode.propTypes = {
  changeMode: PropTypes.func,
  mode: PropTypes.string,
  modes: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
      value: PropTypes.string,
      warningMessage: PropTypes.string,
      disabled: PropTypes.bool,
    }),
  ),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

export default Mode;
