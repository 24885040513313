import * as R from 'ramda';

export const getValue = labelAccessor => item =>
  R.has('values', item)
    ? R.reduce(
        (acc, val) => ({
          label: acc.label,
          id: R.isEmpty(acc.id) ? val.id : `${acc.id}, ${val.id}`,
          flags: R.concat(acc.flags, val.flags || []),
          parents: R.concat(acc.parents, val.parents || []),
        }),
        { id: '', flags: [], parents: [], label: labelAccessor(item.values) },
        item.values,
      )
    : R.pipe(R.prop('value'), val => R.assoc('label', labelAccessor(val), val))(item);

export const defaultLabelAccessor = item => {
  if (R.is(Object, item)) {
    return R.prop('name', item);
  }
  return R.pipe(R.pluck('name'), R.join(', '))(item);
};
