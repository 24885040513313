import { createTheme } from '@material-ui/core/styles';
import { sisccTheme } from '../../src/';

export default {
  siscc: (props) => createTheme(sisccTheme(props)),
  pink: ({ rtl = 'ltr' }) =>
    createTheme({
      direction: rtl,
      overrides: {
        MuiButton: {
          text: {
            textTransform: 'none',
          },
        },
      },
      palette: {
        action: {
          active: 'rgba(246, 78, 87, 0.54)',
          selected: 'rgba(246, 78, 87, 0.25)',
          hover: 'rgba(246, 78, 87, 0.13)',
        },
        primary: {
          main: '#b8005c',
          light: '#fbbbd2',
          dark: '#930055',
        },
        secondary: {
          main: '#fde4ed',
          light: '#ffffff',
          dark: '#ffffff',
        },
      },
    }),
};
