import React, { useState } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import cx from 'classnames';
import useSize from '@react-hook/size';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Collapse from '@material-ui/core/Collapse';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import IconButton from '@material-ui/core/IconButton';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import Link from '@material-ui/core/Link';
import Divider from '@material-ui/core/Divider';
import Tooltip from '../Tooltip/Tooltip';
import { withBlank } from '../utils';
import { isLast } from '../Chips/utils';

const useStyles = makeStyles(theme => ({
  card: {
    borderBottom: `1px solid ${theme.palette.grey[700]}`,
    padding: theme.spacing(0, 0, 2, 0),
    '& mark': {
      color: 'inherit',
      borderBottom: `2px solid ${
        R.path(['palette', 'highlight', 'hl2'])(theme) || theme.palette.success.dark
      }`,
    },
  },
  cardHeader: {
    padding: theme.spacing(1, 0, 0, 0),
  },
  cardContent: {
    padding: theme.spacing(0.5, 0),
    '&:last-child': {
      padding: theme.spacing(0.5, 0),
    },
  },
  cardActions: {
    padding: theme.spacing(0.5, 0, 0, 0),
    justifyContent: 'space-between',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
  },
  rightSide: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '100%',
    padding: theme.spacing(0.5, 0),
  },
  dataset: {
    ...R.pathOr({}, ['mixins', 'dataflow', 'title'], theme),
    '&:hover': {
      color: theme.palette.primary.light,
      textDecoration: 'none', // remove this line when blueprint css import will no longer be in the DE
    },
  },
  noDisplay: {
    display: 'none',
  },
  hidden: {
    visibility: 'hidden',
  },
  descriptionHighlight: {
    padding: 0,
  },
  footer: {
    padding: theme.spacing(0.5, 0, 0, 0),
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  buttonLabel: {
    flexDirection: 'column',
  },
  divider: {
    width: 100,
    margin: theme.spacing(0.5, 0.5),
  },
  fadeContent: {
    color: theme.palette.grey['A200'],
    position: 'relative',
    overflow: 'hidden',
  },
  fading: {
    position: 'absolute',
    left: 0,
    right: 0,
    height: 15,
    margin: 0,
    padding: 0,
    backgroundImage: 'linear-gradient(to bottom, transparent, white)',
  },
  wrapper: {
    height: '100%',
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  mainContent: {
    minWidth: '200px',
    margin: theme.spacing(0, 0.5),
  },
  mutedText: {
    ...R.pathOr({}, ['mixins', 'dataflow', 'desc', 'mutedText'], theme),
  },
  unmutedText: {
    ...R.pathOr({}, ['mixins', 'dataflow', 'desc', 'unmutedText'], theme),
  },
  categories: {
    maxWidth: '100%',
    whiteSpace: 'noWrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  tooltipIndicator: {
    borderBottom: `1px dotted ${theme.palette.primary.main}`,
  },
}));

const BasicHTMLRenderer = ({ html, ...rest }) => (
  <span dangerouslySetInnerHTML={{ __html: html }} {...rest} />
);

BasicHTMLRenderer.propTypes = {
  html: PropTypes.string,
};

const Dataflow = ({
  testId,
  title,
  body,
  url,
  handleUrl,
  label,
  labels,
  dimensions = [],
  highlights = [],
  categories = [],
  children,
  HTMLRenderer = BasicHTMLRenderer,
  id,
}) => {
  const classes = useStyles();
  const [isExpanded, setExpand] = useState(false);
  const description = R.propOr('', 'description', body);

  const target = React.useRef();
  const [, height] = useSize(target);

  const handleExpand = () => {
    setExpand(prev => !prev);
  };

  const collapsedSize = R.min(height, 70);
  const needEllipse = collapsedSize >= 70;
  const mapIndexed = R.addIndex(R.map);

  return (
    <Card elevation={0} square className={classes.card} data-testid={`${testId}`}>
      <div className={classes.container}>
        <div className={classes.mainContent}>
          <CardHeader
            className={classes.cardHeader}
            title={
              <div className={classes.header} data-testid={`${testId}_title`}>
                <Typography variant="h6" color="primary" display="inline">
                  <Link
                    className={classes.dataset}
                    href={url}
                    onClick={handleUrl}
                    color="primary"
                    underline="none"
                  >
                    <HTMLRenderer html={title} />
                  </Link>
                </Typography>
              </div>
            }
          />
          {R.not(R.isEmpty(description)) && (
            <Collapse
              in={isExpanded}
              collapsedSize={collapsedSize}
              classes={isExpanded ? undefined : { wrapper: classes.wrapper }}
            >
              <CardContent
                ref={target}
                className={cx(classes.cardContent, { [classes.fadeContent]: !isExpanded })}
              >
                <Typography
                  variant="body2"
                  align="justify"
                  className={cx(classes.container, classes.mutedText, {
                    [classes.unmutedText]: isExpanded,
                  })}
                >
                  <HTMLRenderer html={description} />
                </Typography>
                {!isExpanded && needEllipse && (
                  <div className={classes.fading} style={{ top: collapsedSize - 15 }} />
                )}
              </CardContent>
            </Collapse>
          )}
          {R.not(R.empty(R.propOr('', 'highlightDescription')(body))) && (
            <CardContent className={classes.descriptionHighlight}>
              <Typography variant="body2" align="justify" className={classes.container}>
                <HTMLRenderer
                  html={R.propOr('', 'highlightDescription')(body)}
                  className={cx({ [classes.noDisplay]: isExpanded })}
                />
              </Typography>
            </CardContent>
          )}
          <CardContent className={classes.cardContent}>
            {!R.isEmpty(dimensions) && (
              <Typography
                variant="body2"
                color="textSecondary"
                data-testid={`${testId}_dimensions`}
              >
                <strong>{R.prop('dimensions')(labels)}:&nbsp;</strong>
                <HTMLRenderer html={R.join(', ', dimensions)} />
              </Typography>
            )}
            {R.map(([field, fieldHighlights]) => (
              <Typography
                variant="body2"
                key={field}
                color="textSecondary"
                data-testid={`${testId}_highlight_${field}`}
              >
                <strong>{field}:&nbsp;</strong>
                {R.map(
                  fieldHighlight => (
                    <HTMLRenderer key={`${id}-${field}`} html={fieldHighlight} />
                  ),
                  fieldHighlights,
                )}
              </Typography>
            ))(highlights)}
            <Collapse in={isExpanded}>
              <Divider className={classes.divider} />
              {R.map(([field, values]) => {
                return (
                  <Typography
                    variant="body2"
                    key={field}
                    color="textSecondary"
                    className={classes.categories}
                  >
                    <strong>{field}:&nbsp;</strong>
                    <span>
                      {mapIndexed(
                        (category, idx) =>
                          R.is(Array)(category) ? (
                            <Tooltip
                              key={`category-tooltip-value-${idx}`}
                              title={R.join(' > ', category)}
                            >
                              <span>
                                <span className={classes.tooltipIndicator}>
                                  {' '}
                                  {`... > ${R.last(category)}`}
                                </span>
                                {isLast(idx, values) ? '' : ', '}
                              </span>
                            </Tooltip>
                          ) : (
                            `${R.identity(category)}${isLast(idx, values) ? '' : ', '}`
                          ),
                        values,
                      )}
                    </span>
                  </Typography>
                );
              })(categories)}
              {label && (
                <div>
                  <Typography variant="body2" color="textSecondary">
                    <strong>{R.prop('source')(labels)}:&nbsp;</strong> {label}
                  </Typography>
                  <Divider className={classes.divider} />
                </div>
              )}
              <CardActions className={classes.footer} disableSpacing>
                <Typography variant="caption" color="textSecondary">
                  <strong>{R.prop('lastUpdated')(labels)}&nbsp;</strong>{' '}
                  <span>{R.prop('date')(labels)}</span>
                </Typography>
                <Typography variant="caption" color="textSecondary">
                  {R.prop('note')(labels)}
                </Typography>
              </CardActions>
            </Collapse>
          </CardContent>
        </div>
        <div>
          <CardActions className={classes.rightSide} disableSpacing>
            <IconButton
              data-testid="dataflow-expand-button-test-id"
              aria-label={R.propOr('Show details above', 'expand')(labels)}
              aria-expanded={isExpanded}
              onClick={() => handleExpand()}
              color="primary"
              size="small"
            >
              {isExpanded ? (
                <ExpandLess data-testid="dataflow-not-expand-test-id" />
              ) : (
                <ExpandMore data-testid="dataflow-expanded-test-id" />
              )}
            </IconButton>
            {children && children}
          </CardActions>
        </div>
      </div>
    </Card>
  );
};

Dataflow.propTypes = {
  testId: PropTypes.string,
  id: PropTypes.string,
  title: PropTypes.string,
  body: PropTypes.shape({
    description: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    highlightDescription: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  }),
  url: PropTypes.string,
  handleUrl: PropTypes.func,
  label: PropTypes.string,
  labels: PropTypes.shape({
    source: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    lastUpdated: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    note: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    date: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  }),
  highlights: PropTypes.array,
  dimensions: PropTypes.array,
  categories: PropTypes.array,
  children: PropTypes.node,
  HTMLRenderer: PropTypes.func,
};

export default withBlank(({ isBlank, title = '' }) => ({
  isBlank: R.or(isBlank, R.isEmpty(title)),
}))(Dataflow);
