import * as R from 'ramda';
import Lens from '@material-ui/icons/Lens';

export const valueLabel = 'Xxxx';
export const maxCount = 3;

export const getMaxValuesLength = items => Math.min(Math.max(...R.pluck('count', items)), maxCount);
export const getEmptyValues = R.ifElse(R.flip(R.lte)(0), R.always(['']), R.times(R.always('')));
export const getLabel = itemRenderer => R.map(item => R.assoc('label', itemRenderer(item), item));

const addMissingValues = (id, nbShowedValues) => values =>
  R.times(
    nb => ({ id: `table-preview-${id}-${nb}`, label: '' }),
    nbShowedValues - R.length(values),
  );

export const getEqualLengthValues = nbShowedValues =>
  R.map(dimension => {
    const id = dimension.id;
    return R.ifElse(
      R.pipe(R.prop('values'), R.length, R.flip(R.lt)(nbShowedValues)),
      R.over(
        R.lensProp('values'),
        R.converge(R.concat, [R.identity, addMissingValues(id, nbShowedValues)]),
      ),
      R.identity,
    )(dimension);
  });

export const getEnhancedSections = sectionLength =>
  R.pipe(
    R.map(
      R.pipe(
        R.over(R.lens(R.propOr([], 'values'), R.assoc('values')), R.pipe(R.pluck('label'), R.head)),
        R.assoc('Icon', Lens),
      ),
    ),
    R.over(R.lensIndex(sectionLength - 1), R.dissoc('Icon')),
  );
