import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Form from './Form';
import Mode from './Mode';
import { withBlank } from '../utils';

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    margin: theme.spacing(1, 0),
    padding: 20,
  },
  subContainer: {
    width: '100%',
    margin: theme.spacing(2, 0),
  },
  divider: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  disclaimerContainer: {
    padding: theme.spacing(0, 1),
  },
  disclaimer: {
    backgroundColor: R.path(['palette', 'tertiary', 'dark'])(theme) || theme.palette.grey[100],
    padding: theme.spacing(1, 2),
    width: '100%',
    height: '100%',
  },
}));

const Share = ({
  changeIsMessageOpen,
  changeMode,
  changeMail,
  hasError,
  isMessageOpen,
  isSharing,
  mail,
  mode,
  modes,
  share,
  labels,
}) => {
  const classes = useStyles();
  return (
    <Grid data-testid="share-test-id" container className={classes.container}>
      <Grid item xs={12}>
        <Mode changeMode={changeMode} modes={modes} mode={mode} title={R.prop('title')(labels)} />
      </Grid>
      <Grid container className={classes.subContainer}>
        <Grid item xs={12} md={6} className={classes.divider}>
          <Form
            changeMail={changeMail}
            changeIsMessageOpen={changeIsMessageOpen}
            hasError={hasError}
            isMessageOpen={isMessageOpen}
            isSharing={isSharing}
            mail={mail}
            share={share}
            labels={labels}
          />
        </Grid>
        <Grid item xs={12} md={6} className={classes.disclaimerContainer}>
          <Paper className={classes.disclaimer}>{R.prop('disclaimer')(labels)}</Paper>
        </Grid>
      </Grid>
    </Grid>
  );
};

Share.propTypes = {
  changeIsMessageOpen: PropTypes.func,
  changeMode: PropTypes.func,
  changeMail: PropTypes.func,
  hasError: PropTypes.bool,
  isMessageOpen: PropTypes.bool,
  isSharing: PropTypes.bool,
  mail: PropTypes.string,
  mode: PropTypes.string,
  modes: PropTypes.array,
  share: PropTypes.func,
  labels: PropTypes.shape({
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    disclaimer: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    submit: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    email: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    successTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    successMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    errorTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    errorMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  }),
};

export default withBlank(({ isBlank }) => ({ isBlank }))(Share);
