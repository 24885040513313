import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { Tooltip } from '../';
import Title from './Title';

export const TooltipHeader = ({ children, classes, content = [] }) => {
  if (R.isEmpty(content)) return null;

  const listContent = (
    <ul className={classes.content}>
      {R.addIndex(R.map)(
        ({ header, label, sub = [] }, index) => (
          <li key={`${label}-${index}`}>
            <Title header={header} />
            {label}
            {R.not(R.isEmpty(sub)) && (
              <ul className={classes.subContent}>
                {R.addIndex(R.map)(
                  ({ label }, index) => <li key={`${label}-${index}`}>{label}</li>,
                  sub,
                )}
              </ul>
            )}
          </li>
        ),
        content,
      )}
    </ul>
  );

  return (
    <Tooltip
      title={listContent}
      tabIndex={0}
      aria-label={R.pipe(R.pluck('label'), R.join(', '))(content)}
      aria-hidden={false}
      placement="top"
      PopperProps={{
        modifiers: {
          flip: {
            behavior: ['top', 'bottom', 'left', 'right'],
          },
        },
      }}
    >
      {children}
    </Tooltip>
  );
};

TooltipHeader.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object,
  content: PropTypes.arrayOf(
    PropTypes.shape({
      header: PropTypes.string,
      label: PropTypes.string,
      sub: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,
        }),
      ),
    }),
  ),
};

export default TooltipHeader;
