import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { makeStyles } from '@material-ui/core/styles';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import { Tooltip } from '../';
import useKeyboardEscapeHandler from '../hooks/useKeybordEscapeHandler';

const useStyles = makeStyles(theme => ({
  title: {
    padding: 0,
    ...R.pathOr({}, ['mixins', 'share', 'title'], theme),
  },
  label: theme.typography.body2,
  icon: {
    marginLeft: theme.spacing(0.5),
  },
  radioGroup: {
    alignContent: 'flex-start',
  },
}));

const Warning = ({ title, children }) => {
  const classes = useStyles();
  const { open, setOpen } = useKeyboardEscapeHandler();

  return (
    <Tooltip
      className={classes.icon}
      title={title}
      variant="light"
      aria-label={title}
      aria-hidden={false}
      interactive
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
    >
      {children}
    </Tooltip>
  );
};

Warning.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
};

const Mode = ({ changeMode, placement = 'end', mode, modes = [], isSmall = false, children }) => {
  const classes = useStyles();
  const mapIndexed = R.addIndex(R.map);
  return (
    <RadioGroup
      onChange={e => changeMode(e.target.value)}
      value={mode}
      className={classes.radioGroup}
      tabIndex={0}
    >
      {mapIndexed(({ label, value, popperLabel, disabled }, idx) => (
        <FormControlLabel
          classes={{ label: classes.label }}
          key={`${value}-${idx}`}
          checked={mode === value}
          control={
            <Radio
              variant="outlined"
              color="primary"
              size={isSmall ? 'small' : 'medium'}
              inputProps={{ tabIndex: '0', id: `${label}_${idx}` }}
            />
          }
          label={
            <span id={label}>
              {label}
              {popperLabel && <Warning title={popperLabel}>{children}</Warning>}
            </span>
          }
          labelPlacement={placement}
          value={value}
          disabled={disabled || false}
          style={{ height: isSmall ? '25px' : null }}
          role="radio"
        />
      ))(modes)}
    </RadioGroup>
  );
};

Mode.propTypes = {
  changeMode: PropTypes.func,
  children: PropTypes.node,
  mode: PropTypes.string,
  placement: PropTypes.string,
  modes: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
      value: PropTypes.string,
      popperLabel: PropTypes.string,
      disabled: PropTypes.bool,
    }),
  ),
  isSmall: PropTypes.bool,
};

export default Mode;
