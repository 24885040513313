import React, { useRef, useState, useEffect, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import Table from '@material-ui/core/Table';
import Header from './header';
import SubHeader from './subHeader';
import Section from './section';
import Cell from './cell';
import { defaultLabelAccessor } from './utils';

/*
 *  Accessibility.
 *  Do not use "TableHead" from Material-ui.
 *  Material-ui, add "scope" attribute for accessibility, "scope" attribute can break screen reader if use with headers.
 *  Child from TableHead will inherit a "scope" that we don't want. (not possible to turn off)
 *  We are using "headers" attribute because the table is too complexe to use "scope".
 */

const useStyles = makeStyles(theme => ({
  table: {
    borderCollapse: 'separate',
    borderLeft: `thin solid ${theme.palette.grey[500]}`,
    borderTop: `thin solid ${theme.palette.grey[500]}`,
    padding: '0px 0px',
  },
  stickyHeader: {
    position: 'sticky',
    top: 0,
    zIndex: 2,
  },
}));

const mapIndexed = R.addIndex(R.map);

export const getEnhancedValuesHeaderData = data => {
  const colLength = R.pipe(R.last, R.propOr([], 'data'), R.length)(data);
  return R.pipe(
    R.pluck('data'),
    // mix merge and transpose for performance
    R.reduce((acc, cells) => {
      R.addIndex(R.forEach)((_, rowIndex) => {
        const cell = R.nth(rowIndex, cells) || {};
        const cellId = R.has('values', cell)
          ? R.pipe(R.prop('values'), R.pluck('id'), R.join(','))(cell)
          : R.pathOr(`missing${rowIndex}`, ['value', 'id'], cell);
        const topCell = rowIndex > 0 ? R.pipe(R.nth(rowIndex - 1), R.last)(acc) : null;
        const cellKey = R.isNil(topCell) ? cellId : `${topCell.key}-${cellId}`;

        const leftCell = R.pipe(R.nth(rowIndex), R.last)(acc);
        if (R.prop('key', leftCell) === cellKey && rowIndex !== colLength - 1) {
          acc = R.over(
            R.lensIndex(rowIndex),
            R.over(R.lensIndex(-1), R.evolve({ spanCount: R.add(1) })),
          )(acc);
        } else {
          acc = R.over(
            R.lensIndex(rowIndex),
            R.append({
              ...R.pick(['value', 'values'], cell),
              key: cellKey,
              spanCount: 1,
              isEmpty: R.isEmpty(cell),
            }),
          )(acc);
        }
      })(acc);
      return acc;
    }, R.times(R.always([]), colLength)),
  )(data);
};

const getCellsWidth = R.addIndex(R.reduce)(
  (acc, element, index) => R.append(R.add(acc[index], R.propOr(0, 'offsetWidth', element)), acc),
  [0],
);

const TableHtml5 = ({
  cells,
  headerData = [],
  sectionsData = [],
  cellHandler,
  activeCellIds,
  activeCellHandler,
  SideIcon,
  testId = 'vis-table',
  HTMLRenderer,
  cellValueAccessor = R.identity,
  labelAccessor = defaultLabelAccessor,
  textAlign,
  isNoWrap = R.always(false),
}) => {
  const classes = useStyles();
  const [headerHeight, setHeaderHeight] = useState(0);
  const [subHeadCellsWidth, setSubHeadCellsWidth] = useState([0]);
  const headerRef = useRef(null);
  const subHeadCellsRef = useRef([]);

  useEffect(() => {
    setHeaderHeight(R.pathOr(0, ['current', 'clientHeight'], headerRef));
    setSubHeadCellsWidth(getCellsWidth(R.prop('current', subHeadCellsRef)));
  }, [cells, headerData, sectionsData]);

  useEffect(() => {
    let timeoutId = null;
    const resizeListener = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        setHeaderHeight(R.pathOr(0, ['current', 'clientHeight'], headerRef));
        setSubHeadCellsWidth(getCellsWidth(R.prop('current', subHeadCellsRef)));
      }, 150);
    };
    window.addEventListener('resize', resizeListener);
    return () => window.removeEventListener('resize', resizeListener);
  }, []);

  const xLayoutData = R.pathOr([], [0, 1, -1, 'data'], sectionsData);
  const xLayoutDataLength = R.length(xLayoutData);
  const { valuesHeaderData, nbColumnsByRows } = useMemo(() => {
    const valuesHeaderData = getEnhancedValuesHeaderData(headerData);
    const nbColumnsByRows = R.map(R.length)(valuesHeaderData);
    return { valuesHeaderData, nbColumnsByRows };
  }, [headerData]);

  const isSingleObs = R.hasPath(['', '', '', 0], cells);
  if (isSingleObs)
    return (
      <Cell
        HTMLRenderer={HTMLRenderer}
        labelAccessor={labelAccessor}
        {...R.pathOr({}, ['', '', '', 0], cells)}
        cellHandler={cellHandler}
        isOne
        textAlign={textAlign}
      />
    );

  return (
    <Table className={classes.table} aria-label="table" size="small" data-testid={testId}>
      <thead ref={headerRef} className={classes.stickyHeader}>
        <Header
          headerData={headerData}
          valuesHeaderData={valuesHeaderData}
          xLayoutDataLength={xLayoutDataLength}
          subHeadCellsWidth={subHeadCellsWidth}
          HTMLRenderer={HTMLRenderer}
          labelAccessor={labelAccessor}
        />
        <SubHeader
          headerData={headerData}
          xLayoutData={xLayoutData}
          activeCellIds={activeCellIds}
          SideIcon={SideIcon}
          ref={subHeadCellsRef}
          subHeadCellsWidth={subHeadCellsWidth}
          HTMLRenderer={HTMLRenderer}
          labelAccessor={labelAccessor}
        />
      </thead>
      {mapIndexed(
        (section, index) => (
          <Section
            key={`section-${index}`}
            sectionIndex={index}
            headerHeight={headerHeight}
            nbColumnsByRows={nbColumnsByRows}
            headerData={headerData}
            cells={cells}
            section={section}
            xLayoutDataLength={xLayoutDataLength}
            cellHandler={cellHandler}
            activeCellIds={activeCellIds}
            activeCellHandler={activeCellHandler}
            SideIcon={SideIcon}
            subHeadCellsWidth={subHeadCellsWidth}
            HTMLRenderer={HTMLRenderer}
            cellValueAccessor={cellValueAccessor}
            labelAccessor={labelAccessor}
            isNoWrap={isNoWrap}
            textAlign={textAlign}
          />
        ),
        sectionsData,
      )}
    </Table>
  );
};

TableHtml5.propTypes = {
  cells: PropTypes.object,
  headerData: PropTypes.array,
  sectionsData: PropTypes.array,
  cellHandler: PropTypes.func,
  activeCellHandler: PropTypes.func,
  activeCellIds: PropTypes.object,
  SideIcon: PropTypes.func,
  testId: PropTypes.string,
  HTMLRenderer: PropTypes.func,
  labelAccessor: PropTypes.func,
  cellValueAccessor: PropTypes.func,
  isNoWrap: PropTypes.func,
  textAlign: PropTypes.string,
};

export default TableHtml5;
