/**
 * Contact component is a popup for users to ask and give feedback.
 * isSubmitDisable could be uncontrolled if the value is undefined
 *
 * @memberOf VISIONS
 * @name Contact
 * @tag component
 * @api public
 * @props
 * Contact.propTypes = {
 *   defaultMode: PropTypes.string,
 *   defaultFullName: PropTypes.string,
 *   defaultEmail: PropTypes.string,
 *   lang: PropTypes.string,
 *   theme: PropTypes.string,
 *   modes: PropTypes.array,
 *   send: PropTypes.func,
 *   isSubmitDisable: PropTypes.bool,
 *   children: PropTypes.node,
 *   labels: PropTypes.shape({
 *     name: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
 *     organisation: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
 *     personalTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
 *     title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
 *     submit: PropTypes.string,
 *     email: PropTypes.string,
 *     details: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
 *     contactForHelp: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
 *   }),
 * };
 * @theme
// no custom theme there
 * @demoReady
 */

export { default } from './Contact';
