import { innerPalette  } from "../../src/theme"

export default [
  {
    id: 'outerPalette',
    children: [
      {
        id: 'highlight1',
        color: innerPalette.highlight1,
        children: [
          {
            id: 'Components',
            children: [
              { id: 'DataHeader' },
              { id: 'Flags' },
              { id: 'Button' },
              { id: 'Spotlight' },
              { id: 'ToggleButton' },
            ]
          },
        ]
      },
      {
        id: 'highlight2',
        color: innerPalette.highlight2,
        children: [
          {
            id: 'Components',
            children: [
              { id: 'Dataflow' },
              { id: 'Tag' }
            ]
          },
        ]
      },
      {
        id: 'highlight3',
        color: innerPalette.highlight3,
        children: [
          {
            id: 'Components',
            children: [
              { id: 'Dataflow' },
            ]
          },
        ]
      },
      {
        id: 'primaryMain',
        color: innerPalette.primaryMain,
        children: [
          {
            id: 'Components',
            children: [
              { id: 'Select' },
              { id: 'TableLayout' },
              { id: 'CollapseButtons' },
              { id: 'Dataflow' },
              { id: 'DataFooter' },
              { id: 'Table' },
              { id: 'TableHtml5' },
              { id: 'Breadcrumbs' },
              { id: 'ExpansionPanel' },
              { id: 'TablePreview' },
              { id: 'ToggleButton' },
              { id: 'ScopeList' },
            ]
          },
        ]
      },
      {
        id: 'hover',
        alphaColor: innerPalette.hover,
        children: [
          {
            id: 'Components',
            children: [
              { id: 'Button' },
              { id: 'ScopeList' },
            ]
          },
        ]
      },
      {
        id: 'selected',
        alphaColor: innerPalette.selected,
        children: [
          {
            id: 'Components',
            children: [
              { id: 'ApiQueries' },
              { id: 'Button' },
              { id: 'ScopeList' },
            ]
          },
        ]
      },
      {
        id: 'textLight',
        color: innerPalette.textLight,
        children: [
          {
            id: 'Components',
            children: [
              { id: 'CollapseButtons' },
              { id: 'Dataflow' },
              { id: 'ScopeList' },
              { id: 'TableHeader' },
              { id: 'Table' },
              { id: 'Tag' },
            ]
          },
        ]
      },
      {
        id: 'textDark',
        color: innerPalette.textDark,
        children: [
          {
            id: 'Components',
            children: [
              { id: 'TableHeader' },
              { id: 'SissFooter' },
              { id: 'Table' },
              { id: 'TableHtml5' },
              { id: 'ToggleButton' },
            ]
          },
        ]
      }
    ]
  },
];