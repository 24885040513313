import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import makeStylesFn from './styles';
import CustomChip from './CustomChip';

const useStyles = makeStyles(makeStylesFn);

const DeleteChip = ({ onDeleteAll, clearAllLabel, ariaLabel }) => {
  const classes = useStyles();
  return (
    <CustomChip
      label={clearAllLabel}
      ariaLabel={ariaLabel}
      chipClasses={cx(classes.mainChip, classes.chip)}
      onDelete={onDeleteAll}
      iconClasses={classes.icon}
      labelClasses={cx(classes.spaceLabel, classes.formatLabel)}
    />
  );
};

DeleteChip.propTypes = {
  onDeleteAll: PropTypes.func,
  ariaLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  clearAllLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

export default DeleteChip;
