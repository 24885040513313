import { makeStyles } from '@material-ui/core/styles';
import { getLight, getDark } from '../utils';

export const useStyles = makeStyles(theme => ({
  header: {
    backgroundColor: theme.palette.primary.main,
  },
  headerTitleCell: {
    color: theme.palette.getContrastText(theme.palette.primary.main),
    textAlign: 'right',
    fontStyle: 'italic',
    fontWeight: theme.typography.fontWeightBold,
  },
  headerCell: {
    color: theme.palette.getContrastText(theme.palette.primary.main),
    textAlign: 'center',
  },
  rows: {
    backgroundColor: getLight(theme) || theme.palette.secondary.light,
  },
  rowheaderCell: {
    color: theme.palette.getContrastText(getLight(theme) || theme.palette.secondary.light),
    padding: theme.spacing(0.5, 1),
    fontStyle: 'italic',
    fontWeight: theme.typography.fontWeightBold,
  },
  sections: {
    backgroundColor: getDark(theme) || theme.palette.secondary.dark,
    padding: theme.spacing(0.5, 1),
  },
  sectionsTitleCell: {
    color: theme.palette.getContrastText(getDark(theme) || theme.palette.secondary.dark),
    fontStyle: 'italic',
    fontSize: theme.typography.fontSize - 2,
    fontWeight: theme.typography.fontWeightBold,
  },
  sectionsCell: {
    color: theme.palette.getContrastText(getDark(theme) || theme.palette.secondary.dark),
  },
  cell: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    maxWidth: 10,
    padding: theme.spacing(0.5, 1),
    textOverflow: 'ellipsis',
    fontSize: theme.typography.fontSize - 2,
  },
  border: {
    border: 'solid 1px #a4a1a1',
    borderCollapse: 'collapse',
  },
  separatorValues: {
    backgroundColor: '#f0f0f0',
  },
  separatorSize: {
    width: 25,
  },
  emptyValues: {
    maxWidth: 0,
  },
  table: {
    width: '100%',
  },
  icon: {
    fontSize: 8,
    marginLeft: 7,
    marginRight: 7,
  },
}));
