import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Badge from '@material-ui/core/Badge';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Tooltip from '@material-ui/core/Tooltip';
import SearchIcon from '@material-ui/icons/Search';
import { getId } from '../utils';
import components from '../components';
import { getTagByType, getComponentById } from '../utils';

const useStyles = makeStyles(theme => ({
  spotlight: {
    position: 'fixed',
    backgroundColor: 'white',
    width: 240, // artisanal
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    marginTop: 64, // artisanal
    zIndex: 1,
  },
  list: {
    marginTop: 40, // artisanal
  },
  label: {
    display: 'flex',
    flexGrow: 1,
  },
  badge: {
    margin: theme.spacing(1),
    zIndex: 0,
  },
}));

const getComponent = id => getComponentById(id)(components);
const sideItems = R.pipe(
  R.map(item => {
    const id = getId(item);
    return {
      id,
      hasTestIdBadge: R.pipe(getTagByType('testId'), R.isNil)(getComponent(id)),
      hasDemoReady: R.pipe(getTagByType('demoReady'), R.isNil)(getComponent(id)),
    };
  }),
  R.sortBy(R.compose(R.toLower, R.prop('id'))),
)(components);

const MenuComponents = ({ componentId, setComponentId }) => {
  const classes = useStyles();
  const [term, setTerm] = useState('');
  const filteredItems = R.isEmpty(term)
    ? sideItems
    : R.filter(R.pipe(R.prop('id'), R.toLower, R.includes(R.toLower(term))), sideItems);
  return (
    <Fragment>
      <TextField
        value={term}
        onChange={e => setTerm(e.target.value)}
        className={classes.spotlight}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
      <List dense className={classes.list}>
        {R.map(({ id, hasTestIdBadge, hasDemoReady }) => (
          <ListItem
            button
            key={id}
            onClick={() => setComponentId(id)}
            selected={R.equals(componentId, id)}
            className={classes.label}
          >
            <ListItemText>
              <Typography variant="body2">{id}</Typography>
            </ListItemText>
            <Tooltip title={hasTestIdBadge ? 'Need testid API' : ''}>
              <Badge
                overlap="rectangular"
                classes={{ root: classes.badge }}
                color={hasTestIdBadge ? 'primary' : undefined}
                variant="dot"
              />
            </Tooltip>
            <Tooltip title={hasDemoReady ? 'Need new demo' : ''}>
              <Badge
                overlap="rectangular"
                classes={{ root: classes.badge }}
                color={hasDemoReady ? 'error' : undefined}
                variant="dot"
              />
            </Tooltip>
          </ListItem>
        ))(filteredItems)}
      </List>
    </Fragment>
  );
};

MenuComponents.propTypes = {
  componentId: PropTypes.string,
  setComponentId: PropTypes.func.isRequired,
};

export default MenuComponents;