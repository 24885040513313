import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import cx from 'classnames';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Chip from '@material-ui/core/Chip';
import Cross from '../Icons/Cross';
import { getIsRtl } from '../utils';
import Tooltip from '../Tooltip';
import makeStylesFn from './styles';
import {
  myTooltipRenderer,
  myTooltipRendererSrOnly,
  showMultiLinedTooltip,
  shownTooltip,
} from './utils';
import useKeyboardEscapeHandler from '../hooks/useKeybordEscapeHandler';

const useStyles = makeStyles(makeStylesFn);

const CustomChip = ({
  id,
  parentId,
  items = [],
  label,
  onDelete,
  renderer,
  chipClasses,
  iconClasses,
  labelClasses,
  ariaLabel = 'delete',
  testId = 'deleteChip-test-id',
  labelRenderer = R.prop('label'),
  ariaLabelTooltip,
  deprecated,
}) => {
  const theme = useTheme();
  const isRtl = getIsRtl(theme);
  const classes = useStyles();
  const mapIndexed = R.addIndex(R.map);
  const onChange = R.is(Function)(onDelete)
    ? () => onDelete(parentId, R.isNil(id) ? [] : R.append(id, deprecated || []))
    : null;
  const hasTooltip = shownTooltip(items);
  const hasMultiLinedTooltip = showMultiLinedTooltip(items);
  const multiLinedTooltipRenderer = arr =>
    mapIndexed((item, index) => (
      <div key={index}>
        {myTooltipRenderer({ isRtl, labelRenderer })(item)}
        <br />
      </div>
    ))(R.prop('parents')(R.head(arr)));

  const multiLinedTooltipRendererSrOnly = items =>
    R.join(',')(
      R.map(
        myTooltipRendererSrOnly({ isRtl, labelRenderer, ariaLabelTooltip }),
        R.propOr([], 'parents')(R.head(items)),
      ),
    );

  const { open, setOpen } = useKeyboardEscapeHandler();

  return (
    <Chip
      data-testid={testId}
      size="small"
      aria-label={label}
      tabIndex={R.isNil(onDelete) ? 0 : -1}
      label={
        hasTooltip || hasMultiLinedTooltip ? (
          <Tooltip
            placement="top"
            title={
              hasTooltip
                ? renderer(items)
                : hasMultiLinedTooltip
                ? multiLinedTooltipRenderer(items)
                : ''
            }
            tabIndex={0}
            aria-label={
              hasTooltip
                ? myTooltipRendererSrOnly({ isRtl, labelRenderer, ariaLabelTooltip })(items)
                : hasMultiLinedTooltip
                ? multiLinedTooltipRendererSrOnly(items)
                : null
            }
            aria-hidden={false}
            interactive
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            disableHoverListener={!hasTooltip && !hasMultiLinedTooltip}
            open={open}
          >
            <span>
              ...<span className={classes.childrenIndicator}>{'>'}</span>
              {label}
            </span>
          </Tooltip>
        ) : (
          label
        )
      }
      role="presentation"
      deleteIcon={
        <IconButton
          disableRipple
          className={iconClasses}
          aria-label={`${ariaLabel} ${label}`}
          component="span"
          tabIndex={0}
        >
          <Cross />
        </IconButton>
      }
      clickable
      onDelete={onChange}
      className={cx(chipClasses, {
        [classes.tooltipIndicator]: hasTooltip || hasMultiLinedTooltip,
      })}
      classes={{ label: labelClasses }}
    />
  );
};

CustomChip.propTypes = {
  testId: PropTypes.string,
  id: PropTypes.string,
  parentId: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  onDelete: PropTypes.func,
  renderer: PropTypes.func,
  chipClasses: PropTypes.string,
  iconClasses: PropTypes.string,
  labelClasses: PropTypes.string,
  ariaLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  items: PropTypes.array,
  labelRenderer: PropTypes.func,
  deprecated: PropTypes.array,
  ariaLabelTooltip: PropTypes.string,
};

export default CustomChip;
