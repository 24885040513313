export const innerPalette = {
  highlight1: '#f7a42c',
  highlight2: '#8CC841',
  highlight3: '#fffc02',
  primaryMain: '#0549ab',
  primaryLight: '#007bc7',
  primaryDark: '#0549ab',
  hover: 'rgba(14, 144, 224, 0.13)',
  selected: 'rgba(14, 144, 224, 0.25)',
  textLight: '#444444',
  textDark: '#182026',
};

export const sisccTheme = ({ rtl, isA11y, outerPalette = {} }) => {
  const focus = {
    outlineColor: outerPalette.highlight1 || innerPalette.highlight1,
  };

  const buttonBaseFocus = {
    borderColor: outerPalette.highlight1 || innerPalette.highlight1,
    '&:focus': {
      outlineColor: outerPalette.highlight1 || innerPalette.highlight1,
      outlineWidth: 1,
      outlineStyle: 'auto',
    },
  };

  const spotlightFocus = {
    outlineColor: outerPalette.highlight1 || innerPalette.highlight1,
    outlineStyle: 'auto',
    outlineWidth: 1,
  };

  return {
    direction: rtl,
    props: {
      MuiButtonBase: {
        disableRipple: true,
      },
      MuiUseMediaQuery: {
        noSsr: true,
      },
    },
    overrides: {
      MuiCheckbox: {
        root: {
          color: outerPalette.primaryMain || innerPalette.primaryMain,
        },
        colorSecondary: {
          '&$checked': {
            color: outerPalette.primaryMain || innerPalette.primaryMain,
          },
        },
      },
      MuiTableCell: {
        head: {
          lineHeight: 1.43,
        },
        sizeSmall: {
          '&:last-child': {
            paddingRight: 9,
          },
        },
      },
      MuiButtonBase: {
        root: isA11y && buttonBaseFocus,
      },
      MuiAccordion: {
        root: {
          '&$expanded': {
            margin: 0,
          },
        },
      },
      MuiButton: {
        root: {
          textTransform: 'none',
        },
        textPrimary: {
          '&:hover': {
            backgroundColor: `${outerPalette.hover || innerPalette.hover}`,
          },
        },
      },
      MuiMenuItem: {
        root: {
          color: outerPalette.primaryMain || innerPalette.primaryMain,
          '&$selected': {
            backgroundColor: outerPalette.hover || innerPalette.hover,
            '&:hover': {
              backgroundColor: outerPalette.selected || innerPalette.selected,
            },
          },
        },
      },
      MuiOutlinedInput: {
        root: {
          '&:focus': isA11y && focus,
        },
      },
      MuiTypography: {
        root: {
          '&:focus': isA11y && focus,
        },
      },
      MuiLink: {
        root: {
          color: outerPalette.primaryMain || innerPalette.primaryMain,
          '&:hover': {
            color: outerPalette.primaryLight || innerPalette.primaryLight,
          },
          '&:focus': isA11y && focus,
        },
        button: {
          '&:focus': isA11y && focus,
        },
      },
      MuiAlert: {
        filledSuccess: {
          color: outerPalette.textDark || innerPalette.textDark,
        },
      },
      MuiInputBase: {
        root: {
          fontSize: '1rem',
        },
        input: {
          height: '1.25em',
        },
      },
      MuiFormLabel: {
        root: {
          fontSize: '1rem',
        },
      },
    },
    palette: {
      background: {
        default: '#ffffff',
      },
      action: {
        selected: outerPalette.selected || innerPalette.selected,
        hover: outerPalette.hover || innerPalette.hover,
      },
      primary: {
        main: outerPalette.primaryMain || innerPalette.primaryMain,
        light: outerPalette.primaryLight || innerPalette.primaryLight,
        dark: outerPalette.primaryDark || innerPalette.primaryDark,
      },
      secondary: {
        main: '#e3e9ed',
        light: '#f5f8fa',
        dark: '#ebf1f5',
      },
      tertiary: {
        main: '#0965c1',
        light: '#e2f2fb',
        dark: '#b7def6',
      },
      highlight: {
        hl1: outerPalette.highlight1 || innerPalette.highlight1,
        hl2: outerPalette.highlight2 || innerPalette.highlight2,
        hl3: outerPalette.highlight3 || innerPalette.highlight3,
      },
      grey: {
        100: '#f1f1f1',
        200: '#F3F7FB',
        300: '#cccccc',
        600: '#7A7A7A',
        700: '#666666',
        800: outerPalette.textLight || innerPalette.textLight,
        A700: outerPalette.textDark || innerPalette.textDark,
      },
      text: {
        primary: outerPalette.textDark || innerPalette.textDark,
        secondary: outerPalette.textLight || innerPalette.textLight,
      },
      error: {
        main: '#b31010',
      },
      warning: {
        main: '#f7a42c',
      },
    },
    mixins: {
      apiQueries: {
        title: {
          fontWeight: 400,
          fontFamily: "'Roboto Slab', serif",
        },
      },
      chart: {
        main: {
          fontFamily: "'Segoe UI'",
          fontSize: 12,
        },
        annotation: { fontSize: 11 },
        axis: { color: '#575757' },
        axisLegend: { color: '#7A7A7A' },
        legend: { fontSize: 14 },
        mapLegend: { color: '#575757', fontSize: 10 },
        tooltip: {
          primary: {},
          secondary: { fontSize: 16, fontWeight: 'bold' },
        },
      },
      chartsConfig: {
        root: {
          fontWeight: 400,
          fontFamily: "'Roboto Slab', serif",
        },
      },
      collapsibleTree: {
        title: {
          fontSize: 17,
          fontFamily: "'PT Sans Narrow', 'Helvetica Neue', Helvetica, Arial, sans-serif",
          color: outerPalette.textLight || innerPalette.textLight,
        },
        buttonSublevel: {
          color: outerPalette.textLight || innerPalette.textLight,
          fontSize: 14,
          fontWeight: 700,
          fontFamily: "'PT Sans Narrow', 'Helvetica Neue', Helvetica, Arial, sans-serif",
        },
      },
      dataEdit: {
        root: {
          fontWeight: 400,
          fontFamily: "'Roboto Slab', serif",
        },
      },
      dataflow: {
        title: {
          fontWeight: 400,
          fontFamily: "'Roboto Slab', serif",
          fontSize: 17,
        },
        desc: {
          mutedText: {
            color: '#666666',
          },
          unmutedText: {
            color: outerPalette.textLight || innerPalette.textLight,
          },
        },
      },
      dataHeader: {
        root: {
          fontFamily: "'Helvetica Neue', Helvetica, Arial, sans-serif",
        },
        title: {
          fontFamily: "'Roboto Slab', serif",
          fontSize: 18,
          fontWeight: 400,
          color: '#494444',
        },
        icon: {
          color: '#494444',
        },
        disclaimer: {
          backgroundColor: outerPalette.highlight1 || innerPalette.highlight1,
        },
        subtitle: {
          color: '#494444',
          fontWeight: 'normal',
        },
        uprs: {},
      },
      expansionPanel: {
        title: {
          fontWeight: 700,
          fontFamily: "'PT Sans Narrow', 'Helvetica Neue', Helvetica, Arial, sans-serif",
          fontSize: 15,
        },
      },
      labelDivider: {
        root: {
          fontSize: 13,
        },
      },
      share: {
        title: {
          fontWeight: 400,
          fontFamily: "'Roboto Slab', serif",
          fontSize: 18,
        },
      },
      spotlight: {
        focus: isA11y && spotlightFocus,
      },
      table: {
        flag: {
          fontSize: 10,
          color: outerPalette.textLight || innerPalette.textLight,
        },
        dot: {
          fontSize: 8,
        },
        cellHighlight: {
          backgroundColor: '#FEF4E6',
        },
      },
      tag: {
        root: {
          fontSize: 12,
          fontFamily: "'PT Sans Narrow', 'Helvetica Neue', Helvetica, Arial, sans-serif",
          color: outerPalette.textLight || innerPalette.textLight,
        },
      },
      toggleButton: {
        selected: {
          color: outerPalette.textDark || innerPalette.textDark,
        },
        hover: {
          color: outerPalette.textDark || innerPalette.textDark,
        },
      },
    },
    typography: {
      fontFamily: "'Helvetica Neue', Helvetica, Arial, sans-serif",
      h6: {
        fontSize: ' 1.0625rem',
      },
      body1: {
        fontSize: '0.750rem',
        lineHeight: 1.43,
      },
      body2: {
        fontSize: '0.875rem',
      },
    },
  };
};

export const T4_BREAKPOINTS = {
  xs: 120,
  xs2: 250,
  xs3: 270,
  sm: 370,
  md: 420,
  md2: 560,
  lg: 760,
  xl: 855,
};
