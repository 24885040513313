import React, { useState } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Input from './Input';
import Button from '../Button';
import Select from '../Select';
import PermsissionsTabs from './PermsissionsTabs';
import { Mode } from '..';

const useStyles = makeStyles(theme => ({
  paper: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2),
  },
  label: {
    color: theme.palette.primary.main,
  },
  textarea: {
    width: '100%',
    padding: theme.spacing(1),
  },
  submitButton: {
    margin: theme.spacing(1),
  },
  contentLabel: {
    fontSize: '14px',
  },
}));

const UserRightForm = ({
  labels,
  artefactTypes,
  dataSpaces,
  permissionTabs,
  onSubmit,
  onCancel,
  user,
  artefactID,
  artefactType,
  artefactVersion,
  maintenanceAgencyID,
  dataspace,
  mode,
  modes,
  onChange,
}) => {
  const classes = useStyles();
  const [userValue, setUserValue] = useState(R.propOr('*', 'value')(user));
  const [dataspaceValue, setDataspaceValue] = useState(R.propOr('*', 'value')(dataspace));
  const [artefactTypeValue, setArtefactTypeValue] = useState(R.propOr('*', 'value')(artefactType));
  const [maintenanceAgencyIDValue, setMaintenanceAgencyIDValue] = useState(
    R.propOr('*', 'value')(maintenanceAgencyID),
  );
  const [artefactIDValue, setArtefactIDValue] = useState(R.propOr('*', 'value')(artefactID));
  const [artefactVersionValue, setArtefactVersionValue] = useState(
    R.propOr('*', 'value')(artefactVersion),
  );

  const initialSelectedPermissions = R.pipe(
    R.pathOr([], ['options', 'data']),
    R.filter(R.prop('isSelected')),
    R.reduce((acc, { id }) => R.assoc(id, id, acc), {}),
  )(permissionTabs);
  const [selectedPermissions, setSelectedPermissions] = useState(initialSelectedPermissions);
  const onCheckPermission = id => {
    if (R.has(id, selectedPermissions)) {
      setSelectedPermissions(R.dissoc(id, selectedPermissions));
    } else {
      setSelectedPermissions(R.assoc(id, id, selectedPermissions));
    }
  };
  const permissionsIdsIndexedByGroups = R.reduce(
    (acc, group) => R.assoc(group.id, group.options, acc),
    {},
    R.pathOr([], ['permissions', 'data'], permissionTabs),
  );
  const selectedPermissionsGroups = R.pipe(
    R.pathOr([], ['permissions', 'data']),
    R.filter(
      group => group.options && R.all(id => R.has(id, selectedPermissions), group.options || []),
    ),
    R.reduce((acc, { id }) => R.assoc(id, id, acc), {}),
  )(permissionTabs);

  const onCheckPermissionGroup = id => {
    if (R.has(id, selectedPermissionsGroups)) {
      setSelectedPermissions(R.omit(permissionsIdsIndexedByGroups[id], selectedPermissions));
    } else {
      setSelectedPermissions({
        ...selectedPermissions,
        ...R.indexBy(R.identity, permissionsIdsIndexedByGroups[id]),
      });
    }
  };
  const evolvedPermissions = R.pipe(
    R.over(
      R.lensPath(['options', 'data']),
      R.map(opt =>
        R.pipe(
          R.assoc('isSelected', R.has(opt.id, selectedPermissions)),
          R.assoc('disabled', !R.is(Function, onSubmit)),
        )(opt),
      ),
    ),
    R.over(
      R.lensPath(['permissions', 'data']),
      R.map(opt =>
        R.pipe(
          R.assoc('isSelected', R.has(opt.id, selectedPermissionsGroups)),
          R.assoc('disabled', !R.is(Function, onSubmit)),
        )(opt),
      ),
    ),
  )(permissionTabs);

  const onChangeDataspace = value => {
    return setDataspaceValue(value);
  };
  const onChangeArtefactType = value => {
    return setArtefactTypeValue(value);
  };

  const handleCancel = () => {
    if (R.is(Function)(onCancel)) return onCancel();
  };
  const handleSubmit = () => {
    if (R.is(Function)(onSubmit))
      return onSubmit({
        user: userValue,
        dataspace: dataspaceValue,
        artefactType: artefactTypeValue,
        maintenanceAgencyID: maintenanceAgencyIDValue,
        artefactID: artefactIDValue,
        artefactVersion: artefactVersionValue,
        checkedPermissions: R.values(selectedPermissions),
        isGroup: R.equals(mode, 'group'),
      });
  };

  const isErrorDataspace = R.pipe(R.find(R.propEq('value', dataspaceValue)), R.isNil)(dataSpaces);
  const isErrorArtefactType = R.pipe(
    R.find(R.propEq('value', artefactTypeValue)),
    R.isNil,
  )(artefactTypes);
  const isValidPermissions = !R.isEmpty(selectedPermissions);
  const isValidFields = R.all(
    value => !R.isEmpty(value) && !R.isNil(value),
    [
      userValue,
      maintenanceAgencyIDValue,
      artefactIDValue,
      artefactVersionValue,
      dataspaceValue,
      artefactTypeValue,
    ],
  );

  const hasPermissionsSelectionChanged = !R.equals(selectedPermissions, initialSelectedPermissions);
  const hasFieldsChanged = !R.all(
    ([state, initial]) => R.equals(state, initial),
    [
      [userValue, R.propOr('', 'value', user)],
      [maintenanceAgencyIDValue, R.propOr('', 'value', maintenanceAgencyID)],
      [artefactIDValue, R.propOr('', 'value', artefactID)],
      [artefactVersionValue, R.propOr('', 'value', artefactVersion)],
      [dataspaceValue, R.propOr('', 'value', dataspace)],
      [artefactTypeValue, R.propOr('', 'value', artefactType)],
    ],
  );
  const isValid =
    isValidPermissions && isValidFields && (hasPermissionsSelectionChanged || hasFieldsChanged);

  return (
    <Grid container className={classes.paper} data-testid="permission-test-id">
      <Grid item container xs={12} md={12} justifyContent="space-between">
        <Grid item container xs={12} sm={12} md={6} flex-direction="column">
          <Grid item container xs={12} alignItems="center">
            <Grid item xs={12} md={5} style={{ marginTop: '4px' }}>
              <Mode modes={modes} mode={mode} changeMode={onChange} isSmall />
            </Grid>
            <Grid item xs={12} md={7}>
              <Input
                variant="outlined"
                name="User"
                type="text"
                fullWidth
                value={userValue}
                onChange={e => setUserValue(e.target.value)}
                isControlled
                textFieldProps={{ disabled: R.propOr(false, 'disabled')(user) }}
              />
            </Grid>
          </Grid>
          <Grid item container xs={12} alignItems="center">
            <Grid item xs={12} md={5}>
              <Typography variant="h6" className={classes.contentLabel} style={{ paddingTop: 8 }}>
                {R.prop('dataspace')(labels)}
              </Typography>
            </Grid>
            <Grid item xs={12} md={7}>
              <Select
                value={R.defaultTo('*', dataspaceValue)}
                items={dataSpaces}
                onChange={onChangeDataspace}
                textFieldProps={{
                  disabled: R.propOr(false, 'disabled')(dataspace),
                  error: isErrorDataspace,
                }}
              />
            </Grid>
          </Grid>
          <Grid item container xs={12} alignItems="center">
            <Grid item xs={12} md={5}>
              <Typography variant="h6" className={classes.contentLabel} style={{ paddingTop: 8 }}>
                {R.prop('artefactType')(labels)}
              </Typography>
            </Grid>
            <Grid item xs={12} md={7}>
              <Select
                value={R.defaultTo('*', artefactTypeValue)}
                items={artefactTypes}
                onChange={onChangeArtefactType}
                textFieldProps={{
                  disabled: R.propOr(false, 'disabled')(artefactType),
                  error: isErrorArtefactType,
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item container xs={12} sm={12} md={5} flex-direction="column">
          <Grid item container xs={12} alignItems="center">
            <Grid item xs={12} md={4}>
              <Typography variant="h6" className={classes.contentLabel} style={{ paddingTop: 8 }}>
                {R.prop('maintenanceAgencyID')(labels)}
              </Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <Input
                variant="outlined"
                name="title"
                type="text"
                fullWidth
                value={maintenanceAgencyIDValue}
                onChange={e => setMaintenanceAgencyIDValue(e.target.value)}
                isControlled
                textFieldProps={{
                  disabled: R.propOr(false, 'disabled')(maintenanceAgencyID),
                }}
              />
            </Grid>
          </Grid>
          <Grid item container xs={12} alignItems="center">
            <Grid item xs={12} md={4}>
              <Typography variant="h6" className={classes.contentLabel} style={{ paddingTop: 8 }}>
                {R.prop('artefactID')(labels)}
              </Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <Input
                variant="outlined"
                name="title"
                type="text"
                fullWidth
                value={artefactIDValue}
                onChange={e => setArtefactIDValue(e.target.value)}
                isControlled
                textFieldProps={{ disabled: R.propOr(false, 'disabled')(artefactID) }}
              />
            </Grid>
          </Grid>
          <Grid item container xs={12} alignItems="center">
            <Grid item xs={12} md={4}>
              <Typography variant="h6" className={classes.contentLabel} style={{ paddingTop: 8 }}>
                {R.prop('artefactVersion')(labels)}
              </Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <Input
                variant="outlined"
                name="title"
                type="text"
                fullWidth
                value={artefactVersionValue}
                onChange={e => setArtefactVersionValue(e.target.value)}
                isControlled
                textFieldProps={{
                  disabled: R.propOr(false, 'disabled')(artefactVersion),
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item container>
        <PermsissionsTabs
          data={evolvedPermissions}
          labels={labels}
          onCheckPermission={onCheckPermission}
          onCheckPermissionGroup={onCheckPermissionGroup}
        />
      </Grid>
      <Grid item container justifyContent="flex-end" alignItems="center">
        <Grid item>
          <Button
            aria-label={R.prop('cancel')(labels)}
            type="submit"
            disabled={false}
            variant="contained"
            color="default"
            alternative="siscc"
            onClick={handleCancel}
            size="medium"
            className={classes.submitButton}
          >
            {R.prop('cancel')(labels)}
          </Button>
        </Grid>
        {R.is(Function, onSubmit) && (
          <Grid item>
            <Button
              aria-label={R.prop('submit')(labels)}
              type="submit"
              disabled={!isValid || isErrorArtefactType || isErrorDataspace}
              variant="contained"
              color="primary"
              alternative="siscc"
              onClick={handleSubmit}
              size="medium"
              className={classes.submitButton}
            >
              {R.prop('submit')(labels)}
            </Button>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};
UserRightForm.propTypes = {
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  artefactTypes: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
  dataSpaces: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
  permissionTabs: PropTypes.object,
  user: PropTypes.shape({
    value: PropTypes.string,
    disabled: PropTypes.bool,
  }),
  dataspace: PropTypes.shape({
    value: PropTypes.string,
    disabled: PropTypes.bool,
  }),
  artefactType: PropTypes.shape({
    value: PropTypes.string,
    disabled: PropTypes.bool,
  }),
  maintenanceAgencyID: PropTypes.shape({
    value: PropTypes.string,
    disabled: PropTypes.bool,
  }),
  artefactID: PropTypes.shape({
    value: PropTypes.string,
    disabled: PropTypes.bool,
  }),
  artefactVersion: PropTypes.shape({
    value: PropTypes.string,
    disabled: PropTypes.bool,
  }),
  labels: PropTypes.shape({
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    user: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    dataspace: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    artefactType: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    maintenanceAgencyID: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    artefactID: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    artefactVersion: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    group: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  }),
  mode: PropTypes.string,
  modes: PropTypes.array,
  onChange: PropTypes.func,
};
export default UserRightForm;
