import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Input from './Input';
import { withBlank } from '../utils';

const DataEdit = ({ labels, properties }) => {
  const { title = {}, subtitle = {}, source = {}, logo = {}, copyright = {} } = properties;
  const resetLabel = R.prop('reset', labels);

  return (
    <Grid data-testid="data-edit-test-id" container spacing={1}>
      <Grid item xs={12}>
        <Typography variant="h6">{'Information'}</Typography>
      </Grid>
      {R.prop('isActive', title) && (
        <Grid item xs={12} style={{ minWidth: 220 }}>
          <Input
            fullWidth
            label={R.prop('title', labels)}
            resetLabel={resetLabel}
            {...R.dissoc('isDefault', title)}
          />
        </Grid>
      )}
      {R.prop('isActive', subtitle) && (
        <Grid item xs={12} style={{ minWidth: 220 }}>
          <Input
            fullWidth
            label={R.prop('subtitle', labels)}
            resetLabel={resetLabel}
            {...R.dissoc('isDefault', subtitle)}
          />
        </Grid>
      )}
      {R.prop('isActive', source) && (
        <Grid item xs={12} style={{ minWidth: 220 }}>
          <Input
            fullWidth
            label={R.prop('source', labels)}
            resetLabel={resetLabel}
            {...R.dissoc('isDefault', source)}
          />
        </Grid>
      )}
      <Grid item container xs={12} justifyContent="space-around">
        {R.map(item => {
          if (R.pipe(R.prop('isActive'), R.not)(item)) return;
          const id = R.prop('id')(item);
          return (
            <Grid item key={id}>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    inputProps={{ 'aria-label': R.prop(id)(labels) }}
                    {...R.omit(['isActive'])(item)}
                  />
                }
                label={R.prop(id)(labels)}
              />
            </Grid>
          );
        })([logo, copyright])}
      </Grid>
    </Grid>
  );
};

DataEdit.propTypes = {
  labels: PropTypes.shape({
    title: PropTypes.string,
    subtitle: PropTypes.string,
    source: PropTypes.string,
    logo: PropTypes.string,
    copyright: PropTypes.string,
    reset: PropTypes.string,
  }),
  properties: PropTypes.shape({
    title: PropTypes.shape({
      id: PropTypes.string,
      isActive: PropTypes.bool,
      onSubmit: PropTypes.func,
      onReset: PropTypes.func,
      value: PropTypes.string,
    }),
    subtitle: PropTypes.shape({
      id: PropTypes.string,
      isActive: PropTypes.bool,
      onSubmit: PropTypes.func,
      onReset: PropTypes.func,
      value: PropTypes.string,
    }),
    source: PropTypes.shape({
      id: PropTypes.string,
      isActive: PropTypes.bool,
      onSubmit: PropTypes.func,
      onReset: PropTypes.func,
      value: PropTypes.string,
    }),
    logo: PropTypes.shape({
      id: PropTypes.string,
      isActive: PropTypes.bool,
      onChange: PropTypes.func,
      checked: PropTypes.bool,
    }),
    copyright: PropTypes.shape({
      id: PropTypes.string,
      isActive: PropTypes.bool,
      onChange: PropTypes.func,
      checked: PropTypes.bool,
    }),
  }),
};

const isAllInactive = R.pipe(R.pluck('isActive'), R.all(R.identity), R.not);

export default withBlank(({ isBlank, properties = {} }) => ({
  isBlank: R.any(R.identity)([isBlank, R.isEmpty(properties), isAllInactive(properties)]),
}))(DataEdit);
