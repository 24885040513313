import React from 'react';
import { Prism } from 'react-syntax-highlighter';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  rtl: {
    direction: 'initial',
  },
}));

export const SyntaxHighlighter = ({ children, ...props }) => {
  const classes = useStyles();
  return (
    <Prism
      className={classes.rtl}
      customStyle={{ paddingLeft: 24, paddingRight: 24, margin: 0, borderRadius: 0 }}
      {...props}
    >
      {children}
    </Prism>
  );
};
