import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Error from '@material-ui/icons/Error';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const NO_RESULT_MESSAGE_ID = 'noResultMessage';

const NoData = ({ icon, message }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Typography variant="subtitle1" noWrap align="center">
        {icon || <Error color="primary" fontSize="large" />}
      </Typography>
      <Typography variant="subtitle1" noWrap align="center" tabIndex="0" id={NO_RESULT_MESSAGE_ID}>
        {message}
      </Typography>
    </div>
  );
};

NoData.propTypes = {
  icon: PropTypes.element,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

export default NoData;
