import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { makeStyles } from '@material-ui/core/styles';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import cx from 'classnames';

const useStyles = makeStyles(theme => ({
  action: {
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  disabled: {
    cursor: 'not-allowed',
    color: theme.palette.text.disabled,
    '&:hover': {
      color: theme.palette.text.disabled,
    },
  },
}));

const CustomBreadcrumbs = ({ items = [], isRtl }) => {
  const classes = useStyles();

  return (
    <Breadcrumbs
      separator={
        isRtl ? <NavigateBeforeIcon fontSize="small" /> : <NavigateNextIcon fontSize="small" />
      }
      aria-label="breadcrumb"
    >
      {R.addIndex(R.map)(({ text, action, isDisabled }, index) => (
        <Typography
          key={`${text}-${index}`}
          color="textPrimary"
          onClick={action}
          variant="subtitle1"
          className={cx({
            [classes.action]: R.is(Function)(action),
            [classes.disabled]: isDisabled,
          })}
        >
          {text}
        </Typography>
      ))(items)}
    </Breadcrumbs>
  );
};

CustomBreadcrumbs.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      action: PropTypes.func,
      isDisabled: PropTypes.bool,
    }),
  ),
  isRtl: PropTypes.bool,
};

export default CustomBreadcrumbs;
