import { makeStyles } from '@material-ui/core/styles';
export const height = 30;

export const useStyles = makeStyles(theme => ({
  middle: {
    margin: 'auto 0',
  },
  selectionContainer: {
    margin: theme.spacing(1),
  },
  containerHeader: {
    borderBottom: `6px solid ${theme.palette.grey[100]}`,
    marginBottom: '1px',
  },
  listItemContainer: {
    height,
    paddingRight: 36,
    outline: 'none',
    marginBottom: '1px',
  },
  listItem: {
    height,
    display: 'flex',
    flexDirection: 'column-reverse',
    justifyContent: 'center',
    margin: 0,
    userSelect: 'none',
    cursor: 'pointer',
    color: theme.palette.primary.main,
  },
  listItemHover: {
    '&:hover': {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.action.hover,
    },
  },
  accessibilityItemHover: {
    '&:focus': {
      backgroundColor: theme.palette.action.selected,
    },
  },
  listItemSelected: {
    backgroundColor: theme.palette.action.hover,
    '&:hover': {
      backgroundColor: theme.palette.action.selected,
    },
  },
  disableSecondaryAction: {
    paddingRight: '0 !important',
  },
  spaceHeader: {
    margin: theme.spacing(1, 0),
    lineHeight: 1,
  },
  spotlightContainer: {
    display: 'flex',
  },
  labelContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'baseline',
  },
  rtlLabelContainer: {
    flexDirection: 'row-reverse',
  },
  count: {
    color: theme.palette.grey[600],
    margin: theme.spacing(0, 0.25),
    fontSize: 11,
  },
  path: {
    color: theme.palette.grey[600],
    marginLeft: theme.spacing(1),
    fontSize: 11,
  },
  label: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginLeft: theme.spacing(1),
  },
  labelIcon: {
    fontSize: 16,
    marginLeft: theme.spacing(0.5),
    alignSelf: 'center',
  },
  disabled: {
    pointerEvents: 'initial !important',
  },
  disabledLabel: {
    color: theme.palette.grey[700],
  },
  ellipsis: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  svg: {
    verticalAlign: 'bottom',
    fontSize: '1.25rem',
  },
  arrowHeader: {
    color: theme.palette.grey[800],
  },
  headerItem: {
    display: 'inline',
    cursor: 'pointer',
    userSelect: 'none',
    height: 'unset',
    minHeight: 25,
    padding: theme.spacing(0.25, 0.5),
    borderBottom: 'dotted 1px',
    verticalAlign: 'text-bottom',
    '&:hover, &:focus': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  containerArrow: {
    paddingLeft: 4,
    borderLeft: `1px solid ${theme.palette.grey[800]}`,
    color: theme.palette.primary.main,
    height: '100%',
    display: 'flex',
    '&:hover, &:focus': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
  },
  arrow: {
    marginTop: 2,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  bulkSelectionIcon: {
    minWidth: 50,
  },
  tooltipNotice: {
    textDecoration: 'underline dotted',
  },
}));
