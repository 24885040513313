import * as R from 'ramda';

export const getTagByType = type => R.pipe(R.prop('tags'), R.find(R.propEq('type', type)));

export const getTagsByType = type => R.pipe(R.prop('tags'), R.filter(R.propEq('type', type)));

export const getName = R.pipe(R.path(['ctx', 'name']), R.split('_'), R.last);

export const getTagName = R.pipe(getTagByType('name'), R.prop('string'));

export const getId = R.ifElse(R.pipe(getTagName, R.isNil), getName, getTagName);

export const getComponentById = id => R.find(R.pipe(getId, R.equals(id)));

export const action = R.tap(console.log);
