import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import cx from 'classnames';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2.5),
    margin: theme.spacing(1.25),
    borderRadius: 'unset',
  },
  mobileView: {
    display: 'flex',
    flexDirection: 'column',
    columnCount: 'unset',
  },
  view: {
    columnCount: 3,
    columnFill: 'balance',
  },
}));

const CustomPaper = ({ isMobileView, children }) => {
  const classes = useStyles();
  return (
    <Paper
      classes={{
        root: cx(classes.paper, { [classes.mobileView]: isMobileView }),
      }}
    >
      <Grid className={cx(classes.view, { [classes.mobileView]: isMobileView })}>{children}</Grid>
    </Paper>
  );
};

CustomPaper.propTypes = {
  isMobileView: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

export default CustomPaper;
