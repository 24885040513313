import React, { useState, useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import * as R from 'ramda';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import App from './components/App';
import Helmet from './components/Helmet';
import themes from './themes';
import { Rtl } from './jss-provider';
import { create } from 'jss';
import rtl from 'jss-rtl';
import { jssPreset } from '@material-ui/styles';

if (module.hot) module.hot.accept();
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

const Root = () => {
  const [isRtl, setRtl] = useState(false);
  const [isA11y, setA11y] = useState(false);
  const [drawers, setDrawers] = React.useState({
    left: R.equals(R.nth(1, window.location.hash), 'o'),
    right: false,
  });
  const [componentId, setComponentId] = useState(R.tail(R.tail(window.location.hash)));
  const [themeId, setThemeId] = useState('siscc');

  const toggleDrawer = anchor => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawers((prevState) => ({ ...prevState, [anchor]: !R.prop(anchor, prevState) }));
  };

  useEffect(
    () => {
      window.location.hash = `#${drawers.left ? 'o' : 'x'}${componentId || ''}`;
    },
    [componentId, drawers.left],
  );

  const theme = themes[themeId]({ rtl: isRtl ? 'rtl' : 'ltr', isA11y });
  window.THEME = theme;

  return (
    <Rtl>
      <ThemeProvider theme={theme}>
        <Helmet isRtl={isRtl} componentId={componentId} />
        <CssBaseline />
        <App
          themeId={themeId}
          setThemeId={setThemeId}
          componentId={componentId}
          setComponentId={setComponentId}
          toggleRtl={() => setRtl(!isRtl)}
          toggleA11y={() => setA11y(!isA11y)}
          isRtl={isRtl}
          isA11y={isA11y}
          toggleDrawer={toggleDrawer}
          drawers={drawers}
        />
      </ThemeProvider>
    </Rtl>
  );
};

const container = document.getElementById('demo');
const root = createRoot(container);
root.render(<Root />);
