import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { makeStyles } from '@material-ui/core/styles';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import { SyntaxHighlighter } from '../components/SyntaxHighlighter';
import { okaidia } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { getTagByType, getComponentById } from '../utils';
import components from '../components';

const useStyles = makeStyles(theme => ({
  wrapper: {
    paddingBottom: theme.spacing(3),
  },
  card: {
    marginBottom: theme.spacing(3),
  },
  cardContent: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  details: {
    flexDirection: 'column',
    padding: 0,
  },
}));

const Entry = ({ componentId, children }) => {
  const classes = useStyles();
  const component = getComponentById(componentId)(components);
  const visibility = R.pipe(getTagByType('api'), R.propEq('visibility', 'private'))(component);
  const props = R.pipe(getTagByType('props'), R.prop('string'))(component);
  const theme = R.pipe(getTagByType('theme'), R.prop('string'))(component);
  const description = R.path(['description', 'full'], component);
  const componentCard = (
    <Card className={classes.card} square elevation={2}>
      <CardHeader
        title={componentId}
        subheader={<span dangerouslySetInnerHTML={{ __html: description }} />}
        avatar={visibility ? <LockIcon color="secondary" /> : <LockOpenIcon color="secondary" />}
      />
    </Card>
  );
  if (visibility) return componentCard;
  return (
    <div className={classes.wrapper}>
      {componentCard}
      {props && (
        <Accordion square elevation={2}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="body2">PropTypes</Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.details}>
            <SyntaxHighlighter language="javascript" style={okaidia}>
              {props}
            </SyntaxHighlighter>
          </AccordionDetails>
        </Accordion>
      )}
      {theme && (
        <Accordion square elevation={2}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="body2">Theme</Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.details}>
            <SyntaxHighlighter language="javascript" style={okaidia}>
              {theme}
            </SyntaxHighlighter>
          </AccordionDetails>
        </Accordion>
      )}
      {children}
    </div>
  );
};

Entry.propTypes = {
  componentId: PropTypes.string.isRequired,
  children: PropTypes.node,
};

export default Entry;
