export const ITEMS_TYPES = {
  DND: 'DND',
};

export const BOX = {
  header: 'header',
  sections: 'sections',
  rows: 'rows',
};

export const wcagId = 'dnd-how-drag-item';
