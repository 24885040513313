import React from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  hr: {
    display: 'flex',
    color: theme.palette.getContrastText(
      R.path(['palette', 'tertiary', 'main'], theme) || theme.palette.common.black,
    ),
    alignItems: 'center',
    textAlign: 'center',
    '&::before, &::after': {
      content: 'close-quote',
      flex: 1,
      borderTop: '.0625em solid',
      margin: '0.5em',
    },
    ...R.pathOr({}, ['mixins', 'labelDivider', 'root'], theme),
  },
  margin: {
    marginTop: theme.spacing(1.25),
    marginBottom: theme.spacing(1.25),
  },
}));

export const LabelDivider = ({ label, color, withMargin }) => {
  const classes = useStyles();
  return (
    <Typography className={cx(classes.hr, { [classes.margin]: withMargin })} style={{ color }}>
      {label}
    </Typography>
  );
};

LabelDivider.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  color: PropTypes.string,
  withMargin: PropTypes.bool,
};

export default LabelDivider;
