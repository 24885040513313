import React, { useState } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { makeStyles } from '@material-ui/core/styles';
import Badge from '@material-ui/core/Badge';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CodeIcon from '@material-ui/icons/Code';
import HomeIcon from '@material-ui/icons/Home';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import InvertColorsIcon from '@material-ui/icons/InvertColors';
import FormatTextdirectionLToRIcon from '@material-ui/icons/FormatTextdirectionLToR';
import FormatTextdirectionRToLIcon from '@material-ui/icons/FormatTextdirectionRToL';
import AccessibilityNewIcon from '@material-ui/icons/AccessibilityNew';
import AccessibilityIcon from '@material-ui/icons/Accessibility';
import ColorLensIcon from '@material-ui/icons/ColorLens';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import themes from '../themes';
import meta from '../../../package.json';
import components from '../components';

const useStyles = makeStyles(theme => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  grow: {
    flexGrow: 1,
  },
}));

export const Bar = ({
  themeId,
  setThemeId,
  setComponentId,
  toggleRtl,
  isRtl,
  toggleA11y,
  isA11y,
  toggleDrawer,
  drawers,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleTheme = key => {
    setThemeId(key);
    setAnchorEl(null);
  };

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar>
        <IconButton color="inherit" onClick={toggleDrawer('left')} edge="start">
          {drawers.left ? <CloseIcon /> : <MenuIcon />}
        </IconButton>
        <Typography variant="body2" color="inherit">
          <strong>{meta.name}</strong>
        </Typography>
        &nbsp;&nbsp;
        <img src="https://img.shields.io/npm/v/@sis-cc/dotstatsuite-visions?style=flat-square" />
        &nbsp;&nbsp;
        <img src="https://gitlab.com/sis-cc/.stat-suite/dotstatsuite-visions/badges/master/pipeline.svg?style=flat-square" />
        &nbsp;&nbsp;
        <img src="https://gitlab.com/sis-cc/.stat-suite/dotstatsuite-visions/badges/master/coverage.svg?style=flat-square" />
        &nbsp;&nbsp;
        <Typography variant="body2" color="inherit" className={classes.grow}>
          {components.length} components
        </Typography>
        <IconButton onClick={() => setComponentId()} color="inherit">
          <HomeIcon />
        </IconButton>
        <IconButton onClick={toggleDrawer('right')} color="inherit">
          <ColorLensIcon />
        </IconButton>
        <IconButton onClick={() => toggleRtl()} color="inherit">
          {isRtl ? <FormatTextdirectionLToRIcon /> : <FormatTextdirectionRToLIcon />}
        </IconButton>
        <IconButton onClick={e => setAnchorEl(e.currentTarget)} color="inherit">
          <InvertColorsIcon />
        </IconButton>
        <IconButton onClick={() => toggleA11y()}  color="inherit">
          { isA11y ? <AccessibilityNewIcon /> : <AccessibilityIcon />}
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
        >
          {R.map(
            key => (
              <MenuItem
                selected={R.equals(key, themeId)}
                key={key}
                onClick={() => handleTheme(key)}
              >
                {key}
              </MenuItem>
            ),
            R.keys(themes),
          )}
        </Menu>
        <IconButton color="inherit" href={meta.repository} target="_blank" edge="end">
          <CodeIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};

Bar.propTypes = {
  themeId: PropTypes.string,
  setThemeId: PropTypes.func.isRequired,
  setComponentId: PropTypes.func.isRequired,
  toggleRtl: PropTypes.func.isRequired,
  isRtl: PropTypes.bool,
  toggleA11y: PropTypes.func.isRequired,
  isA11y: PropTypes.bool,
  toggleDrawer: PropTypes.func.isRequired,
  drawers: PropTypes.object,
};

export default Bar;
