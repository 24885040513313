import React from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { Alert, Button } from '../';

const useStyles = makeStyles(theme => ({
  error: {
    width: '100%',
    margin: '0px 0px 8px 0px',
    wordBreak: 'break-word',
  },
  header: {
    padding: theme.spacing(1, 0),
    minHeight: 50,
  },
  textarea: {
    width: '100%',
    margin: theme.spacing(0, 0, 1, 0),
  },
  title: R.pathOr({}, ['mixins', 'apiQueries', 'title'], theme),
}));

const Query = ({ contents, title, selectedId, labels, delay, handleClick }) => {
  const classes = useStyles();
  const ref = React.useRef();
  const [value, setSelected] = React.useState(selectedId);
  const [isCopied, setCopied] = React.useState(false);
  const contentValue = R.pipe(R.find(R.propEq('id', value)), R.prop('value'))(contents);
  const isError = R.pipe(R.find(R.propEq('id', value)), R.propOr(false, 'isError'))(contents);

  const copyToClipboard = () => {
    if (R.has('current')(ref)) {
      ref.current.select();
      document.execCommand('copy');
      setCopied(true);
      setTimeout(() => setCopied(false), delay);
      handleClick();
    }
  };
  const labelActionCopy = isCopied ? R.prop('copied')(labels) : R.prop('copy')(labels);
  return (
    <Grid item xs={12} md={6} container>
      <Grid
        item
        container
        justifyContent="space-between"
        alignItems="flex-start"
        className={classes.header}
      >
        <Grid item>
          <Typography className={classes.title} variant="h6">
            {title}
          </Typography>
        </Grid>
        {R.pipe(R.length, R.flip(R.gt)(1))(contents) && (
          <Grid container alignItems="center">
            <Grid item>
              <Typography variant="body2">{labels.buttonsLabel}:&nbsp;</Typography>
            </Grid>
            <Grid item>
              {R.map(({ id, label }) => (
                <Button
                  aria-label={label}
                  aria-pressed={R.equals(value, id)}
                  key={id}
                  size="small"
                  variant="text"
                  color="primary"
                  onClick={() => setSelected(id)}
                  selected={R.equals(value, id)}
                >
                  {label}
                </Button>
              ))(contents)}
            </Grid>
          </Grid>
        )}
      </Grid>
      <Grid item container xs={12} style={{ alignSelf: 'flex-end' }} justifyContent="flex-end">
        {!isError && (
          <TextareaAutosize
            ref={ref}
            aria-label={title}
            className={classes.textarea}
            minRows={5}
            maxRows={5}
            readOnly
            value={contentValue}
          />
        )}
        {isError && (
          <Alert className={classes.error} severity="error" variant="filled">
            {contentValue}
          </Alert>
        )}
        <Button
          aria-label={labelActionCopy}
          disabled={isError}
          size="small"
          variant="contained"
          alternative="siscc"
          color="primary"
          onClick={copyToClipboard}
          className={classes.sisccButton}
        >
          {labelActionCopy}
        </Button>
      </Grid>
    </Grid>
  );
};

Query.propTypes = {
  labels: PropTypes.shape({
    copy: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    copied: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    buttonsLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  }),
  contents: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
      value: PropTypes.string,
    }),
  ),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  selectedId: PropTypes.string,
  delay: PropTypes.number,
  handleClick: PropTypes.func,
};

export default Query;
