import * as R from 'ramda';
import { T4_BREAKPOINTS } from '../theme';

export const getReducedContent = ({ isWidthLess, ...rest }) =>
  R.pipe(
    R.evolve({
      title: R.of,
      subtitle: R.identity,
      uprs: R.of,
      disclaimer: label => [{ label }],
    }),
    R.props(['title', 'subtitle', 'uprs', 'disclaimer']),
    R.cond([
      [R.always(isWidthLess(T4_BREAKPOINTS.xs2)), R.identity],
      [R.always(isWidthLess(T4_BREAKPOINTS.xs3)), R.tail],
      [R.T, R.always([])],
    ]),
    R.unnest,
    R.reject(R.isNil),
  )(rest);
