export const YEARS = 'years';
export const SEMESTERS = 'semesters';
export const QUARTERS = 'quarters';
export const MONTHS = 'months';
export const WEEKS = 'weeks';
export const DAYS = 'days';
export const FULL_HOURS = 'full_hours';
export const HOURS = 'hours';
export const MINUTES = 'minutes';
export const frequencies = [
  YEARS,
  MONTHS,
  DAYS,
  HOURS,
  MINUTES,
  FULL_HOURS,
  QUARTERS,
  WEEKS,
  SEMESTERS,
];

export const YEAR = 'year';
export const SEMESTER = 'semester';
export const QUARTER = 'quarter';
export const MONTH = 'month';
export const WEEK = 'week';
export const DAY = 'day';
export const FULL_HOUR = 'full_hour';
export const HOUR = 'hour';
export const MINUTE = 'minute';

export const layoutFrequencies = {
  S: [
    [
      { values: YEARS, id: YEAR },
      { values: SEMESTERS, id: SEMESTER },
    ],
  ],
  A: [[{ values: YEARS, id: YEAR }]],
  Q: [
    [
      { values: YEARS, id: YEAR },
      { values: QUARTERS, id: QUARTER },
    ],
  ],
  M: [
    [
      { values: YEARS, id: YEAR },
      { values: MONTHS, id: MONTH },
    ],
  ],
  W: [
    [
      { values: YEARS, id: YEAR },
      { values: WEEKS, id: WEEK },
    ],
  ],
  B: [
    [
      { values: YEARS, id: YEAR },
      { values: WEEKS, id: WEEK },
    ],
  ],
  D: [
    [
      { values: YEARS, id: YEAR },
      { values: MONTHS, id: MONTH },
      { values: DAYS, id: DAY },
    ],
  ],
  H: [
    [
      { values: YEARS, id: YEAR },
      { values: MONTHS, id: MONTH },
      { values: DAYS, id: DAY },
    ],
    [{ values: FULL_HOURS, id: FULL_HOUR }],
  ],
  N: [
    [
      { values: YEARS, id: YEAR },
      { values: MONTHS, id: MONTH },
      { values: DAYS, id: DAY },
    ],
    [
      { values: HOURS, id: HOUR },
      { values: MINUTES, id: MINUTE },
    ],
  ],
};

export const defaultOutputValues = {
  [YEARS]: '----',
  [SEMESTERS]: '-',
  [QUARTERS]: '-',
  [MONTHS]: '--',
  [WEEKS]: '--',
  [DAYS]: '--',
  [FULL_HOURS]: '--:00',
  [HOURS]: '--',
  [MINUTES]: '--',
};

export const formatOrder = [YEAR, MONTH, DAY, HOUR, MINUTE, FULL_HOUR, QUARTER, WEEK, SEMESTER];
export const defaultfreq = 'A';
