/* eslint react/prop-types: 0 */
import * as R from 'ramda';
import React, { useEffect, useMemo, useState } from 'react';
import { getHierarchicalId } from './utils';

export const spotlightFilter =
  (term, labelRenderer = R.prop('label')) =>
  items => {
    if (R.isEmpty(term) || R.isNil(term)) {
      return items;
    }

    const groupedItemsByParentId = R.groupBy(R.propOr('#ROOT', 'parentId'), items);
    const recurse = R.filter(item => {
      const isValidItem =
        R.isEmpty(term) || R.isNil(term)
          ? true
          : R.includes(R.toLower(term), R.toLower(labelRenderer(item)));
      if (isValidItem) {
        return true;
      }
      if (R.has(getHierarchicalId(item), groupedItemsByParentId)) {
        const filtered = recurse(R.prop(getHierarchicalId(item), groupedItemsByParentId));
        if (!R.isEmpty(filtered)) {
          return true;
        }
      }
      return false;
    });

    return recurse(items);
  };

export const withSpotlight =
  Component =>
  ({ items, defaultSpotlight, labelRenderer, ...rest }) => {
    const [{ term }, setSpotlight] = useState({ term: '' });
    useEffect(() => {
      if (!R.isNil(defaultSpotlight) && !R.isEmpty(defaultSpotlight)) {
        setSpotlight(defaultSpotlight);
      }
    }, [defaultSpotlight]);

    const filteredItems = useMemo(() => spotlightFilter(term, labelRenderer)(items), [term, items]);

    return (
      <Component
        {...rest}
        allItems={items}
        items={filteredItems}
        labelRenderer={labelRenderer}
        hasSpotlight={R.length(items) >= 8}
        spotlight={{ term }}
        setSpotlight={setSpotlight}
      />
    );
  };
