import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import * as R from 'ramda';
import ArrowForward from '@material-ui/icons/ArrowForward';
import { useTheme } from '@material-ui/core/styles';
import ArrowBack from '@material-ui/icons/ArrowBack';
import Search from '@material-ui/icons/Search';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import { Field } from './Fields';
import { useStyles } from './useStyles';
import { useSpotlight } from './useSpotlight';
import Cross from '../Icons/Cross';

const size = R.pipe(R.values, R.length);

const Spotlight = ({
  placeholder,
  hasClearAll,
  hasCommit,
  withBorder,
  defaultSpotlight,
  term,
  action,
  fullWidth,
  className,
  withAutoFocus = false,
  testId = 'spotlight',
}) => {
  const {
    value,
    spotlight,
    isFocus,
    onKeyPress,
    onClearTerm,
    onChangeTerm,
    onChangeField,
    onCommitTerm,
    setFocus,
  } = useSpotlight(hasCommit, term, defaultSpotlight, action);
  const classes = useStyles();
  const theme = useTheme();
  const isRtl = R.equals('rtl', R.prop('direction', theme));

  return (
    <div
      data-testid={testId}
      className={cx(R.prop('container', className), {
        [classes.container]: withBorder,
        [classes.containerFocus]: isFocus,
        [classes.fullWidth]: fullWidth,
        [classes.defaultWidth]: !fullWidth,
      })}
    >
      <Paper className={classes.root} elevation={0}>
        <IconButton className={classes.leftIcon} disabled aria-label="search">
          <Search />
        </IconButton>
        <InputBase
          inputProps={{
            'aria-label': placeholder,
            'data-testid': `${testId}_input`,
          }}
          className={classes.input}
          classes={{ root: classes.inputBase, input: classes.inputBaseMargin }}
          placeholder={placeholder}
          onKeyPress={onKeyPress}
          value={value}
          onChange={onChangeTerm}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus={withAutoFocus}
        />
        {hasClearAll && !R.isEmpty(value) ? (
          <IconButton
            aria-label="Clear"
            onClick={onClearTerm}
            className={cx(classes.rightIcon, classes.clear)}
          >
            <Cross />
          </IconButton>
        ) : null}
        {R.gt(size(R.prop('fields', spotlight)), 1) ? (
          <Fragment>
            <Divider className={classes.divider} />
            <Field
              spotlight={spotlight}
              onChangeField={onChangeField}
              isRtl={isRtl}
              term={value}
              classes={classes}
            />
          </Fragment>
        ) : null}
        {hasCommit ? (
          <Fragment>
            <Divider className={classes.divider} />
            <IconButton
              disabled={R.isEmpty(value)}
              color="primary"
              className={classes.rightIcon}
              aria-label="commit"
              onClick={onCommitTerm}
            >
              {isRtl ? <ArrowBack /> : <ArrowForward />}
            </IconButton>
          </Fragment>
        ) : null}
      </Paper>
    </div>
  );
};

Spotlight.propTypes = {
  term: PropTypes.string,
  testId: PropTypes.string,
  placeholder: PropTypes.string,
  defaultSpotlight: PropTypes.shape({
    fields: PropTypes.object,
    searchLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    FieldLabelRenderer: PropTypes.func,
  }),
  action: PropTypes.func,
  hasClearAll: PropTypes.bool,
  hasCommit: PropTypes.bool,
  withBorder: PropTypes.bool,
  fullWidth: PropTypes.bool,
  classes: PropTypes.object,
  className: PropTypes.object,
  withAutoFocus: PropTypes.bool,
};

export default Spotlight;
