import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import SvgIcon from '@material-ui/core/SvgIcon';

const WarningIcon = React.forwardRef((props, ref) => (
  <SvgIcon ref={ref} viewBox="0 0 24 24" {...R.omit(['pathBackgroundColor', 'pathColor'], props)}>
    <path
      d="M1 21h22L12 2 1 21zm12-3h-2v-2h2v2zm0-4h-2v-4h2v4z"
      fill={props.pathBackgroundColor || 'orange'}
    />
    <path d="M1 21zm12 -3h-2v-2h2v2zm0 -4h-2v-4h2v4z" fill={props.pathColor || 'black'} />
  </SvgIcon>
));

WarningIcon.propTypes = {
  pathBackgroundColor: PropTypes.string,
  pathColor: PropTypes.string,
};

export default WarningIcon;
