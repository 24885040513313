import React from 'react';
import * as R from 'ramda';
import cx from 'classnames';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Help from '@material-ui/icons/Help';
import Tooltip from '../Tooltip/Tooltip';
import { withBlank } from '../utils';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(0.5),
    alignItems: 'center',
  },
  textField: {
    margin: '0px 10px 0 10px',
  },
  input: {
    padding: '4px 12px 4px 16px',
    height: 27,
    '&[type=number]::-webkit-inner-spin-button, &[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '&[type=number]': {
      '-moz-appearance': 'textfield',
    },
  },
  icon: {
    marginLeft: theme.spacing(0.5),
  },
}));

const InputNumber = ({
  value,
  onChange,
  beforeLabel,
  afterLabel,
  popperLabel,
  placeholder = '--',
  textFieldProps,
  ariaLabel,
}) => {
  const classes = useStyles();

  const _value = R.anyPass([R.isEmpty, R.isNil, nb => R.lte(Number(nb), 0)])(value) ? '' : value;

  return (
    <div data-testid="input-number-test-id" className={classes.root}>
      {beforeLabel && (
        <Typography variant="body2" aria-label={beforeLabel} tabIndex={-1}>
          {beforeLabel}
        </Typography>
      )}
      <TextField
        className={classes.textField}
        inputProps={{
          min: 0,
          step: 1,
          className: classes.input,
          'aria-label': ariaLabel,
        }}
        placeholder={placeholder}
        onChange={e => onChange(e.target.value)}
        type="number"
        value={_value}
        variant="outlined"
        {...textFieldProps}
      />
      {R.not(R.isNil(afterLabel)) && (
        <Typography variant="body2" aria-label={afterLabel} tabIndex={0}>
          {afterLabel}
        </Typography>
      )}
      {R.not(R.isNil(popperLabel)) && (
        <Tooltip
          className={cx(classes.icon)}
          title={popperLabel}
          aria-label={popperLabel}
          variant="light"
          tabIndex={0}
        >
          <Help />
        </Tooltip>
      )}
    </div>
  );
};

InputNumber.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  beforeLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  afterLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  popperLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  textFieldProps: PropTypes.object,
  ariaLabel: PropTypes.string,
};

export default withBlank(({ isBlank }) => ({ isBlank }))(InputNumber);
