/**
 * Loading component is a basic placeholder to use when retrieving things.
 * There are four way to use it: 
 * 1. Loading with message
 * 2. Loading with message and variant (primary|secondary) (default: primary) 
 * 3. Loading with message and color (second priority color)
 * 4. Loading with warning (top priority color)
 * 
 *
 * @memberOf VISIONS
 * @name Loading
 * @tag component
 * @api public
 * @props
 * Loading.propTypes = {
 *   message: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
 *   variant: PropTypes.string,
 *   color: PropTypes.string,
 *   isWarning: PropTypes.bool,
 * };

 */

export { default } from './Loading';
