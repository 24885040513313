import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import cx from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles(theme => ({
  tooltip: {
    fontSize: 12,
    boxShadow: theme.shadows[2],
  },
  warningTooltip: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.getContrastText(theme.palette.warning.main),
  },
  lightTooltip: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.getContrastText(theme.palette.common.white),
  },
  darkTooltip: {
    backgroundColor: '#616161',
    color: theme.palette.getContrastText(theme.palette.common.black),
    '& a': {
      color: theme.palette.primary.light,
      textDecoration: 'none',
      '&:hover': {
        color: theme.palette.primary.light,
        textDecoration: 'underline',
      },
    },
  },
  popper: {
    opacity: 1,
  },
}));

const CustomTooltip = ({ children, variant = 'dark', ...props }) => {
  const classes = useStyles();
  return (
    <Tooltip
      PopperProps={{
        modifiers: {
          flip: {
            behavior: ['right', 'top', 'left', 'bottom'],
          },
        },
      }}
      // open={true}
      // interactive={true}
      enterNextDelay={100}
      classes={{
        popper: classes.popper,
        tooltip: cx(R.prop(`${variant}Tooltip`)(classes), classes.tooltip),
      }}
      {...props}
    >
      {children}
    </Tooltip>
  );
};

CustomTooltip.propTypes = {
  variant: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

export default CustomTooltip;
