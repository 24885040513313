import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Bar from './Bar';
import Side from './Side';
import Main from './Main';
import MenuComponents from './MenuComponents';
import MenuColors from './MenuColors';

const useStyles = makeStyles(() => ({
  app: {
    display: 'flex',
  },
}));

const App = ({
  componentId,
  setComponentId,
  themeId,
  setThemeId,
  toggleRtl,
  isRtl,
  toggleDrawer,
  drawers,
  isA11y,
  toggleA11y,
}) => {
  const classes = useStyles();
  const isDrawerLeftOpen = drawers.left;
  const isDrawerRightOpen = drawers.right;
  return (
    <Fragment>
      <div className={classes.app}>
        <Bar
          themeId={themeId}
          setThemeId={setThemeId}
          setComponentId={setComponentId}
          toggleRtl={toggleRtl}
          isRtl={isRtl}
          toggleA11y={toggleA11y}
          isA11y={isA11y}
          toggleDrawer={toggleDrawer}
          drawers={drawers}
        />
        <Side isDrawerOpen={isDrawerLeftOpen}>
          <MenuComponents setComponentId={setComponentId} componentId={componentId} />
        </Side>
        <Main componentId={componentId} isRtl={isRtl} isA11y={isA11y} isDrawerLeftOpen={isDrawerLeftOpen} />
      </div>
      <Side isDrawerOpen={isDrawerRightOpen} anchor='right'>
        <MenuColors />
      </Side>
    </Fragment>
  );
};

App.propTypes = {
  componentId: PropTypes.string,
  setComponentId: PropTypes.func.isRequired,
  themeId: PropTypes.string,
  setThemeId: PropTypes.func.isRequired,
  toggleRtl: PropTypes.func.isRequired,
  isRtl: PropTypes.bool,
  toggleA11y: PropTypes.func.isRequired,
  isA11y: PropTypes.bool,
  toggleDrawer: PropTypes.func.isRequired,
  drawers: PropTypes.object.isRequired,
};

export default App;
