import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { Asterisk } from '../';
import { TooltipHeader } from './TooltipHeader';

const Flags = ({ classes, content }) => {
  const codes = R.pipe(R.pluck('code'), R.filter(R.identity))(content);
  const hasCodes = !R.isEmpty(codes);
  const hasUncoded = R.length(codes) !== R.length(content);
  const joinedCodes = R.pipe(R.join(','), (codes) => (hasUncoded ? `${codes},` : codes))(codes);
  return (
    <TooltipHeader classes={classes} content={content}>
      <sup className={classes.svgWrapper}>
        {hasCodes && joinedCodes}
        {hasUncoded && <Asterisk className={classes.svg} />}
      </sup>
    </TooltipHeader>
  );
};

Flags.propTypes = {
  classes: PropTypes.object,
  content: PropTypes.arrayOf(
    PropTypes.shape({
      header: PropTypes.string,
      label: PropTypes.string,
      sub: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,
        }),
      ),
    }),
  ),
};

export default Flags;
