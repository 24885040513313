import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  headerContainer: {
    marginBottom: theme.spacing(0.5),
  },
  sideLabel: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingRight: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  },
  headerLabel: {
    display: 'flex',
    justifyContent: 'center',
  },
  selectContainer: {
    marginTop: theme.spacing(1),
  },
}));
