import * as R from 'ramda';

export const myTooltipRenderer = ({ isRtl, labelRenderer }) =>
  R.pipe(R.map(labelRenderer), R.when(R.always(isRtl), R.reverse), R.join(isRtl ? ' < ' : ' > '));

export const myTooltipRendererSrOnly = ({ isRtl, labelRenderer, ariaLabelTooltip }) =>
  R.pipe(R.map(labelRenderer), R.when(R.always(isRtl), R.reverse), R.join(ariaLabelTooltip));

export const isLast = (index, list) => index + 1 === R.length(list);

export const shownTooltip = R.pipe(R.dropLast(1), R.either(R.isEmpty, R.isNil), R.not);
export const showMultiLinedTooltip = R.pipe(R.head, R.propOr([], 'parents'), R.isEmpty, R.not);
