import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import cx from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import { Asterisk } from '../';
import { getValue } from './utils';
import useKeyboardEscapeHandler from '../hooks/useKeybordEscapeHandler';

const useStyles = makeStyles(theme => ({
  content: {
    borderRadius: 3,
    boxSizing: 'border-box',
    listStyleType: 'none',
    maxWidth: 270,
    padding: 0,
    margin: 0,
    ...theme.typography.body1,
  },
  flags: {
    borderBottom: '1px dotted',
    borderColor: 'inherit',
    margin: theme.spacing(0, 0.5),
    whiteSpace: 'nowrap',
    display: 'inline',
    fontWeight: 'bold',
    fontStyle: 'normal',
    position: 'relative',
    top: '-4px',
    ...R.pathOr({}, ['mixins', 'table', 'flag'], theme),
  },
  svg: {
    verticalAlign: 'middle',
  },
  header: {
    color: theme.palette.getContrastText(theme.palette.primary.main),
  },
}));

const Flags = ({ flags = [], isHeader, HTMLRenderer, labelAccessor }) => {
  const classes = useStyles();
  const noFlags = R.isNil(flags) || R.isEmpty(flags);
  const { open, setOpen } = useKeyboardEscapeHandler();

  if (noFlags) return null;

  const hasUncoded = R.any(R.complement(R.has('code')), flags);
  const codedIcons = R.pipe(
    R.reduce((acc, flag) => R.when(R.always(R.has('code', flag)), R.append(flag.code))(acc), []),
    R.join(','),
  )(flags);

  const labels = R.map(flag => {
    const value = getValue(labelAccessor)(flag);
    return `${labelAccessor(flag)}: ${value.label}`;
  }, flags);
  const content = (
    <ul data-testid="cell-flags-footnotes-content" className={classes.content}>
      {R.addIndex(R.map)(
        (label, index) => (
          <li key={index}>
            {R.is(Function, HTMLRenderer) ? <HTMLRenderer html={label} /> : label}
          </li>
        ),
        labels,
      )}
    </ul>
  );
  return (
    <Tooltip
      tabIndex={0}
      aria-label={R.join(', ', labels)}
      aria-hidden={false}
      title={content}
      placement="top"
      open={open}
      interactive
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
    >
      <span
        data-testid="cell-flags-footnotes-icon"
        className={cx(classes.flags, { [classes.header]: isHeader })}
      >
        {R.not(R.isEmpty(codedIcons)) && codedIcons}
        {R.and(R.not(R.isEmpty(codedIcons)), hasUncoded) && <React.Fragment>,</React.Fragment>}
        {hasUncoded && (
          <Fragment>
            &nbsp;
            <Asterisk className={classes.svg} style={{ fontSize: 11 }} />
            <Typography variant="srOnly">{`* ${labels}`}</Typography>
          </Fragment>
        )}
      </span>
    </Tooltip>
  );
};

Flags.propTypes = {
  flags: PropTypes.array,
  isHeader: PropTypes.bool,
  HTMLRenderer: PropTypes.func,
  labelAccessor: PropTypes.func,
};

export default Flags;
