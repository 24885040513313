import { useEffect, useState, useCallback } from 'react';

export default () => {
  const [open, setOpen] = useState(false);
  const handler = useCallback(e => {
    if (e.key === 'Escape') {
      setOpen(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', handler);
    return () => {
      document.removeEventListener('keydown', handler);
    };
  }, []);

  return { open, setOpen };
};
