import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  tag: {
    border: `2px solid ${
      R.path(['palette', 'highlight', 'hl2'], theme) || theme.palette.primary.dark
    }`,
    borderRadius: 4,
    fontWeight: 700,
    padding: theme.spacing(0, 0.5),
    ...R.pathOr({}, ['mixins', 'tag', 'root'], theme),
  },
}));

const Tag = ({ children, tagValueLabel }) => {
  const classes = useStyles();

  if (R.isNil(children)) return null;

  return (
    <div>
      <Typography tabIndex={0} className={classes.tag} aria-label={tagValueLabel}>
        {children}
      </Typography>
    </div>
  );
};

Tag.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  tagValueLabel: PropTypes.string,
};

export default Tag;
