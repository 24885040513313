import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import * as R from 'ramda';
import useSize from '@react-hook/size';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import { Tooltip } from '../';
import { T4_BREAKPOINTS } from '../theme';
import useKeyboardEscapeHandler from '../hooks/useKeybordEscapeHandler';

const HEIGHT = 42;

const useStyles = makeStyles(theme => ({
  footer: {
    minHeight: HEIGHT,
  },
  legend: {
    flexGrow: 1,
  },
  copyright: {
    padding: 0,
  },
  source: {
    padding: theme.spacing(0, 1, 0, 1),
  },
  logo: {
    height: HEIGHT / 2,
    display: 'flex',
  },
  sticky: {
    position: 'sticky',
    right: 0,
  },
}));

const DataFooter = ({ logo, source, copyright, legend, isSticky = false }) => {
  const target = React.useRef(null);
  const [width] = useSize(target);
  const isWidthUp = R.gt(width);
  const isSourced = source && source.label && source.link;
  const isCopyrighted = copyright && copyright.label && copyright.content;
  const classes = useStyles();
  const { open, setOpen } = useKeyboardEscapeHandler();
  const typoSize = isWidthUp(T4_BREAKPOINTS.lg) ? 14 : 12;
  if (!isWidthUp(T4_BREAKPOINTS.xs)) return <Grid ref={target} />;

  return (
    <Grid
      ref={target}
      container
      className={classes.footer}
      direction="row"
      justifyContent="flex-end"
      alignItems="center"
    >
      <Grid item className={classes.legend}>
        {isWidthUp(T4_BREAKPOINTS.sm) && legend}
      </Grid>
      <Grid item style={{ alignSelf: 'flex-end' }} className={cx({ [classes.sticky]: isSticky })}>
        <Grid
          container
          justifyContent="flex-end"
          alignItems="center"
          className={classes.footer}
          spacing={0}
        >
          <Grid
            item
            className={
              isWidthUp(T4_BREAKPOINTS.sm) && isSourced ? classes.copyright : classes.source
            }
          >
            {isCopyrighted && (
              <Fragment>
                <Tooltip
                  title={<Typography variant="body2">{copyright.content}</Typography>}
                  aria-label={copyright.content}
                  variant="light"
                  tabIndex={0}
                  aria-hidden={false}
                  placement="top"
                  enterNextDelay={100}
                  interactive
                  arrow
                  open={open}
                  onOpen={() => setOpen(true)}
                  onClose={() => setOpen(false)}
                >
                  <Typography variant="body2" style={{ cursor: 'pointer', fontSize: typoSize }}>
                    {copyright.label}
                  </Typography>
                </Tooltip>
                <Typography variant="srOnly">{copyright.content}</Typography>
              </Fragment>
            )}
          </Grid>
          {isWidthUp(T4_BREAKPOINTS.sm) && isSourced && (
            <Grid item className={classes.source}>
              <Typography variant="body2" style={{ fontSize: typoSize }}>
                <Link href={source.link} rel="noopener noreferrer" target="_blank" variant="body2">
                  {source.label}
                </Link>
              </Typography>
            </Grid>
          )}
          {isWidthUp(T4_BREAKPOINTS.xs) && logo && (
            <Grid item>
              <img src={logo} alt="logo" className={classes.logo} />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

DataFooter.propTypes = {
  logo: PropTypes.string,
  source: PropTypes.shape({
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    link: PropTypes.string,
  }),
  copyright: PropTypes.shape({
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    content: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  }),
  legend: PropTypes.element,
  isSticky: PropTypes.bool,
};

export default DataFooter;
