import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import cx from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Done from '@material-ui/icons/Done';
import { withInput } from './with-input';

const useStyles = makeStyles(() => ({
  visibility: {
    visibility: 'hidden',
  },
}));

const LefttIcon = ({ Icon }) => <Icon />;

LefttIcon.propTypes = {
  Icon: PropTypes.object,
};

const RightIcon = ({ onSubmit, Icon = Done }) => (
  <IconButton color="primary" onClick={() => onSubmit()}>
    <Icon />
  </IconButton>
);

RightIcon.propTypes = {
  Icon: PropTypes.object,
  onSubmit: PropTypes.func,
  classes: PropTypes.object,
};

export const MyInput = ({
  id,
  label,
  placeholder,
  leftIcon,
  rightIcon,
  value,
  type,
  onChange,
  onSubmit,
  fullWidth,
  withValidationIcon,
  textFieldProps,
  inputProps,
  endAdornment,
}) => {
  const classes = useStyles();
  const startAdornment = R.isNil(leftIcon) ? null : (
    <InputAdornment position="start">
      <LefttIcon Icon={leftIcon} />
    </InputAdornment>
  );
  const endMyadornment = R.or(withValidationIcon, !R.isNil(rightIcon)) ? (
    <InputAdornment position="end" className={cx({ [classes.visibility]: R.isEmpty(value) })}>
      <RightIcon onSubmit={onSubmit} Icon={rightIcon} />
    </InputAdornment>
  ) : null;

  const onEnterLabel = event => {
    if (event.key === 'Enter') {
      event.preventDefault();
      if (R.is(Function, onSubmit)) {
        onSubmit();
      }
    }
  };

  return (
    <TextField
      data-testid="input-test-id"
      margin="dense"
      fullWidth={fullWidth}
      id={id}
      type={type}
      label={label}
      value={value}
      variant="outlined"
      placeholder={placeholder}
      onChange={e => onChange(e.target.value)}
      onKeyPress={onEnterLabel}
      InputProps={{
        startAdornment,
        endAdornment: R.isNil(endAdornment) ? endMyadornment : endAdornment,
      }}
      inputProps={inputProps}
      {...textFieldProps}
    />
  );
};

MyInput.propTypes = {
  id: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  leftIcon: PropTypes.object,
  rightIcon: PropTypes.object,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  type: PropTypes.string,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  fullWidth: PropTypes.bool,
  withValidationIcon: PropTypes.bool,
  textFieldProps: PropTypes.object,
  inputProps: PropTypes.object,
  endAdornment: PropTypes.node,
};

export default withInput(MyInput);
