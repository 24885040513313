import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import clsx from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import Entry from './Entry';
import { ErrorBoundary } from "react-error-boundary";
import Loading from '../../../src/Loading';

const useStyles = makeStyles(theme => ({
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -260,
    flexDirection: 'column',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.secondary.main,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  toolbar: theme.mixins.toolbar,
}));

const Main = ({ componentId, isRtl, isDrawerLeftOpen, isA11y }) => {
  const classes = useStyles();
  const Wrapper = React.lazy(() => import(`../wrappers/${componentId}`));

  return (
    <main className={clsx(classes.content, { [classes.contentShift]: isDrawerLeftOpen })}>
      <div className={classes.toolbar} />
      <ErrorBoundary fallback={<div>Something went wrong with {componentId}</div>}>
        <Suspense fallback={<Loading />}>
          {componentId && <Entry componentId={componentId}>
            <Wrapper isRtl={isRtl} isA11y={isA11y} />
          </Entry>}
        </Suspense>
      </ErrorBoundary>
    </main>
  );
};

Main.propTypes = {
  componentId: PropTypes.string,
  isRtl: PropTypes.bool,
  isA11y: PropTypes.bool,
  isDrawerLeftOpen: PropTypes.bool,
};

export default Main;
