import React from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ArrowUp from '@material-ui/icons/KeyboardArrowUp';
import ArrowDown from '@material-ui/icons/KeyboardArrowDown';

const useStyles = makeStyles(theme => {
  const color1 = R.path(['palette', 'highlight', 'hl1'], theme) || theme.palette.primary.dark;
  return {
    root: {
      minHeight: '43px',
      borderRadius: theme.shape.borderRadius,
      padding: theme.spacing(0.75, 2, 0.5, 2),
      color: theme.palette.getContrastText(theme.palette.primary.main),
      backgroundColor:
        R.path(['palette', 'button', 'mainOpacity'], theme) || theme.palette.primary.main,
      display: 'flex',
      justifyContent: 'space-between',
      '&:hover': {
        color: theme.palette.getContrastText(color1),
        backgroundColor: color1,
      },
    },
    label: {
      textAlign: 'left',
      fontSize: '0.9375rem',
      lineHeight: '1.0',
    },
    selected: {
      color: theme.palette.getContrastText(color1),
      backgroundColor: color1,
    },
  };
});

const ToggleButton = ({
  isOpen,
  label,
  toggle,
  testId,
  tabindex = 0,
  textTransform = 'capitalize',
}) => {
  const classes = useStyles();
  return (
    <Button
      aria-expanded={isOpen}
      aria-label={label}
      tabIndex={tabindex}
      data-testid={testId}
      classes={{ root: classes.root, label: classes.label }}
      className={cx({ [classes.selected]: isOpen })}
      style={{ textTransform }}
      fullWidth
      variant="text"
      onClick={R.is(Function)(toggle) ? toggle : null}
    >
      {label}
      {isOpen ? <ArrowUp /> : <ArrowDown />}
    </Button>
  );
};

ToggleButton.propTypes = {
  testId: PropTypes.string,
  tabindex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isOpen: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  toggle: PropTypes.func,
  textTransform: PropTypes.string,
};

export default ToggleButton;
