import React, { Fragment } from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Lens from '@material-ui/icons/Lens';

const useStyles = makeStyles(theme => ({
  title: {
    color: theme.palette.text.primary,
  },
  subtitle: {
    color: theme.palette.text.secondary,
    lineHeight: 1.3,
  },
  icon: {
    height: '0.35em',
    color: theme.palette.text.secondary,
  },
}));

const TableHeader = ({ header }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Typography variant="h5" className={classes.title}>
        {R.prop('title')(header)}
      </Typography>
      <Typography variant="subtitle1" className={classes.subtitle}>
        {R.pipe(
          R.propOr([], 'subtitle'),
          R.intersperse(<Lens className={classes.icon} />),
          R.addIndex(R.map)((v, index) => (
            <Fragment key={R.toString([R.prop('title')(header), index])}>{v}</Fragment>
          )),
        )(header)}
      </Typography>
      <Typography variant="subtitle1" className={classes.subtitle}>
        {R.prop('uprs')(header)}
      </Typography>
    </div>
  );
};

TableHeader.propTypes = {
  header: PropTypes.shape({
    title: PropTypes.string,
    subtitle: PropTypes.array,
    uprs: PropTypes.string,
  }),
};

export default TableHeader;
