import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { Grid } from '@material-ui/core';
import Input from './Input';

const InputProxy = ({
  minWidth = 220,
  properties = {},
  labels = {},
  pkey,
  mkey,
  xs = 12,
  sm = 12,
  lg = 12,
  InputProps = {},
}) => {
  if (R.isNil(pkey)) return null;
  if (!R.has(pkey, properties)) return null;

  const prop = R.propOr({}, pkey, properties);

  return (
    <Grid item xs={xs} sm={sm} lg={lg} style={{ minWidth }}>
      <Input
        {...prop}
        value={R.when(R.isNil, R.always(''))(prop.value)}
        type="number"
        label={R.prop(mkey || pkey, labels)}
        fullWidth
        InputProps={R.mergeRight({ endAdornment: null }, InputProps)}
      />
    </Grid>
  );
};

InputProxy.propTypes = {
  minWidth: PropTypes.number,
  properties: PropTypes.object,
  labels: PropTypes.object,
  pkey: PropTypes.string,
  mkey: PropTypes.string,
  xs: PropTypes.number,
  sm: PropTypes.number,
  lg: PropTypes.number,
  InputProps: PropTypes.object,
};

export default InputProxy;
