/* eslint react/prop-types: 0 */
import React, { useState, useEffect } from 'react';
import * as R from 'ramda';
import { getHierarchicalId } from './utils';

export const withExpansionTree =
  Component =>
  ({ items, defaultExpandedIds, ...rest }) => {
    const [expandedIds, setExpandedIds] = useState({});
    const [itemsIds, setItemsIds] = useState([]);

    useEffect(() => {
      const ids = R.map(getHierarchicalId, items);
      if (!R.isEmpty(R.symmetricDifference(ids, itemsIds))) setItemsIds(ids);
    }, [items]);

    useEffect(() => {
      setExpandedIds(R.indexBy(R.identity, itemsIds));
    }, [itemsIds]);

    useEffect(() => {
      if (!R.isNil(defaultExpandedIds)) {
        setExpandedIds(defaultExpandedIds);
      }
    }, [defaultExpandedIds]);

    const expand = id => {
      setExpandedIds(R.assoc(id, id, expandedIds));
    };

    const collapse = id => {
      setExpandedIds(R.dissoc(id, expandedIds));
    };

    const expandAll = () => {
      setExpandedIds(R.indexBy(R.identity, itemsIds));
    };

    const collapseAll = () => {
      setExpandedIds({});
    };

    return (
      <Component
        {...rest}
        items={items}
        expand={expand}
        collapse={collapse}
        expandAll={expandAll}
        collapseAll={collapseAll}
        expandedIds={expandedIds}
      />
    );
  };
