import * as R from 'ramda';
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(1.875),
    borderRadius: theme.spacing(0.5),
    backgroundColor:
      R.path(['palette', 'button', 'mainOpacity'], theme) || theme.palette.primary.dark,
  },
  fullWidth: {
    width: '100%',
  },
  defaultWidth: {
    maxWidth: 420,
    minWidth: 250,
  },
  containerFocus: {
    backgroundColor: R.path(['palette', 'highlight', 'hl1'], theme) || theme.palette.primary.light,
    borderRadius: theme.shape.borderRadius,
    ...R.pathOr({}, ['mixins', 'spotlight', 'focus'], theme),
  },
  root: {
    display: 'flex',
    alignItems: 'center',
    border: `solid 1px ${theme.palette.grey[400]}`,
    paddingRight: theme.spacing(1),
  },
  input: {
    marginLeft: theme.spacing(0),
    flex: 1,
  },
  inputBase: {
    ...R.pathOr({}, ['typography', 'body2'], theme),
    lineHeight: 0,
  },
  inputBaseMargin: {
    padding: theme.spacing(0),
    height: theme.spacing(3.75),
  },
  leftIcon: {
    padding: theme.spacing(0.125, 0.5),
  },
  rightIcon: {
    padding: theme.spacing(0.125, 0),
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.primary.main,
    margin: theme.spacing(0.125),
  },
  clear: {
    padding: '3px 0px 0px 0px',
  },
  popoverButton: {
    display: 'flex',
    flexDirection: 'column',
  },
  divider: {
    width: 1,
    height: '1.5em',
    margin: theme.spacing(0.5),
  },
  selected: {
    visibility: 'hidden',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
}));
