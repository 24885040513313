import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Query from './Query';

const useStyles = makeStyles(theme => ({
  divider: {
    padding: theme.spacing(2, 0),
  },
  title: R.pathOr({}, ['mixins', 'apiQueries', 'title'], theme),
}));

const ApiQueries = ({ queries = [], labels, delay = 1000, handleClick }) => {
  const classes = useStyles();
  const hasQueries = R.not(R.isEmpty(queries));
  return (
    <Grid container data-testid="apiqueries-test-id">
      <Grid item xs={12}>
        <Typography className={classes.title} variant="h6">
          {R.prop('title')(labels)}
        </Typography>
        {R.map(description => (
          <Typography key={description} display="block" variant="body2">
            {description}
          </Typography>
        ))(R.propOr([], 'descriptions', labels))}
      </Grid>
      {hasQueries && (
        <Grid item xs={12} className={classes.divider}>
          <Divider />
        </Grid>
      )}
      <Grid item container spacing={2}>
        {R.map(({ id, title, contents = [] }) => (
          <Query
            key={id}
            title={title}
            contents={contents}
            selectedId={R.pipe(R.head, R.prop('id'))(contents)}
            labels={labels}
            delay={delay}
            handleClick={handleClick}
          />
        ))(queries)}
      </Grid>
      {hasQueries && (
        <Grid item xs={12} className={classes.divider}>
          <Divider />
        </Grid>
      )}
      <Typography variant="body2">{R.prop('notice')(labels)}</Typography>
    </Grid>
  );
};

ApiQueries.propTypes = {
  labels: PropTypes.shape({
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    copy: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    copied: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    descriptions: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.element])),
    notice: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    buttonsLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  }),
  delay: PropTypes.number,
  queries: PropTypes.arrayOf(
    PropTypes.shape({
      contents: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
          value: PropTypes.string,
        }),
      ),
      id: PropTypes.string,
      title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    }),
  ),
  handleClick: PropTypes.func,
};

export default ApiQueries;
