import React, { Fragment, useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import * as R from 'ramda';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ViewColumn from '@material-ui/icons/ViewColumnOutlined';
import ViewList from '@material-ui/icons/ViewListOutlined';
import Reorder from '@material-ui/icons/Reorder';
import ViewQuilt from '@material-ui/icons/ViewQuiltOutlined';
import Undo from '@material-ui/icons/Undo';
import Done from '@material-ui/icons/Done';
import CheckBoxIcon from '@material-ui/icons/CheckBoxOutlineBlankOutlined';
import { makeStyles } from '@material-ui/core/styles';
import { DragDropContext } from '@hello-pangea/dnd';
import { withBlank } from '../utils';
import TablePreview from '../TablePreview';
import Box from './Box';
import { changeLayout, getIsOptionalActivated } from './lib';
import { BOX, wcagId } from './constants';
import { Button } from '../';

export const useStyles = makeStyles(theme => ({
  dnd: {
    userSelect: 'none',
  },
  buttonContainer: {
    margin: theme.spacing(2, 0),
  },
  iconTitle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexFlow: 'nowrap',
  },
  activationButton: {
    backgroundColor: theme.palette.action.hover,
    '&:hover': {
      backgroundColor: theme.palette.action.selected,
    },
  },
}));

const transform = {
  index: R.add(1),
};

const TableLayout = ({
  layout = {},
  items = [],
  optionalItem = {},
  itemRenderer = R.prop('name'),
  itemButtonProps,
  labels,
  commit,
  noPreview,
  accessibility,
}) => {
  const classes = useStyles();
  const [currentLayout, setCurrentLayout] = useState(layout);
  const boxLabel = {
    [BOX.header]: labels.column || BOX.header,
    [BOX.sections]: labels.section || BOX.sections,
    [BOX.rows]: labels.row || BOX.rows,
  };
  useEffect(() => {
    setCurrentLayout(layout);
  }, [layout]);
  const indexedItems = useMemo(() => {
    const indexed = R.indexBy(R.prop('id'), items);
    if (!R.isEmpty(optionalItem) && !R.isNil(optionalItem)) {
      return R.assoc(optionalItem.id, optionalItem, indexed);
    }
    return indexed;
  }, [items, optionalItem]);

  const onChangeLayout = props => setCurrentLayout(changeLayout({ ...props }));

  const activateOptionalItem = () => {
    const nextLayout = R.over(R.lensProp('header'), R.append(optionalItem.id), currentLayout);
    setCurrentLayout(nextLayout);
  };

  const deactivateOptionalItem = () => {
    setCurrentLayout(R.map(R.reject(R.equals(optionalItem.id)), currentLayout));
  };

  const onCommit = () => {
    if (R.is(Function)(commit)) {
      commit(currentLayout);
    }
  };

  const onCancel = () => {
    if (R.is(Function)(setCurrentLayout)) {
      setCurrentLayout(layout);
    }
  };

  const onDragStart = ({ draggableId, source }, provided) => {
    if (R.is(Function)(labels.wcagDragStart)) {
      // start.source = droppableId & index
      const dragZoneId = source.droppableId;
      const dragLabel = R.pipe(
        R.prop(dragZoneId),
        R.find(R.propEq('id', draggableId)),
        itemRenderer,
      )(currentLayout);
      R.pipe(
        R.evolve(transform),
        R.prop('wcagDragStart', labels),
        provided.announce,
      )({
        dragZone: R.prop(dragZoneId)(boxLabel),
        ...source,
        dragLabel,
      });
    }
  };

  const onDragUpdate = ({ destination, source }, provided) => {
    if (R.isNil(destination)) {
      return;
    }

    if (R.is(Function)(labels.wcagDragUpdate)) {
      const { droppableId, index } = destination;
      const length = R.ifElse(
        R.always(R.equals(droppableId, source.droppableId)),
        R.pipe(R.prop(droppableId), R.length),
        R.pipe(R.prop(droppableId), R.length, R.add(1)),
      )(currentLayout);

      R.pipe(
        R.evolve(transform),
        R.prop('wcagDragUpdate', labels),
        provided.announce,
      )({
        dropZone: R.prop(droppableId)(boxLabel),
        dragZone: R.prop(source.droppableId)(boxLabel),
        index,
        length,
      });
    }
  };

  const onDragEnd =
    layout =>
    ({ reason, destination, source }, provided) => {
      if (R.is(String)(labels.wcagDragCancel)) {
        if (R.equals(reason, 'CANCEL')) {
          provided.announce(labels.wcagDragCancel);
        }
      }

      if (R.isNil(destination)) {
        return;
      }
      if (R.is(Function)(labels.wcagDragEnd)) {
        if (R.equals(reason, 'DROP')) {
          const { droppableId, index } = destination;
          R.pipe(
            R.evolve(transform),
            R.prop('wcagDragEnd', labels),
            provided.announce,
          )({
            dropZone: R.prop(droppableId)(boxLabel),
            dragZone: R.prop(source.droppableId)(boxLabel),
            index,
          });
        }
      }

      onChangeLayout({
        dragIndex: source.index,
        hoverIndex: destination.index,
        dragZone: source.droppableId,
        dropZone: destination.droppableId,
        currentLayout: layout,
      });
    };

  const getItems = level =>
    R.pipe(R.prop(level), ids => R.props(ids, indexedItems), R.filter(R.identity))(currentLayout);

  const header = (
    <Box
      title={labels.column}
      Icon={ViewColumn}
      items={getItems('header')}
      labels={labels}
      dropZoneLabel={BOX.header}
      itemRenderer={itemRenderer}
      itemButtonProps={itemButtonProps}
      accessibility={accessibility}
      md={accessibility ? 4 : 6}
    />
  );

  const sectionsAndRows = (
    <Fragment>
      <Box
        title={labels.section}
        Icon={Reorder}
        items={getItems('sections')}
        labels={labels}
        dropZoneLabel={BOX.sections}
        itemRenderer={itemRenderer}
        itemButtonProps={itemButtonProps}
        accessibility={accessibility}
        md={accessibility ? 4 : 12}
      />
      <Box
        title={labels.row}
        Icon={ViewList}
        items={getItems('rows')}
        labels={labels}
        dropZoneLabel={BOX.rows}
        itemRenderer={itemRenderer}
        itemButtonProps={itemButtonProps}
        accessibility={accessibility}
        md={accessibility ? 4 : 12}
      />
    </Fragment>
  );

  const isOptionalActivated = getIsOptionalActivated(currentLayout, R.prop('id', optionalItem));

  return (
    <Grid data-testid="table-layout-test-id" container>
      <div id={wcagId} aria-live="assertive" style={{ display: 'none' }}>
        {labels.wcagDragExplanation}
      </div>
      <DragDropContext
        onDragEnd={onDragEnd(currentLayout)}
        onDragStart={onDragStart}
        onDragUpdate={onDragUpdate}
      >
        <Grid container direction="row" spacing={1} className={classes.dnd}>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid tabIndex={0} item xs={12} md={accessibility ? 12 : 6}>
                <span>{labels.help}</span>
                <Typography variant="srOnly">{labels.wcagDragExplanation}</Typography>
              </Grid>
              {R.not(accessibility) && header}
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            {accessibility && header}
            {accessibility && sectionsAndRows}
            {R.not(accessibility) && (
              <Grid item xs={12} md={6}>
                {sectionsAndRows}
              </Grid>
            )}
            {!noPreview && (
              <Grid item xs={12} md={accessibility ? 12 : 6} className={cx(classes.wrapper)}>
                <div className={classes.iconTitle}>
                  <ViewQuilt />
                  <Typography tabIndex={0} variant="subtitle1">
                    {labels.table}
                  </Typography>
                </div>
                <TablePreview
                  header={R.props(R.prop('header', currentLayout), indexedItems)}
                  sections={R.props(R.prop('sections', currentLayout), indexedItems)}
                  rows={R.props(R.prop('rows', currentLayout), indexedItems)}
                  itemRenderer={itemRenderer}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </DragDropContext>
      <Grid
        container
        justifyContent="space-between"
        className={classes.buttonContainer}
        spacing={1}
      >
        <Grid
          item
          data-testid={
            isOptionalActivated
              ? 'table-layout-deactivate-optional'
              : 'table-layout-activate-optional'
          }
        >
          {!R.isNil(optionalItem) && !R.isEmpty(optionalItem) && (
            <Button
              className={classes.activationButton}
              aria-label={R.prop('activationLabel', optionalItem)}
              color="primary"
              onClick={isOptionalActivated ? deactivateOptionalItem : activateOptionalItem}
              startIcon={isOptionalActivated ? <Done /> : <CheckBoxIcon />}
            >
              <Typography variant="body2">{R.prop('activationLabel', optionalItem)}</Typography>
            </Button>
          )}
        </Grid>
        <Grid item>
          <Grid container justifyContent="flex-end" display="flex" spacing={1}>
            {R.map(button => (
              <Grid item key={button.id} data-testid={button['data-testid']}>
                <Button aria-label={button.label} color="primary" {...button}>
                  <Typography variant="body2">{button.label}</Typography>
                </Button>
              </Grid>
            ))([
              {
                id: 2,
                label: labels.cancel,
                onClick: onCancel,
                startIcon: <Undo />,
                'datatest-id': 'table-layout-cancel',
              },
              {
                id: 1,
                label: labels.commit,
                onClick: onCommit,
                startIcon: <Done />,
                variant: 'contained',
                alternative: 'siscc',
                className: classes.applyButton,
                'data-testid': 'table-layout-apply',
              },
            ])}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const patern = PropTypes.shape({
  id: PropTypes.string,
  count: PropTypes.number,
});

TableLayout.propTypes = {
  layout: PropTypes.shape({
    header: PropTypes.arrayOf(PropTypes.string),
    sections: PropTypes.arrayOf(PropTypes.string),
    rows: PropTypes.arrayOf(PropTypes.string),
  }),
  items: PropTypes.arrayOf(patern),
  optionalItem: patern,
  itemRenderer: PropTypes.func,
  commit: PropTypes.func,
  noPreview: PropTypes.bool,
  itemButtonProps: PropTypes.object,
  labels: PropTypes.shape({
    commit: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    cancel: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    row: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    column: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    section: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    table: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    asc: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    desc: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    help: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    one: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    wcagDragStart: PropTypes.func,
    wcagDragUpdate: PropTypes.func,
    wcagDragEnd: PropTypes.func,
    wcagDragCancel: PropTypes.string,
    wcagDragExplanation: PropTypes.string,
  }),
  accessibility: PropTypes.bool,
};

export default withBlank(({ layout = [], isBlank }) => ({
  isBlank: R.or(isBlank, R.pipe(R.values, R.all(R.isEmpty))(layout)),
}))(TableLayout);
